import { format, formatDistanceToNow } from 'date-fns';
import i18n from 'i18next';

// Статический импорт всех необходимых локалей
import { af, ar, az, be, bg, bn, bs, ca, cs, da, de, el, enUS, enGB, es, et, faIR, fi, fr, frCA, frCH, he, hi, hu, is, it, ja, ko, nl, pl, pt, ptBR, ro, ru, sv, tr, uk, vi, zhCN, zhTW } from 'date-fns/locale';

// Кэш для локалей
const localeCache = {
  af, ar, az, be, bg, bn, bs, ca, cs, da, de, el, 'en-US': enUS, 'en-GB': enGB, es, et, 'fa-IR': faIR, fi, fr, 'fr-CA': frCA, 'fr-CH': frCH, he, hi, hu, is, it, ja, ko, nl, pl, pt, 'pt-BR': ptBR, ro, ru, sv, tr, uk, vi, 'zh-CN': zhCN, 'zh-TW': zhTW
};

// Функция получения текущей локали
const getLocale = (locale) => localeCache[locale] || localeCache['en-US'];
const getCurrentLanguage = () => i18n.language;
const getCurrentLocale = () => localeCache[i18n.language] || localeCache['en-US'];

// Функция форматирования даты
const formatDate = (date, dateFormat = 'PP', timeFormat = 'p') => {
  const locale = getCurrentLocale();
  const finalFormat = `${dateFormat} ${timeFormat}`.trim();
  return format(date, finalFormat, { locale });
};

// Функция форматирования времени между датами
const formatDateDistance = (date) => {
  const locale = getCurrentLocale();
  return formatDistanceToNow(date, { addSuffix: true, locale }).replace(i18n.t('consultations.about'), '');
};

export { getLocale, getCurrentLanguage, getCurrentLocale, formatDate, formatDateDistance };
