import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const zh_CN = {
    "send_email_code": "正在向 {{email}} 发送包含验证码的邮件: {{code}}",
    "token_valid": "令牌有效",
    "token_invalid": "令牌无效",
    "verification_error": "验证令牌时出错:",
    "verification_completed": "验证完成。已认证: ",
    "welcome": {
        "title": "Wixee 欢迎您"
    },
    "splash1": {
        "title": "智慧健康从这里开始",
        "description": "通过个性化的人工智能健康建议，提升您家庭的健康旅程，满足每位成员的独特需求"
    },
    "splash2": {
        "title": "人人健康洞察",
        "description": "探索全面的健康洞察，照顾各个年龄段，确保您所爱的人健康快乐"
    },
    "splash3": {
        "title": "您的健康，我们的优先",
        "description": "体验家庭健康的未来，通过人工智能驱动的建议，帮助您保持最佳健康状态"
    },
    "splash4": {
        "title": "让我们开始吧！",
        "description": "登录以享受我们提供的功能，保持健康！",
        "signInButton": "登录"
    },
    "home": {
        "title": "主页",
        "consultations": "咨询",
        "consultationsDescription": "点击此处查看您的咨询记录",
        "documents": "文件",
        "documentsDescription": "点击此处上传您的文件",
        "notifications": "通知",
        "notificationsDescription": "点击此处查看所有事件",
        "offices": "服务中心",
        "officesDescription": "选择一个服务中心以获取服务",
        "settings": "设置",
        "settingsDescription": "更改语言等",
        "cardTitle": "愿您今日愉快",
        "cardDescription": "根据您的健康状况和太阳活动，建议您放松以避免认知能力方面的困扰",
        "newConsultation": "新的咨询",
        "adTitle": "应用中的一些广告",
        "learnMore": "了解更多"
    },
    "calendar": {
        "title": "通知",
        "created": "已创建",
        "source": {
            "consultation": "来自咨询",
            "document": "来自文件"
        }
    },
    "documents": {
        "title": "我的文件",
        "latest": "最近的",
        "lastMonth": "上个月",
        "older": "更早的",
        "upload": "上传新文件",
        "deleted": "已删除"
    },
    "document": {
        "title": "文件"
    },
    "header": {
        "youAndYourBeloved": "您与您的挚爱"
    },
    "settings": {
        "title": "设置",
        "language": "语言",
        "country": "国家",
        "userCountry": "我的国家",
        "userLanguage": "我的语言",
        "userEmail": "我的电子邮件",
        "emailAddress": "电子邮件地址"
    },
    "consultation": {
        "title": "咨询",
        "description": "咨询描述",
        "selectConsultant": "选择咨询顾问",
        "manageConsultation": "管理咨询",
        "consultationDeleted": "咨询已删除 {{date}}",
        "download": "下载",
        "message": "消息",
        "join": {
            "MALE": "加入对话",
            "FEMALE": "加入对话",
            "OTHER": "加入对话"
        }
    },
    "consultations": {
        "title": "我的咨询",
        "description": "咨询描述",
        "latest": "最近的",
        "seeAll": "查看全部",
        "lastMonth": "上个月",
        "older": "更早的",
        "deleted": "已删除",
        "about": "关于"
    },
    "profiles": {
        "title": "我的亲人"
    },
    "profile": {
        "title": "我的个人资料",
        "billing": "我的资费",
        "offices": "我的服务中心",
        "settings": "设置",
        "general": "常规",
        "create": "创建",
        "logout": "退出",
        "addProfile": "添加新的亲人",
        "hideStoredData": "隐藏存储的数据",
        "showStoredData": "显示存储的数据",
        "officeNoSummary": "没有可用摘要",
        "termsConditions": "条款和条件",
        "clickToChange": "点击更改",
        "name": "姓名",
        "comment": "备注",
        "dateOfBirth": "出生日期",
        "timeOfBirth": "出生时间（当地）",
        "placeOfBirth": "出生地点",
        "height": "身高（厘米）",
        "weight": "体重（千克）",
        "cm": "厘米",
        "kg": "千克"
    },
    "billing": {
        "title": "账单",
        "currentBalance": "当前余额",
        "subscriptionPlan": "订阅计划",
        "subscription": {
            "free": "免费",
            "premium": "高级",
            "exclusive": {
                "title": "独家",
                "description": "独家订阅计划为会员提供独特的内容、高级功能和个性化服务，这些是普通用户无法享受的"
            }
        },
        "topUp": "充值",
        "changePlan": "更改计划"
    },
    "offices": {
        "title": "服务中心",
        "HEALTH": {
            "name": "健康",
            "description": "人工智能健康诊断服务根据用户提供的症状和病史进行分析，提供初步的健康状况评估。此服务旨在为用户提供个性化的健康见解和建议，指导用户下一步的医疗护理方向，包括是否需要寻求专业帮助",
            "disclaimer": "此人工智能服务仅供参考，不构成医疗工具或服务。它不提供医学诊断、治疗或处方。所有健康相关的决策和行动应基于持证医疗专业人员的建议。如果您有紧急医疗需求，请立即拨打 911 或本地急救服务电话。开发者不为使用此工具所导致的任何健康结果负责。"
        },
        "ALTMED": {
            "name": "替代医学",
            "description": "人工智能驱动的替代医学服务提供包括针灸、草药治疗和顺势疗法等一系列非传统治疗方法。通过先进的算法，它根据替代疗法的原则提供个性化建议。此服务旨在通过提供整体健康见解，补充传统医疗治疗。",
            "disclaimer": "此人工智能替代医学服务仅供一般健康和信息目的使用，不能代替专业医疗诊断或治疗。请在做出任何健康相关决策前，务必咨询合格的医疗专业人员。开发者对因使用此服务所造成的健康结果不承担任何责任。"
        }
        // and so on for other "officeTypes"
    },
    // Continue for other sections...
    "languages": {
        "af": "南非荷兰语",
        "ar": "阿拉伯语",
        "az": "阿塞拜疆语",
        "be": "白俄罗斯语",
        "bg": "保加利亚语",
        "bn": "孟加拉语",
        "bs": "波斯尼亚语",
        "ca": "加泰罗尼亚语",
        "cs": "捷克语",
        "da": "丹麦语",
        "de": "德语",
        "de-AT": "德语（奥地利）",
        "el": "希腊语",
        "en": "英语",
        "en-GB": "英语（英国）",
        "en-US": "英语（美国）",
        "es": "西班牙语",
        "et": "爱沙尼亚语",
        "fa-IR": "波斯语（伊朗）",
        "fi": "芬兰语",
        "fr": "法语",
        "fr-CA": "法语（加拿大）",
        "fr-CH": "法语（瑞士）",
        "he": "希伯来语",
        "hi": "印地语",
        "hu": "匈牙利语",
        "is": "冰岛语",
        "it": "意大利语",
        "ja": "日语",
        "ko": "韩语",
        "nl": "荷兰语",
        "pl": "波兰语",
        "pt": "葡萄牙语",
        "pt-BR": "葡萄牙语（巴西）",
        "ro": "罗马尼亚语",
        "ru": "俄语",
        "sv": "瑞典语",
        "tr": "土耳其语",
        "uk": "乌克兰语",
        "vi": "越南语",
        "zh-CN": "中文（简体）",
        "zh-TW": "中文（繁体）"
    }
}
