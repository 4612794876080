import React, { useState, useEffect, useRef } from 'react';
import { countries, languages } from '../config/AppModes.ts';
import { Box, Select, MenuItem, FormControl } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';  // Import useAuth from AuthContext
import { List, ListItem, ListItemIcon, TextField, InputLabel } from '@mui/material';

import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import { useAppContext } from '../components/AppContext.js'; // Import useAppContext hook
import { useTranslation } from 'react-i18next';
import api from '../classes/API.js';
import { useProfile } from '../classes/ProfileManager.js';

const SAVE_TIMEOUT_DELAY = 2000;

export function SettingsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { t, i18n } = useTranslation();
    const { userProfile, updateUserProfile, tokenVerificationCompleted } = useAuth();
    const { userCountry, setUserCountry } = useAppContext();

    const [country, setCountry] = useState("");
    useEffect(
        () => {
            if(userCountry)
                setCountry(userCountry);
        }, [
            userCountry
        ]
    )
    const countrySaveTimeout = useRef(0)
    const countrySaveCb = useRef(() => {})
    const handleCountryChange = c => {
        setCountry(c);
        countrySaveTimeout.current
            && clearTimeout(countrySaveTimeout.current);
        countrySaveTimeout.current = setTimeout(
            countrySaveCb.current = () => api
                .setUserCountry(c)
                .then(() => setUserCountry(c))
            , SAVE_TIMEOUT_DELAY
        );
    };

    const [language, setLanguage] = useState(i18n.language);
    const languageSaveTimeout = useRef(0);
    const languageSaveCb = useRef(() => {});
    const handleLanguageChange = lng => {
        setLanguage(lng);
        i18n.changeLanguage(lng);
        languageSaveTimeout.current && clearTimeout(languageSaveTimeout.current);
        languageSaveTimeout.current = setTimeout(
            languageSaveCb.current = () => api
                .setUserLanguage(lng)
                .then(() => updateUserProfile({
                    ...userProfile
                    , language: lng
                }))
            , SAVE_TIMEOUT_DELAY
        );
    };

    useEffect(
        () => {
            if(!tokenVerificationCompleted) return;
            if(!userProfile) return;
            userProfile.language && setLanguage(userProfile.language);
        }
        , [
            tokenVerificationCompleted
            , useProfile
        ]
    );

    useEffect(() => () => {
            // Save on exit, will be called exactly once
            if(countrySaveTimeout.current) {
                clearTimeout(countrySaveTimeout.current);
                countrySaveCb.current();//State vars are not available here.
            }
            if(languageSaveTimeout.current) {
                clearTimeout(languageSaveTimeout.current);
                languageSaveCb.current();
            }
        }
        , []
    );

    return (
        <Box sx={{
            pt: 4, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: '100svh', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
        }}>

            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', mt: 0,
            }}>

                <Box
                    sx={{
                        width: '90%',
                        borderRadius: '20px 20px 0 0',
                        textAlign: 'center',
                        padding: 2,
                        position: 'relative',
                    }}
                >
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120, width: '100%' }}>
                        <List component="nav" sx={{ mt: 1, gap: 3, display: 'flex', flexDirection: 'column' }}>
                            
                        <ListItem>
                                <ListItemIcon>
                                    <LanguageIcon />
                                </ListItemIcon>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.userCountry')}</InputLabel>
                                    <Select
                                        label={t('settings.country')}
                                        value={country}
                                        onChange={event => handleCountryChange(event.target.value)}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Country' }}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {/* Пустой пункт выбора страны */}
                                        <MenuItem value="" disabled>
                                            {t('settings.userCountry')}
                                        </MenuItem>

                                        {/* Сначала собираем и сортируем страны */}
                                        {Object.keys(countries)
                                            .map((key) => ({
                                                code: countries[key], 
                                                name: t(`countries.${countries[key]}`)
                                            }))
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Сортируем по названию страны
                                            .map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <TranslateIcon />
                                </ListItemIcon>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.userLanguage')}</InputLabel>
                                    <Select
                                        label={t('settings.language')}
                                        value={language}
                                        onChange={event => handleLanguageChange(event.target.value)}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Language' }}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {/* Пустой пункт выбора языка */}
                                        <MenuItem value="" disabled>
                                            {t('settings.userLanguage')}
                                        </MenuItem>

                                        {/* Сначала собираем и сортируем языки */}
                                        {Object.keys(languages)
                                            .map((key) => ({
                                                code: languages[key], 
                                                name: t(`languages.${languages[key]}`)
                                            }))
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Сортируем по названию языка
                                            .map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                {/* email input here */}
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={userProfile?.email}
                                    disabled
                                />
                            </ListItem>

                        </List>
                    </FormControl>

                </Box>

            </Box>

        </Box>

    );
}

export default SettingsScreen;