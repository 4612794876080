import React from 'react';
import { Typography, Box, Grid, Collapse } from '@mui/material';
import DocumentCard from './DocumentCard';

const DocumentGroup = ({ title, documents, userPhoto, handleCardClick, expanded, onExpand, isDeleted }) => {

    function getDocumentsLength(docs) {
        if (!docs) return 0;
        if (docs.length === 0) return 0;
        if (docs[0].id === 0) return 0; // return 0 when only skeletons are present
        return docs.length;
    }

    return (
        <Box sx={{ width: '100%', mt: 0, pb: 2 }}>
            <Typography variant="body1" color="black" onClick={onExpand} sx={{ cursor: 'pointer', px: { xs: 2, md: 3 }, mb: 2 }}>
                {title} / {getDocumentsLength(documents)} &nbsp; {expanded ? '▽' : '▷'}
            </Typography>
            <Collapse in={expanded}>
                <Grid container rowSpacing={2} spacing={2} sx={{ ml: 0, pl: { md: 1 }, pr: { xs: 2, md: 3 }, py: 0.5, width: '100%' }}>
                    {documents.map((profile, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <DocumentCard
                                key={index}
                                content={profile}
                                userPhoto={userPhoto}
                                handleCardClick={handleCardClick}
                                isDeleted={isDeleted}
                                index={index}
                                expanded={expanded}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </Box >
    );
};

export default DocumentGroup;
