import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useAuthRedirect, useAuth } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import SearchBox from '../elements/SearchBox.tsx'; // Import SearchBox component
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';
import { useAppContext } from '../components/AppContext.js';

import ConsultationGroup from '../components/ConsultationGroup.js';

export function HomeOfficeScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();

    const { userProfile } = useAuth();
    const userPhoto = userProfile?.picture || null;
    const { t } = useTranslation();

    const isLoaded = useRef(false);

    const { userCountry, currentProfileId, consultationsManager } = useAppContext();

    const [expandedGroup, setExpandedGroup] = useState('latest');
    const [consultations, setConsultations] = useState({
        latestConsultations: [
            { id: 0 },
            { id: 0 },
            { id: 0 },
            { id: 0 },
        ],
        lastMonthConsultations: [],
        olderConsultations: [],
        deletedConsultations: [],
    });

    // load consultations
    useEffect(() => {
        if (isLoaded.current) return;

        if (consultationsManager && userCountry && userProfile) {
            // FIXME! Use only user profile id
            //const profileId = userProfile?.profile_id ? userProfile.profile_id : userProfile.id;
            //const userId = userProfile?.id;
            // Do not issue another call
            isLoaded.current = true;

            consultationsManager.getSplittedConsultations(userProfile.id, userCountry, currentProfileId)
                .then((consultations) => {
                    setConsultations(consultations);
                })
                .catch((error) => {
                    isLoaded.current = false;
                    console.error("Failed to fetch consultations:", error);
                });
        }
    }, [
        consultationsManager
        , userCountry
        , userProfile
    ]);


    function handleCardClick(cons) {

        // profile.id
        setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_CONSULTATION_ID.path}?consid=${cons.id}`);

    }

    function handleCardClickDeleted(cons) {

        // profile.id
        setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_CONSULTATION_DETAILS.path}?consid=${cons.id}`);

    }

    return (
        <Box sx={{
            pt: 0, pb: 0, display: 'flex', flexDirection: 'column',
            flex: 1, height: '100%',
            alignItems: 'center', width: '100%', overflow: 'hidden',
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{
                pt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowX: 'hidden', overflowY: 'auto'
            }}>

                <SearchBox />

                <Box sx={{ width: '70%', mx: 2, p: 2, my: 1, maxWidth: '20em' }}>
                    <Button sx={{ position: 'relative', width: '100%', px: 2 }}
                        variant="contained" color="primary"
                        onClick={() => { setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SELECT_CONSULTANT.path) }}>
                        {t('home.newConsultation')}
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                    <ConsultationGroup
                        title={t('consultations.latest')}
                        consultations={consultations.latestConsultations}
                        userPhoto={userPhoto}
                        handleCardClick={handleCardClick}
                        expanded={expandedGroup === 'latest'}
                        onExpand={() => setExpandedGroup(expandedGroup === 'latest' ? '' : 'latest')}
                    />

                    <ConsultationGroup
                        title={t('consultations.lastMonth')}
                        consultations={consultations.lastMonthConsultations}
                        userPhoto={userPhoto}
                        handleCardClick={handleCardClick}
                        expanded={expandedGroup === 'lastMonth'}
                        onExpand={() => setExpandedGroup(expandedGroup === 'lastMonth' ? '' : 'lastMonth')}
                    />

                    <ConsultationGroup
                        title={t('consultations.older')}
                        consultations={consultations.olderConsultations}
                        userPhoto={userPhoto}
                        handleCardClick={handleCardClick}
                        expanded={expandedGroup === 'older'}
                        onExpand={() => setExpandedGroup(expandedGroup === 'older' ? '' : 'older')}
                    />

                    <ConsultationGroup
                        title={t('consultations.deleted')}
                        consultations={consultations.deletedConsultations}
                        userPhoto={userPhoto}
                        handleCardClick={handleCardClickDeleted}
                        expanded={expandedGroup === 'deleted'}
                        isDeleted={true}
                        onExpand={() => setExpandedGroup(expandedGroup === 'deleted' ? '' : 'deleted')}
                    />
                </Box>


            </Box>
        </Box >
    );
}

export default HomeOfficeScreen;