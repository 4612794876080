import { topic } from './Topic.js';

// ./classes/API.js
const API_ENDPOINT = (process.env.REACT_APP_PROD_CONNECT) ? process.env.REACT_APP_API_ENDPOINT_PROD : process.env.REACT_APP_API_ENDPOINT;

const { pub: notify_authorized, sub: on_authorized } = topic("authorized");
const { pub: notify_unauthorized, sub: on_unauthorized } = topic("unauthorized");
const { pub: notify_negotiate, sub: on_negotiate } = topic("negotiate");

class API {

    constructor() {
        this._isMounted = false; // Initialize the flag
        this.token = localStorage.getItem('authToken');
        this.user_id = null;
        this.is_valid = false;
        on_unauthorized((api => () => {
            api.is_valid = false;
        })(this));
        on_authorized((api => () => {
            api.is_valid = true;
        })(this));
    }

    set_token(token) {
        localStorage.setItem('authToken', this.token = token);
    }

    set_user_id(id) {
        this.user_id = id;
    }

    drop() {
        this.token = null;
        localStorage.removeItem('authToken');
        notify_unauthorized();
    }

    on_unauthorized(cbFn) {
        return on_unauthorized(cbFn);
    }

    on_authorized(cbFn) {
        return on_authorized(cbFn);
    }

    on_negotiate(cbFn) {
        return on_negotiate(cbFn);
    }

    async fetchWithFallback(method, options) {
        const apiEndpoint = API_ENDPOINT + method;
        return fetch(apiEndpoint, {
            ...options
            , headers: {
                ...options.headers
                , ...(this.token && {
                    Authorization: `Bearer ${this.token}`
                })
            }
        })
            .then(x => {
                if (x.status == 401) {
                    this.set_token(null);
                    notify_unauthorized();
                    //NOTE: Below in commented to avoid uncatched promise handling by React
                    //throw new Error(x.statusText);
                }
                //FIXME:Handle code 403 as well
                return x;
            });
    }

    async apiLogin(data) {

        return await this.fetchWithFallback("/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async apiUpdateUserProfile(data) {

        return await this.fetchWithFallback("/updateuserprofile", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async verifyToken(data) {
        if (this.token) {
            notify_negotiate();
            //TODO: change server side to verify token from header and then remove request body
            const r = await this.fetchWithFallback("/verifytoken", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: this.token }),
                credentials: 'include',
            });
            if (r.status == 200) {
                const d = await r.json();
                this.set_user_id(d.user.id);
                notify_authorized(d.user);
                return d;
            }
        }
        notify_unauthorized();
        //throw new Error(r.statusText);
        return {};
    }

    async apiGoogleLogin(data) {

        notify_negotiate();
        const r = await this.fetchWithFallback("/googlelogin", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include',
        });
        if (r.status == 200) {
            const d = await r.json();
            this.set_token(d.token);
            this.set_user_id(d.user.id);
            notify_authorized(d.user);
            return d;
        } else throw new Error(r.statusText);
    }

    async getConsultationsByUser(userId, officeId, profileId = 0) {
        return await this.fetchWithFallback(`/consultations/user?officeId=${officeId}&profileId=${profileId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
    }

    async getConsultationById(consId) {
        return await this.fetchWithFallback(`/consultations/${consId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
    }

    async deleteConsultation(consId) {
        const response = await this.fetchWithFallback(`/consultations/${consId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async restoreConsultation(consId) {
        const response = await this.fetchWithFallback(`/consultations/restore/${consId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async apiGetChatResponse(body) {

        return await this.fetchWithFallback("/chat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: 'include',
        });
    }


    async apiSaveChatMessages(body) {

        return await this.fetchWithFallback("/savechat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: 'include',
        });
    }


    async apiRequestInstantLogin(email) {
        return await this.fetchWithFallback("/authCodeReq", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({ id: email }),
            credentials: 'include',
        });
    }

    async apiInstantLogin(email, code) {
        notify_negotiate();
        const r = await this.fetchWithFallback("/authCode", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({ id: email, code: code }),
            credentials: 'include',
        });
        if (r.status == 200) {
            const d = await r.json();
            this.set_token(d.token);
            this.set_user_id(d.user.id);
            notify_authorized(d.user);
            return d;
        } else throw new Error(r.statusText);
    }

    async allProfiles() {
        const response = await this.fetchWithFallback("/profile", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        });
        const res = await response.json();
        res.forEach(x => x.date_of_birth = new Date(x.date_of_birth))
        return res;
    }

    async getProfile(profileId) {
        const response = await this.fetchWithFallback(`/profile/${profileId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        });
        let x = await response.json();
        x.date_of_birth = new Date(x.date_of_birth);
        return x;
    }

    async newProfile(profile) {
        const r = await this.fetchWithFallback("/profile/new", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...profile
                , date_of_birth: profile.date_of_birth.toISOString()
            }),
            credentials: 'include',
        });
        return r.json();
    }

    async saveProfile(profile) {
        const r = await this.fetchWithFallback(`/profile/${profile.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...profile
                , date_of_birth: profile.date_of_birth.toISOString()
            }),
            credentials: 'include',
        });
        return r.json();
    }

    async deleteProfile(profile_id) {
        return this.fetchWithFallback(`/profile/${profile_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        });
    }

    async storePhoto(profile_id, file_data) {
        const form_data = new FormData();
        form_data.append('avatar', file_data);
        return await this.fetchWithFallback(`/profile/photo/${profile_id}`, {
            method: 'POST',
            body: form_data,
            credentials: 'include',
        });
    }

    async loadPhoto(profileId, hash) {
        return await this.fetchWithFallback(`/profile/photo/${profileId}`, {
            method: 'GET',
            headers: {
                ...(hash && {
                    'If-None-Match': hash
                })
            },
            credentials: 'include',
        });
    }


    async deletePhoto(profileId) {
        return await this.fetchWithFallback(`/profile/photo/${profileId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    }

    async stats(profileId = null, officeId = null) {
        let url = "/stats";
        const params = new URLSearchParams();
        if (officeId) params.append("officeId", officeId.toString());
        if (profileId) params.append("profileId", profileId.toString());

        if (params.toString()) {
            url += `?${params.toString()}`;
        }

        const r = await this.fetchWithFallback(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        });
        return r.json();
    }

    async apiGetWebSocketTranscribe(body) {
        try {
            console.log("Trying socket connection to URL: " + API_ENDPOINT);
            const ws = await this.connectWebSocket(API_ENDPOINT);
            return ws; // Return the successfully connected WebSocket instance
        } catch (error) {
            console.log(error);
            // Attempt to connect to the alternative URL if the initial connection fails
            /*
            try {
                console.log("Trying alternative URL due to error: " + API_ENDPOINT_ALTERNATIVE);
                const wsAlternative = await this.connectWebSocket(API_ENDPOINT_ALTERNATIVE);
                return wsAlternative; // Return the WebSocket instance connected to the alternative URL
            } catch (error) {
                console.log("Error with alternative URL", error);
                return null; // Return null if both connection attempts fail
            }
            */
        }
    }

    async connectWebSocket(url) {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(url);

            ws.addEventListener('open', () => {
                //console.log("Connected to WebSocket at " + url);
                resolve(ws); // Resolve the promise with the WebSocket instance upon successful connection
            });

            ws.addEventListener('error', (event) => {
                console.log("Error connecting to WebSocket at " + url, event);
                reject(new Error("Failed to connect to WebSocket at " + url)); // Reject the promise on connection error
            });
        });
    }

    async apiGenerateDocument(data) {
        const response = await this.fetchWithFallback("/generateDocument", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });

        return response;
    }

    async generateImage(data) {
        const response = await this.fetchWithFallback("/generateImage", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });

        return response;
    }


    async apiGetDocumentContent(documentHash) {
        return await this.fetchWithFallback(`/documents/content/${documentHash}`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    async apiGetDocument(documentId) {
        return await this.fetchWithFallback(`/documents/${documentId}`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    async apiGetDocuments(profileId, officeId) {
        return await this.fetchWithFallback(`/documents/profile/${profileId}?officeId=${officeId}`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    async deleteDocument(docId) {
        const response = await this.fetchWithFallback(`/documents/${docId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    async restoreDocument(docId) {
        const response = await this.fetchWithFallback(`/documents/restore/${docId}`, {
            method: 'PUT',
        });
        return response;
    }

    async uploadDocument(formData) {

        return await this.fetchWithFallback(`/documents/upload`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });
    }

    async uploadPhoto(formData) {

        return await this.fetchWithFallback(`/documents/upload`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });
    }

    async getAllProfilesSummary() {
        return await this.fetchWithFallback(`/profiles/allsummary`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    async getBalance() {
        return await this.fetchWithFallback(`/profiles/balance`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    async setUserLanguage(lng) {
        return await this.fetchWithFallback(`/user/lng/${this.user_id}?lng=${lng}`, {
            method: 'PUT'
            , credentials: 'include'
        });
    }

    async setUserCountry(country) {
        return await this.fetchWithFallback(`/user/country/${this.user_id}?country=${country}`, {
            method: 'PUT'
            , credentials: 'include'
        });
    }

    async setUserTimezone(timezone) {
        const r = await this.fetchWithFallback(`/user/tz/${this.user_id}`, {
            method: 'PUT'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                timezone: timezone
            })
            , credentials: 'include'
        });
        return r.json();
    }
}

const api = new API();

export default api;
