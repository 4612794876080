// src/classes/DocumentsManager.js
import api from '../classes/API.js';

export default class DocumentsManager {
    constructor(officeManager) {
        this.officeManager = officeManager;
        if (!this.officeManager) {
            console.error('Office Manager is not set!');
        }
    }

    async generateDocument(data) {
        const response = await this.api.apiGenerateDocument(data);
        if (response.ok) {
            const documentData = await response.json();
            return documentData;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    async fetchDocumentContent(documentId) {
        const response = await api.apiGetDocumentContent(documentId);
        if (response.ok) {
            const documentContent = await response.json();
            return documentContent;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    async getDocument(documentId) {
        const response = await api.apiGetDocument(documentId);
        if (response.ok) {
            const document = await response.json();
            const formattedDocument = this.formatDocuments([document]);
            return formattedDocument[0];
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    formatDocuments(data) {
        return data.map(item => {
            //console.log('Processing item:', item);
            return {
                id: item.id,
                profileId: item.profile_id,
                title: item.document_name,
                date: item.create_date_time ? new Date(item.create_date_time).toISOString() : '...', // Adjust date formatting if necessary
                author: item.author,
                content: item.document_summary.markdown,
                description: item.document_summary.summary,
                generated_title: item.document_summary.title,
                short_summary: item.document_summary.short_summary,
                long_summary: item.document_summary.long_summary,
                deleted_date_time: item.delete_date_time,
                document_hash: item.document_hash_string,
                total_cost: parseFloat(item.total_cost).toFixed(3),
                source_type: item.source_type,
                mime_type: item.mime_type,
            };
        });
    }

    sortDocuments(documents) {
        return documents.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    }

    async fetchAllDocuments(profileId) {
        const officeId = this.officeManager.getCurrentOfficeId();
        const response = await api.apiGetDocuments(profileId, officeId); // Assuming an API method for fetching all documents
        if (response.ok) {
            const data = await response.json();
            const formattedDocuments = this.formatDocuments(data);
            const sortedDocuments = this.sortDocuments(formattedDocuments);
            return sortedDocuments;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }


    async getSplittedDocuments(profileId) {
        const data = await this.fetchAllDocuments(profileId);
        const sortedAndFilteredDocuments = this.sortAndFilterDocuments(data);
        return sortedAndFilteredDocuments;
    }

    sortAndFilterDocuments(documents) {
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const sortedDocuments = documents.sort((a, b) => new Date(b.date) - new Date(a.date));
        const deletedDocuments = sortedDocuments.filter(c => c.deleted_date_time);
        const activeDocuments = sortedDocuments.filter(c => !c.deleted_date_time);

        const latestDocuments = activeDocuments.filter(c => new Date(c.date) >= thirtyDaysAgo);
        const lastMonthDocuments = activeDocuments.filter(c => new Date(c.date) < thirtyDaysAgo && new Date(c.date) >= new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth() - 1, 1));
        const olderDocuments = activeDocuments.filter(c => new Date(c.date) < new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth() - 1, 1));

        return {
            latestDocuments,
            lastMonthDocuments,
            olderDocuments,
            deletedDocuments
        };
    }

    downloadDocument = async (documentId, showAlert = null) => {
        console.log('Downloading document:', documentId);
        if (documentId) {
            try {
                const response = await api.apiGetDocumentContent(documentId);
                if (!response.ok) throw new Error('Failed to fetch document content');

                // Extract the filename from the Content-Disposition header
                const filename = decodeURIComponent(response.headers.get('X-Filename') || 'unknown_document');

                const content_type = response.headers.get('Content-Type') || 'unknown_type';

                // Convert the response to a blob with the correct MIME type
                const blob = await response.blob();

                // Create a new Blob object with the specified content type
                const blobWithType = new Blob([blob], { type: content_type });

                const url = window.URL.createObjectURL(blobWithType);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                if (showAlert) showAlert('error', 'Error downloading document. Try again later.');
                console.error('Error downloading document:', error);
            }
        }
    };

    async loadDocumentContent(documentId) {
        console.log('Loading document content:', documentId);
        if (documentId) {
            try {
                const response = await api.apiGetDocumentContent(documentId);
                if (!response.ok) throw new Error('Failed to fetch document content');

                const contentType = response.headers.get('Content-Type') || 'unknown_type';

                // Convert the response to a blob
                const blob = await response.blob();

                // Check if the content is an image
                if (contentType.startsWith('image/')) {
                    // Create a URL from the blob
                    const url = window.URL.createObjectURL(blob);
                    return { url, contentType };
                } else {
                    throw new Error('Document is not an image');
                }
            } catch (error) {
                console.error('Error loading document content:', error);
                return { url: null, contentType: null };
            }
        }
    }


    async deleteDocument(id) {
        const response = await api.deleteDocument(id);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    async restoreDocument(id) {
        const response = await api.restoreDocument(id);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    // Add a method to upload documents
    async uploadDocument(file, profileId, officeId, consultationId) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('consultationId', consultationId);
        formData.append('profileId', profileId);
        formData.append('officeId', officeId);

        const response = await api.uploadDocument(formData);
        if (response.ok) {
            const documentData = await response.json();
            return documentData;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    // Add a method to upload photos
    async uploadPhoto(file, profileId, officeId, consultationId) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('consultationId', consultationId);
        formData.append('profileId', profileId);
        formData.append('officeId', officeId);

        const response = await api.uploadPhoto(formData);
        if (response.ok) {
            const photoData = await response.json();
            return photoData;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }
}
