import { officeTypes, chatModes, genderTypes, countries, languages } from '../../config/AppModes.ts';

export const ru = {
    "send_email_code": "Sending email to {{email}} with code: {{code}}",
    "token_valid": "Token is valid",
    "token_invalid": "Token is invalid",
    "verification_error": "Error verifying token:",
    "verification_completed": "Verification completed. Authenticated: ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "Умная медицина начинается здесь",
        "description": "Укрепляйте здоровье вашей семьи с помощью персонализированных рекомендаций по здоровью на основе ИИ, адаптированных к уникальным потребностям каждого члена семьи"
    },
    "splash2": {
        "title": "Здоровье для всех",
        "description": "Откройте для себя всесторонние сведения о здоровье для всех возрастов, чтобы ваши близкие оставались здоровыми и счастливыми"
    },
    "splash3": {
        "title": "Ваше здоровье - наш приоритет",
        "description": "Испытайте будущее семейного здравоохранения с рекомендациями и советами на основе ИИ, созданными для поддержания вашего здоровья в оптимальном состоянии"
    },
    "splash4": {
        "title": "Давайте начнем!",
        "description": "Войдите, чтобы воспользоваться предоставленными функциями и оставаться здоровыми!",
        "signInButton": "Войти"
    },
    "home": {
        "title": "Домой",
        "consultations": "Консультации",
        "consultationsDescription": "Нажмите здесь, чтобы увидеть ваши консультации",
        "documents": "Документы",
        "documentsDescription": "Нажмите здесь, чтобы загрузить ваши документы",
        "notifications": "Уведомления",
        "notificationsDescription": "Нажмите здесь, чтобы увидеть все события",
        "offices": "Офисы",
        "officesDescription": "Выберите офис для получения услуги",
        "settings": "Настройки",
        "settingsDescription": "Изменить язык и т.д",
        "cardTitle": "Сегодня прекрасный день",
        "cardDescription": "Ваше состояние здоровья и солнечная активность предполагают возможные осложнения с когнитивными способностями. Расслабьтесь и наслаждайтесь",
        "newConsultation": "Новая консультация",
        "adTitle": "Некоторая реклама из приложения",
        "learnMore": "Узнать больше"
    },
    "calendar": {
        "title": "Уведомления",
        "created": "Создано",
        "source": {
            "consultation": "из консультации",
            "document": "из документа"
        },
    },
    "documents": {
        "title": "Мои документы",
        "latest": "Последние",
        "lastMonth": "Предыдущий месяц",
        "older": "Прошлые",
        "upload": "Загрузить новый документ",
        "deleted": "Удаленные"
    },
    "document": {
        "title": "Документ",
        "generated": "Ваш документ готов",
        "create": "Сейчас я могу сформировать для вас итоговый документ консультации. Желаете сформировать документ?",
        "option": {
            "basic": "Сформировать базовый документ",
            "extended": "Сформировать расширенный документ",
            "clarifications": "Хочу получить уточнения"
        }
    },
    "header": {
        "youAndYourBeloved": "Вы и ваши любимые"
    },
    "settings": {
        "title": "Настройки",
        "country": "Страна",
        "language": "Язык",
        "userCountry": "Моя страна",
        "userLanguage": "Мой язык",
        "userEmail": "Мой email",
        "emailAddress": "Адрес электронной почты",
    },
    "consultation": {
        "title": "Консультация",
        "description": "Здесь вы можете ознакомиться с вашими консультациями",
        "selectConsultant": "Выберите консультанта",
        "manageConsultation": "Управление консультацией",
        "consultationDeleted": "Была удалена {{date}}",
        "download": "Скачать документ",
        "message": "Cообщение",
        "join": {
            [genderTypes.MALE]: "Вошёл в чат",
            [genderTypes.FEMALE]: "Вошла в чат",
            [genderTypes.OTHER]: "Вошло в чат",
        },
    },
    "consultations": {
        "title": "Мои консультации",
        "description": "Здесь вы можете ознакомиться со всеми консультациями",
        "latest": "Последние",
        "seeAll": "Смотреть все",
        "lastMonth": "Предыдущий месяц",
        "older": "Прошлые",
        "deleted": "Удалено",
        "about": "приблизительно",
    },
    "profiles": {
        "title": "Мои любимые",
    },
    "profile": {
        "title": "Мой профиль",
        "billing": "Мой тариф",
        "offices": "Мои офисы",
        "settings": "Настройки",
        "general": "Общее",
        "create": "Создать",
        "logout": "Выход",
        "addProfile": "Добавить любимого",
        "hideStoredData": "Скрыть сохраненные данные",
        "showStoredData": "Показать сохраненные данные",
        "officeNoSummary": "Ничего пока не хранится",
        "termsConditions": "Условия использования",
        "clickToChange": "Изменить",
        "name": "Имя",
        "comment": "Комментарий",
        "dateOfBirth": "Дата рождения",
        "timeOfBirth": "Время рождения (местное)",
        "placeOfBirth": "Место рождения",
        "height": "Рост (см)",
        "weight": "Вес (кг)",
        "cm": "см",
        "kg": "кг"
    },
    "billing": {
        "title": "Тариф",
        "currentBalance": "Текущий баланс",
        "subscriptionPlan": "План подписки",
        "subscription": {
            "free": "Бесплатный",
            "premium": "Премиум",
            "exclusive": {
                "title": "Эксклюзивный",
                "description": "Эксклюзивный план подписки предоставляет участникам премиальный доступ к уникальному контенту, специальным функциям и персонализированным услугам, недоступным для обычных пользователей"
            }
        },
        "topUp": "Пополнить",
        "changePlan": "Сменить тариф"
    },
    "terms": {
        "title": "Условия использования",
        "close": "Закрыть",
        "download": "Скачать",
    },
    "offices": {
        "title": "Офисы",
        [officeTypes.HEALTH]: {
            "name": "Здоровье",
            "description": "Сервис AI для диагностики здоровья анализирует предоставленные пользователем симптомы и историю болезни, чтобы предложить предварительные оценки состояния здоровья. Он предоставляет персонализированные рекомендации и направляет пользователей на возможные следующие шаги для получения медицинской помощи, включая обращение к профессиональному врачу",
            "disclaimer": "Этот AI-сервис предназначен только для информационных целей и не является медицинским инструментом или услугой. Все решения и действия, связанные со здоровьем, должны приниматься на основе консультаций с лицензированным медицинским специалистом. Разработчики и владельцы данного приложения не несут ответственности за любые медицинские исходы, связанные с использованием данного инструмента. В случае экстренной ситуации, пожалуйста, позвоните 911 или в местную службу экстренной помощи."
        },
        [officeTypes.PSYCHO]: {
            "name": "Психология",
            "description": "Сервис AI для психологической поддержки анализирует эмоциональное состояние, мысли и поведение пользователя. Он предоставляет персонализированные советы, стратегии преодоления трудностей и методы саморазвития для помощи в управлении стрессом, тревожностью и другими психологическими проблемами. Этот сервис призван дополнить традиционную терапию, предлагая пользователям мгновенную и доступную поддержку",
            "disclaimer": "Этот AI-сервис для психологической поддержки предназначен для предоставления общей поддержки психического здоровья и не является заменой профессиональной психологической или психиатрической помощи, диагностики или лечения. Для получения точной помощи в области психического здоровья всегда обращайтесь к лицензированному специалисту. Если вы находитесь в кризисной ситуации или в экстренном состоянии, обратитесь в местную службу экстренной помощи или кризисную горячую линию. Разработчики и владельцы данного приложения не несут ответственности за любые последствия, связанные с использованием этого сервиса."
        },
        [officeTypes.ALTMED]: {
            "name": "Альтернативная медицина",
            "description": "Сервис альтернативной медицины с искусственным интеллектом, который предлагает различные нетрадиционные методы лечения, включая акупунктуру, фитотерапию и гомеопатию. Используя сложные алгоритмы, он предоставляет персонализированные рекомендации, основанные на принципах альтернативного исцеления. Этот сервис помогает дополнять традиционные методы лечения, предлагая целостные взгляды на здоровье и благополучие пользователей",
            "disclaimer": "Данный сервис альтернативной медицины с ИИ предназначен исключительно для общего оздоровления и информационных целей. Он не заменяет профессиональную медицинскую диагностику или лечение. Всегда обращайтесь за консультацией к квалифицированному врачу перед принятием каких-либо решений, касающихся здоровья. Разработчики не несут ответственности за любые результаты, связанные с использованием данного сервиса."
        },
        [officeTypes.VET]: {
            "name": "Животные",
            "description": "Сервис AI для ветеринарии помогает владельцам домашних животных понять состояние здоровья их питомцев, анализируя предоставленные симптомы и медицинскую историю. Он предлагает предварительные рекомендации по возможным проблемам со здоровьем и шагам, таким как посещение ветеринара или уход на дому",
            "disclaimer": "Этот AI-сервис для ветеринарии предназначен для предоставления предварительных сведений о состоянии здоровья вашего питомца на основе предоставленных вами данных. Он не заменяет профессиональную ветеринарную помощь, диагностику или лечение. Для получения точного диагноза и плана лечения всегда обращайтесь к лицензированному ветеринару. В случае экстренной ситуации с вашим питомцем немедленно свяжитесь с местной службой ветеринарной помощи. Разработчики и владельцы данного приложения не несут ответственности за любые последствия, связанные со здоровьем вашего питомца при использовании данного инструмента."
        },
        [officeTypes.TEACHER]: {
            "name": "Образование",
            "description": "Образовательный сервис с ИИ, предоставляющий помощь в обучении и репетиторстве по различным предметам, от математики до искусства. Сервис предлагает персонализированные учебные планы и ответы на вопросы пользователей, симулируя поддержку опытных преподавателей. Он помогает улучшить результаты обучения, предлагая адаптированную образовательную поддержку",
            "disclaimer": "Этот образовательный сервис на базе ИИ предназначен для дополнения формального образования. Он не заменяет сертифицированных преподавателей или аккредитованные образовательные программы. Пользователи должны обращаться за профессиональной поддержкой для получения официального образования и сертификаций. Разработчики не несут ответственности за академические результаты, связанные с использованием этого сервиса."
        },
        [officeTypes.BUSINESS]: {
            "name": "Бизнес",
            "description": "Консалтинговый сервис в области бизнеса на базе искусственного интеллекта, предназначенный для предоставления стратегий и решений для предпринимателей, стартапов и существующих компаний. Используя анализ данных, сервис помогает разрабатывать маркетинговые стратегии, операционные процессы, финансовые модели и планы роста, симулируя работу опытного бизнес-консультанта. Он поддерживает пользователей в принятии бизнес-решений с помощью рекомендаций ИИ",
            "disclaimer": "Данный консалтинговый сервис на базе ИИ предназначен исключительно для информационных целей и планирования. Он не заменяет профессиональные финансовые, юридические или операционные консультации. Пользователи должны обращаться к квалифицированным специалистам для принятия важных бизнес-решений. Разработчики не несут ответственности за результаты, связанные с использованием этого сервиса."
        },
        [officeTypes.LEGAL]: {
            "name": "Законы",
            "description": "Сервис юридических консультаций на базе ИИ, который предоставляет общую информацию по широкому кругу юридических вопросов, начиная с договоров и заканчивая интеллектуальной собственностью. Используя сложные алгоритмы, он предлагает рекомендации и интерпретации, симулируя работу юриста. Сервис помогает пользователям лучше понять свои юридические ситуации, но не заменяет персонализированные юридические консультации",
            "disclaimer": "Этот сервис юридических консультаций на базе ИИ предназначен исключительно для информационных целей и не является юридической консультацией. Пользователи должны обращаться к лицензированным юристам для решения конкретных юридических вопросов. Разработчики не несут ответственности за юридические действия, предпринятые на основании информации, предоставленной этим сервисом."
        },
        [officeTypes.LIFE]: {
            "name": "Жизнь",
            "description": "Сервис личного развития и коучинга на базе ИИ, который помогает пользователям решать различные жизненные вопросы, такие как планирование карьеры, саморазвитие, путешествия и переезд. Сервис предлагает персонализированные советы и стратегии для достижения жизненных целей и улучшения общего благополучия",
            "disclaimer": "Этот сервис коучинга на базе ИИ предназначен для саморефлексии и предоставления общих советов. Он не заменяет профессиональные психологические, финансовые или юридические консультации. Пользователи должны обращаться к квалифицированным специалистам для принятия важных жизненных решений. Разработчики не несут ответственности за решения, принятые на основе рекомендаций данного сервиса."
        },
        [officeTypes.HOUSE]: {
            "name": "Дом",
            "description": "Сервис домашнего консалтинга на базе ИИ, который предоставляет советы по ремонту, обслуживанию и улучшению жилья. От сантехники и электрики до садоводства и безопасности — сервис предлагает персонализированные рекомендации для домашних проектов, симулируя помощь опытных специалистов",
            "disclaimer": "Этот сервис домашнего консалтинга на базе ИИ предназначен исключительно для информационных целей. Он не заменяет экспертизу сертифицированных специалистов по ремонту, строительству или обслуживанию дома. Пользователи должны нанимать лицензированных подрядчиков для выполнения серьезных домашних проектов. Разработчики не несут ответственности за результаты, связанные с использованием этого сервиса."
        },
        [officeTypes.CAR]: {
            "name": "Авто",
            "description": "Автомобильный консалтинговый сервис на базе ИИ, который предоставляет советы по обслуживанию, ремонту и покупке/продаже автомобилей. Сервис помогает пользователям принимать обоснованные решения о транспортных средствах, начиная от рутинного обслуживания и заканчивая крупными ремонтами и покупками",
            "disclaimer": "Этот автомобильный консалтинговый сервис на базе ИИ предназначен исключительно для информационных целей. Он не заменяет работу сертифицированных механиков или профессиональные автомобильные консультации. Пользователи должны обращаться к лицензированным специалистам для принятия важных решений, связанных с транспортными средствами. Разработчики не несут ответственности за результаты, связанные с обслуживанием автомобилей или сделками."
        },
        [officeTypes.RELIGION]: {
            "name": "Религия",
            "description": "Сервис религиозных и духовных консультаций на базе ИИ, предоставляющий информацию о различных вероисповеданиях и духовных практиках. Сервис симулирует знания религиозных ученых и духовных лидеров, предоставляя пользователям ответы на вопросы, связанные с теологией, религиозными практиками и личными убеждениями",
            "disclaimer": "Этот сервис религиозных и духовных консультаций на базе ИИ предназначен для личной рефлексии и информационных целей. Он не заменяет советы религиозных лидеров или духовных наставников. Пользователи должны обращаться к признанным религиозным авторитетам для принятия официальных религиозных решений. Разработчики не несут ответственности за действия, предпринятые на основании информации, предоставленной этим сервисом."
        },
        [officeTypes.ESOTERIC]: {
            "name": "Эзотерика",
            "description": "Сервис эзотерических и мистических консультаций на базе ИИ, предоставляющий информацию по таким практикам, как астрология, нумерология и энергетическое исцеление. Сервис предлагает персонализированные интерпретации и прогнозы, помогая пользователям исследовать альтернативные духовные и мистические убеждения",
            "disclaimer": "Этот сервис эзотерических консультаций на базе ИИ предназначен исключительно для развлечений и личного самопознания. Он не дает гарантированных предсказаний будущих событий и не предоставляет медицинских, психологических или юридических консультаций. Пользователи должны обращаться к квалифицированным специалистам для принятия важных жизненных решений. Разработчики не несут ответственности за действия, предпринятые на основании этого сервиса."
        },
        [officeTypes.TAROT]: {
            "name": "Таро",
            "description": "Сервис AI для гадания на картах Таро использует сложные алгоритмы для интерпретации раскладов Таро и предоставления персонализированных ответов на вопросы пользователей или жизненные ситуации. Симулируя традиционную мудрость Таро, он предлагает руководство и ясность, предоставляя детализированные гадания и толкования онлайн",
            "disclaimer": "Этот AI-сервис для гадания на Таро предназначен исключительно для развлечения и личных размышлений. Он не предсказывает будущее с точностью и не предоставляет профессиональных психологических, юридических или финансовых консультаций. Разработчики и владельцы данного приложения не несут ответственности за решения, принятые на основе гаданий, предоставленных этим сервисом."
        },
        [officeTypes.HOROSCOPE]: {
            "name": "Гороскопы",
            "description": "Сервис AI для гороскопов предлагает ежедневные, еженедельные и ежемесячные прогнозы, основанные на знаках зодиака пользователей. Используя продвинутые алгоритмы и астрологические данные, сервис предоставляет персонализированные инсайты в области любви, карьеры и жизненных событий. Пользователи получают индивидуальные советы и прогнозы, которые соответствуют их астрологическому профилю, помогая уверенно справляться с жизненными неясностями",
            "disclaimer": "Этот AI-сервис для гороскопов предоставляет ежедневные, еженедельные и ежемесячные прогнозы, основанные на астрологических данных. Он предназначен исключительно для развлечения и личных размышлений. Гороскопы не дают гарантированных результатов и не предоставляют профессиональных консультаций в юридических, финансовых или медицинских вопросах. Разработчики и владельцы данного приложения не несут ответственности за действия или решения, принятые на основе предоставленных гороскопов."
        },
        [officeTypes.ASTROLOGY]: {
            "name": "Астрология",
            "description": "Сервис AI для астрологии предоставляет персонализированные астрологические инсайты, анализируя натальные карты пользователей, планетарные выравнивания и астрологические транзиты. Он предлагает ежедневные, еженедельные и ежемесячные прогнозы, помогая пользователям понимать возможные вызовы и возможности. Этот сервис сочетает традиционную астрологическую мудрость с современным анализом данных для предоставления точных и значимых предсказаний",
            "disclaimer": "Этот AI-сервис для астрологии предлагает астрологические инсайты на основе традиционных интерпретаций натальных карт и данных. Он не предоставляет профессиональных психологических, финансовых или юридических консультаций. Астрология предназначена для личных размышлений и развлечения. Разработчики и владельцы данного приложения не несут ответственности за решения, принятые на основе предоставленных астрологических инсайтов."
        },
        [officeTypes.TALISMAN]: {
            "name": "Талисманы",
            "description": "Сервис AI для подбора талисманов создает персонализированные рекомендации на основе индивидуальных черт, желаний и духовных потребностей пользователей. Анализируя астрологические данные, нумерологию и символические интерпретации, сервис предлагает талисманы, предназначенные для привлечения позитивной энергии, защиты или достижения определенных целей, предлагая современный подход к древним мистическим практикам",
            "disclaimer": "Этот AI-сервис для подбора талисманов предоставляет рекомендации для личного и духовного использования. Он основан на символических интерпретациях и не гарантирует конкретных результатов или профессиональных консультаций в какой-либо форме. Разработчики и владельцы данного приложения не несут ответственности за результаты, связанные с использованием предложенных талисманов."
        },
    },
    "consultants": {
        "title": "Консультанты",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "AI Регистратор",
            "description": "Поможет подобрать подходящего специалиста"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "AI Аллерголог",
            "description": "Специалист по диагностике и лечению аллергий и связанных с ними заболеваний"
        },
        [chatModes.CHAT_HEALTH_ANESTHESIOLOGIST]: {
            "name": "AI Анестезиолог",
            "description": "Эксперт в области обезболивания и анестезии при операциях"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "AI Кардиолог",
            "description": "Специалист по заболеваниям сердца и сосудов"
        },
        [chatModes.CHAT_HEALTH_COLOPROCTOLOGIST]: {
            "name": "AI Колопроктолог",
            "description": "Эксперт по диагностике и лечению заболеваний толстой кишки, прямой кишки и ануса"
        },
        [chatModes.CHAT_HEALTH_COSMETOLOGIST]: {
            "name": "AI Косметолог",
            "description": "Специалист по уходу за кожей и эстетическим процедурам"
        },
        [chatModes.CHAT_HEALTH_DENTIST]: {
            "name": "AI Стоматолог",
            "description": "Специалист по лечению и профилактике заболеваний зубов и десен"
        },
        [chatModes.CHAT_HEALTH_DERMATOLOGIST]: {
            "name": "AI Дерматолог",
            "description": "Эксперт по уходу за кожей и лечению кожных заболеваний"
        },
        [chatModes.CHAT_HEALTH_DIETITIAN]: {
            "name": "AI Диетолог",
            "description": "Специалист по питанию и составлению рационов для здоровья"
        },
        [chatModes.CHAT_HEALTH_ENDOCRINOLOGIST]: {
            "name": "AI Эндокринолог",
            "description": "Эксперт по гормональным нарушениям и обмену веществ"
        },
        [chatModes.CHAT_HEALTH_ENDOSCOPIST]: {
            "name": "AI Эндоскопист",
            "description": "Специалист по эндоскопическим исследованиям и лечению внутренних органов"
        },
        [chatModes.CHAT_HEALTH_GASTROENTEROLOGIST]: {
            "name": "AI Гастроэнтеролог",
            "description": "Эксперт по заболеваниям пищеварительной системы"
        },
        [chatModes.CHAT_HEALTH_GYNECOLOGIST]: {
            "name": "AI Гинеколог",
            "description": "Специалист по женскому здоровью, включая репродуктивную систему и менструальные циклы"
        },
        [chatModes.CHAT_HEALTH_MAMMOLOGIST]: {
            "name": "AI Маммолог",
            "description": "Специалист по диагностике и лечению заболеваний молочной железы, диагностике и лечению рака груди"
        },
        [chatModes.CHAT_HEALTH_IMMUNOLOGIST]: {
            "name": "AI Иммунолог",
            "description": "Эксперт по заболеваниям и нарушениям иммунной системы"
        },
        [chatModes.CHAT_HEALTH_INFECTIOLOGIST]: {
            "name": "AI Инфекционист",
            "description": "Специалист по лечению инфекционных заболеваний"
        },
        [chatModes.CHAT_HEALTH_NEPHROLOGIST]: {
            "name": "AI Нефролог",
            "description": "Эксперт по заболеваниям почек и почечной недостаточности"
        },
        [chatModes.CHAT_HEALTH_NEUROLOGIST]: {
            "name": "AI Невролог",
            "description": "Специалист по заболеваниям головного и спинного мозга, а также нервной системы"
        },
        [chatModes.CHAT_HEALTH_ONCOLOGIST]: {
            "name": "AI Онколог",
            "description": "Эксперт по диагностике и лечению онкологических заболеваний"
        },
        [chatModes.CHAT_HEALTH_OPHTHALMOLOGIST]: {
            "name": "AI Офтальмолог",
            "description": "Специалист по диагностике и лечению заболеваний глаз"
        },
        [chatModes.CHAT_HEALTH_ORTHOPEDIST]: {
            "name": "AI Ортопед",
            "description": "Эксперт по заболеваниям и травмам костей, суставов и опорно-двигательного аппарата"
        },
        [chatModes.CHAT_HEALTH_OTOLARYNGOLOGIST]: {
            "name": "AI Отоларинголог",
            "description": "Специалист по заболеваниям уха, горла и носа"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "AI Педиатр",
            "description": "Эксперт по детскому здоровью и лечению заболеваний у детей"
        },
        [chatModes.CHAT_HEALTH_PHLEBOLOGIST]: {
            "name": "AI Флеболог",
            "description": "Специалист по заболеваниям вен, таким как варикоз"
        },
        [chatModes.CHAT_HEALTH_PHYSIOTHERAPIST]: {
            "name": "AI Физиотерапевт",
            "description": "Эксперт по физической реабилитации и восстановлению после травм"
        },
        [chatModes.CHAT_HEALTH_PLASTIC_SURGEON]: {
            "name": "AI Пластический хирург",
            "description": "Специалист по эстетической и реконструктивной хирургии"
        },
        [chatModes.CHAT_HEALTH_PSYCHIATRIST]: {
            "name": "AI Психиатр",
            "description": "Эксперт по диагностике и лечению психических расстройств"
        },
        [chatModes.CHAT_HEALTH_PSYCHOTHERAPIST]: {
            "name": "AI Психотерапевт",
            "description": "Специалист по эмоциональному здоровью и психологическому консультированию"
        },
        [chatModes.CHAT_HEALTH_PULMONOLOGIST]: {
            "name": "AI Пульмонолог",
            "description": "Эксперт по заболеваниям легких и дыхательных путей"
        },
        [chatModes.CHAT_HEALTH_REHABILITOLOGIST]: {
            "name": "AI Реабилитолог",
            "description": "Специалист по реабилитационной медицине и восстановлению после болезней"
        },
        [chatModes.CHAT_HEALTH_RHEUMATOLOGIST]: {
            "name": "AI Ревматолог",
            "description": "Специалист по артриту и аутоиммунным заболеваниям, поражающим суставы"
        },
        [chatModes.CHAT_HEALTH_SEXOLOGIST]: {
            "name": "AI Сексолог",
            "description": "Эксперт по вопросам сексуального здоровья и взаимоотношений"
        },
        [chatModes.CHAT_HEALTH_SOMNOLOGIST]: {
            "name": "AI Сомнолог",
            "description": "Специалист по диагностике и лечению нарушений сна"
        },
        [chatModes.CHAT_HEALTH_SURGEON]: {
            "name": "AI Хирург",
            "description": "Эксперт по хирургическому лечению различных заболеваний и травм"
        },
        [chatModes.CHAT_HEALTH_THERAPIST]: {
            "name": "AI Терапевт",
            "description": "Специалист по общим вопросам здоровья и профилактике заболеваний"
        },
        [chatModes.CHAT_HEALTH_TRAUMATOLOGIST]: {
            "name": "AI Травматолог",
            "description": "Эксперт по лечению травм и повреждений опорно-двигательной системы"
        },
        [chatModes.CHAT_HEALTH_UROLOGIST]: {
            "name": "AI Уролог",
            "description": "Специалист по мочевыводящей системе и репродуктивному здоровью"
        },
        [chatModes.CHAT_HEALTH_VASCULAR_SURGEON]: {
            "name": "AI Сосудистый хирург",
            "description": "Эксперт по заболеваниям кровеносных сосудов и их хирургическому лечению"
        },
        [chatModes.CHAT_HEALTH_PHARMACIST]: {
            "name": "AI Фармацевт",
            "description": "Эксперт по подбору и применению лекарств"
        },
        [chatModes.CHAT_ALTMED_ACUPUNCTURE]: {
            "name": "AI Специалист по акупунктуре",
            "description": "Специалист по акупунктуре, использующий тонкие иглы для стимуляции энергетических точек в организме с целью улучшения здоровья и восстановления баланса."
        },
        [chatModes.CHAT_ALTMED_HERBAL_MEDICINE]: {
            "name": "AI Специалист по траволечению",
            "description": "Эксперт по использованию лекарственных растений и трав для поддержки здоровья и естественного лечения различных заболеваний."
        },
        [chatModes.CHAT_ALTMED_HOMEOPATHY]: {
            "name": "AI Гомеопат",
            "description": "Специалист по гомеопатии, предлагающий лечение на основе принципа 'подобное лечит подобным', используя сильно разведенные вещества."
        },
        [chatModes.CHAT_ALTMED_REFLEXOLOGY]: {
            "name": "AI Рефлексотерапевт",
            "description": "Специалист по рефлексотерапии, фокусирующийся на надавливании на определенные зоны стоп, рук и ушей для улучшения здоровья соответствующих частей тела."
        },
        [chatModes.CHAT_ALTMED_REIKI]: {
            "name": "AI Специалист по Рэйки",
            "description": "Эксперт по Рэйки — японской технике энергетического исцеления, использующей легкое прикосновение или положения рук для восстановления энергетического баланса и расслабления."
        },
        [chatModes.CHAT_ALTMED_CHIROPRACTOR]: {
            "name": "AI Хиропрактик",
            "description": "Специалист по хиропрактике, диагностирующий и лечащий опорно-двигательные нарушения, особенно связанные с позвоночником, с помощью мануальных методов."
        },
        [chatModes.CHAT_ALTMED_NATUROPATHY]: {
            "name": "AI Натуропат",
            "description": "Специалист по натуропатии, использующий природные методы лечения, такие как питание, травы и изменения образа жизни, для поддержания здоровья и профилактики заболеваний."
        },
        [chatModes.CHAT_ALTMED_AROMATHERAPY]: {
            "name": "AI Ароматерапевт",
            "description": "Эксперт по ароматерапии, использующий эфирные масла растений для улучшения эмоционального и физического благополучия через ингаляцию или нанесение на кожу."
        },
        [chatModes.CHAT_ALTMED_AYURVEDA]: {
            "name": "AI Специалист по Аюрведе",
            "description": "Практик Аюрведы — древней индийской системы медицины, направленной на достижение баланса между разумом, телом и духом через диету, травы и физические практики."
        },
        [chatModes.CHAT_ALTMED_TCM]: {
            "name": "AI Специалист по традиционной китайской медицине",
            "description": "Эксперт по традиционной китайской медицине (ТКМ), использующий холистический подход с акупунктурой, траволечением и регуляцией энергии Ци для восстановления здоровья и баланса."
        },
        [chatModes.CHAT_ALTMED_HYDROTHERAPY]: {
            "name": "AI Специалист по гидротерапии",
            "description": "Специалист по гидротерапии, использующий водные процедуры, такие как ванны, пар и компрессы, для облегчения боли, улучшения циркуляции и ускорения восстановления."
        },
        [chatModes.CHAT_ALTMED_BIOENERGETICS]: {
            "name": "AI Специалист по биоэнергетике",
            "description": "Эксперт по биоэнергетике, фокусирующийся на энергетических системах организма для освобождения от эмоционального и физического напряжения через движения и дыхательные упражнения."
        },
        [chatModes.CHAT_ALTMED_HYPNOTHERAPY]: {
            "name": "AI Гипнотерапевт",
            "description": "Специалист по гипнотерапии, использующий управляемое расслабление и концентрацию для доступа к подсознанию с целью изменения привычек или уменьшения стресса."
        },
        [chatModes.CHAT_ALTMED_CRANIOSACRAL]: {
            "name": "AI Специалист по краниосакральной терапии",
            "description": "Практик краниосакральной терапии, использующий легкие манипуляции черепа и позвоночника для снятия напряжения и улучшения общего здоровья."
        },
        [chatModes.CHAT_ALTMED_SOUND_THERAPY]: {
            "name": "AI Специалист по звуковой терапии",
            "description": "Эксперт по звуковой терапии, использующий звуки, вибрации и музыку для достижения релаксации, снижения стресса и балансировки энергии в организме."
        },
        [chatModes.CHAT_ALTMED_THERMOTHERAPY]: {
            "name": "AI Специалист по термотерапии",
            "description": "Эксперт по термотерапии, использующий тепло, такое как горячие компрессы, инфракрасную терапию и термальные ванны, для облегчения боли и улучшения циркуляции."
        },
        [chatModes.CHAT_ALTMED_FITOTHERAPY]: {
            "name": "AI Специалист по фитотерапии",
            "description": "Специалист по фитотерапии, использующий лекарственные растения для профилактики и лечения широкого круга заболеваний."
        },
        [chatModes.CHAT_PSYCHO_REGULAR]: {
            "name": "AI Психолог общей практики",
            "description": "Психолог, специализирующийся на общих вопросах психического здоровья, оказывающий поддержку в решении широкого спектра эмоциональных и психологических проблем"
        },
        [chatModes.CHAT_PSYCHO_FAMILY]: {
            "name": "AI Семейный психолог",
            "description": "Специалист по семейной динамике и отношениям, предоставляющий терапию для улучшения общения и разрешения конфликтов в семье"
        },
        [chatModes.CHAT_PSYCHO_COUPLES]: {
            "name": "AI Психолог для пар",
            "description": "Эксперт по консультированию пар, помогающий справиться с трудностями в отношениях и улучшить эмоциональную связь"
        },
        [chatModes.CHAT_PSYCHO_CHILD]: {
            "name": "AI Детский психолог",
            "description": "Специалист по детской психологии, сосредоточенный на эмоциональном, социальном и поведенческом развитии детей"
        },
        [chatModes.CHAT_PSYCHO_SCHOOL]: {
            "name": "AI Школьный психолог",
            "description": "Эксперт по образовательной и развивающей психологии, поддерживающий учеников в решении академических, социальных и эмоциональных проблем"
        },
        [chatModes.CHAT_PSYCHO_COGNITIVE_BEHAVIORAL]: {
            "name": "AI Психолог когнитивно-поведенческой терапии",
            "description": "Психолог, использующий когнитивно-поведенческую терапию (КПТ) для изменения негативных моделей мышления и поведения"
        },
        [chatModes.CHAT_PSYCHO_GESTALT]: {
            "name": "AI Гештальт-психолог",
            "description": "Специалист по гештальт-терапии, сосредотачивающий внимание на самосознании и настоящем моменте для улучшения эмоционального состояния"
        },
        [chatModes.CHAT_PSYCHO_PSYCHODYNAMIC]: {
            "name": "AI Психодинамический психолог",
            "description": "Эксперт по психодинамической терапии, исследующий бессознательные мысли и эмоции для понимания текущего поведения"
        },
        [chatModes.CHAT_PSYCHO_CLINICAL]: {
            "name": "AI Клинический психолог",
            "description": "Клинический психолог, специализирующийся на диагностике и лечении серьезных психических расстройств и предоставлении терапевтических вмешательств"
        },
        [chatModes.CHAT_PSYCHO_SPORTS]: {
            "name": "AI Спортивный психолог",
            "description": "Специалист по спортивной психологии, помогающий спортсменам улучшить результаты и психологическую устойчивость в конкурентных условиях"
        },
        [chatModes.CHAT_PSYCHO_ORGANIZATIONAL]: {
            "name": "AI Организационный психолог",
            "description": "Эксперт по психологии рабочего места, предоставляющий информацию о благополучии сотрудников, организационном поведении и продуктивности"
        },
        [chatModes.CHAT_PSYCHO_TRAUMA]: {
            "name": "AI Психолог по травматерапии",
            "description": "Специалист по терапии травм, помогающий людям справиться с эмоциональными и психологическими последствиями травм"
        },
        [chatModes.CHAT_PSYCHO_NEUROPSYCHOLOGIST]: {
            "name": "AI Нейропсихолог",
            "description": "Эксперт по нейропсихологии, сосредоточенный на том, как функции мозга влияют на поведение, когнитивные процессы и эмоции"
        },
        [chatModes.CHAT_PSYCHO_EATING_DISORDER]: {
            "name": "AI Психолог по расстройствам пищевого поведения",
            "description": "Психолог, специализирующийся на лечении расстройств пищевого поведения, таких как анорексия, булимия и переедание"
        },
        [chatModes.CHAT_PSYCHO_NARCOLOGIST]: {
            "name": "AI Психолог-нарколог",
            "description": "Эксперт по лечению зависимостей, включая наркозависимость и другие аддиктивные поведения"
        },
        [chatModes.CHAT_PSYCHO_ADDICTION]: {
            "name": "AI Специалист по зависимостям",
            "description": "Специалист по терапии зависимостей, оказывающий поддержку в преодолении наркозависимости и поведенческих аддикций"
        },
        [chatModes.CHAT_PSYCHO_GRIEF]: {
            "name": "AI Психолог по вопросам горя и утраты",
            "description": "Эксперт по консультированию по вопросам горя, помогающий людям справиться с потерей и горем"
        },
        [chatModes.CHAT_PSYCHO_WORKPLACE]: {
            "name": "AI Психолог по вопросам рабочего места",
            "description": "Психолог, специализирующийся на психических проблемах, связанных с рабочей средой, включая стресс и баланс работы и жизни"
        },
        [chatModes.CHAT_PSYCHO_SEXUALITY]: {
            "name": "AI Психолог по вопросам сексуальности",
            "description": "Специалист по сексуальному здоровью и отношениям, решающий вопросы, связанные с сексуальностью и сексуальным благополучием"
        },
        [chatModes.CHAT_PSYCHO_LGBTQ]: {
            "name": "AI Психолог для LGBTQ+",
            "description": "Психолог, предоставляющий поддержку для LGBTQ+ людей, с акцентом на вопросы идентичности, отношений и психического здоровья"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "AI Ветеринарный Регистратор",
            "description": "Поможет выбрать подходящего ветеринарного специалиста"
        },
        [chatModes.CHAT_VET_ALLERGIST]: {
            "name": "AI Ветеринарный Аллерголог",
            "description": "Специалист по диагностике и лечению аллергий у животных"
        },
        [chatModes.CHAT_VET_ANESTHESIOLOGIST]: {
            "name": "AI Ветеринарный Анестезиолог",
            "description": "Эксперт по анестезии и обезболиванию у животных во время операций"
        },
        [chatModes.CHAT_VET_CARDIOLOGIST]: {
            "name": "AI Ветеринарный Кардиолог",
            "description": "Специалист по здоровью сердца и сосудов у животных"
        },
        [chatModes.CHAT_VET_COLOPROCTOLOGIST]: {
            "name": "AI Ветеринарный Колопроктолог",
            "description": "Эксперт по диагностике и лечению заболеваний толстой кишки и прямой кишки у животных"
        },
        [chatModes.CHAT_VET_COSMETOLOGIST]: {
            "name": "AI Ветеринарный Косметолог",
            "description": "Специалист по уходу за кожей животных и эстетическим процедурам"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "AI Ветеринарный Стоматолог",
            "description": "Специалист по уходу за зубами и полостью рта у животных"
        },
        [chatModes.CHAT_VET_DERMATOLOGIST]: {
            "name": "AI Ветеринарный Дерматолог",
            "description": "Эксперт по диагностике и лечению кожных заболеваний у питомцев"
        },
        [chatModes.CHAT_VET_DIETITIAN]: {
            "name": "AI Ветеринарный Диетолог",
            "description": "Специалист по питанию и диетам для домашних животных"
        },
        [chatModes.CHAT_VET_ENDOCRINOLOGIST]: {
            "name": "AI Ветеринарный Эндокринолог",
            "description": "Эксперт по гормональным и метаболическим нарушениям у животных"
        },
        [chatModes.CHAT_VET_ENDOSCOPIST]: {
            "name": "AI Ветеринарный Эндоскопист",
            "description": "Специалист по эндоскопическим исследованиям для диагностики внутренних заболеваний у животных"
        },
        [chatModes.CHAT_VET_GASTROENTEROLOGIST]: {
            "name": "AI Ветеринарный Гастроэнтеролог",
            "description": "Эксперт по диагностике и лечению заболеваний пищеварительной системы у животных"
        },
        [chatModes.CHAT_VET_GYNECOLOGIST]: {
            "name": "AI Ветеринарный Гинеколог",
            "description": "Специалист по репродуктивному здоровью и уходу за самками животных"
        },
        [chatModes.CHAT_VET_IMMUNOLOGIST]: {
            "name": "AI Ветеринарный Иммунолог",
            "description": "Эксперт по заболеваниям иммунной системы у животных"
        },
        [chatModes.CHAT_VET_INFECTIOLOGIST]: {
            "name": "AI Ветеринарный Инфекционист",
            "description": "Специалист по лечению инфекционных заболеваний у животных"
        },
        [chatModes.CHAT_VET_NEPHROLOGIST]: {
            "name": "AI Ветеринарный Нефролог",
            "description": "Эксперт по здоровью почек и лечению почечных заболеваний у животных"
        },
        [chatModes.CHAT_VET_NEUROLOGIST]: {
            "name": "AI Ветеринарный Невролог",
            "description": "Специалист по заболеваниям головного мозга, спинного мозга и нервной системы у животных"
        },
        [chatModes.CHAT_VET_ONCOLOGIST]: {
            "name": "AI Ветеринарный Онколог",
            "description": "Эксперт по диагностике и лечению рака у животных"
        },
        [chatModes.CHAT_VET_OPHTHALMOLOGIST]: {
            "name": "AI Ветеринарный Офтальмолог",
            "description": "Специалист по уходу за зрением и лечению глазных заболеваний у животных"
        },
        [chatModes.CHAT_VET_ORTHOPEDIST]: {
            "name": "AI Ветеринарный Ортопед",
            "description": "Эксперт по диагностике и лечению заболеваний костей, суставов и опорно-двигательного аппарата у животных"
        },
        [chatModes.CHAT_VET_OTOLARYNGOLOGIST]: {
            "name": "AI Ветеринарный Отоларинголог",
            "description": "Специалист по заболеваниям уха, носа и горла у животных"
        },
        [chatModes.CHAT_VET_PEDIATRICIAN]: {
            "name": "AI Ветеринарный Педиатр",
            "description": "Эксперт по уходу за молодыми животными, включая щенков и котят"
        },
        [chatModes.CHAT_VET_PHLEBOLOGIST]: {
            "name": "AI Ветеринарный Флеболог",
            "description": "Специалист по лечению заболеваний вен и циркуляторных нарушений у животных"
        },
        [chatModes.CHAT_VET_PHYSIOTHERAPIST]: {
            "name": "AI Ветеринарный Физиотерапевт",
            "description": "Эксперт по реабилитации и восстановлению животных после травм и операций"
        },
        [chatModes.CHAT_VET_PLASTIC_SURGEON]: {
            "name": "AI Ветеринарный Пластический Хирург",
            "description": "Специалист по косметической и реконструктивной хирургии для животных"
        },
        [chatModes.CHAT_VET_PSYCHIATRIST]: {
            "name": "AI Ветеринарный Психиатр",
            "description": "Эксперт по диагностике и лечению психических и поведенческих нарушений у животных"
        },
        [chatModes.CHAT_VET_PSYCHOTHERAPIST]: {
            "name": "AI Ветеринарный Психотерапевт",
            "description": "Специалист по поведенческой терапии и эмоциональному здоровью животных"
        },
        [chatModes.CHAT_VET_PULMONOLOGIST]: {
            "name": "AI Ветеринарный Пульмонолог",
            "description": "Эксперт по заболеваниям легких и дыхательных путей у животных"
        },
        [chatModes.CHAT_VET_RHEUMATOLOGIST]: {
            "name": "AI Ветеринарный Ревматолог",
            "description": "Специалист по диагностике и лечению артрита и аутоиммунных заболеваний у животных"
        },
        [chatModes.CHAT_VET_SEXOLOGIST]: {
            "name": "AI Ветеринарный Сексолог",
            "description": "Специалист по вопросам сексуального здоровья и репродукции у животных"
        },
        [chatModes.CHAT_VET_SOMNOLOGIST]: {
            "name": "AI Ветеринарный Сомнолог",
            "description": "Эксперт по диагностике и лечению нарушений сна у домашних животных"
        },
        [chatModes.CHAT_VET_SURGEON]: {
            "name": "AI Ветеринарный Хирург",
            "description": "Специалист по хирургическому лечению широкого спектра заболеваний у животных"
        },
        [chatModes.CHAT_VET_THERAPIST]: {
            "name": "AI Ветеринарный Терапевт",
            "description": "Специалист по общему здоровью животных и лечению распространенных заболеваний"
        },
        [chatModes.CHAT_VET_TRAINER]: {
            "name": "AI Дрессировщик животных",
            "description": "Дрессировщик обучает домашних животных различным командам и техникам"
        },
        [chatModes.CHAT_VET_TRAUMATOLOGIST]: {
            "name": "AI Ветеринарный Травматолог",
            "description": "Эксперт по лечению травм и повреждений у домашних животных"
        },
        [chatModes.CHAT_VET_UROLOGIST]: {
            "name": "AI Ветеринарный Уролог",
            "description": "Специалист по лечению мочевыводящей системы и репродуктивному здоровью животных"
        },
        [chatModes.CHAT_VET_VASCULAR_SURGEON]: {
            "name": "AI Ветеринарный Сосудистый Хирург",
            "description": "Эксперт по лечению заболеваний кровеносных сосудов у животных"
        },
        [chatModes.CHAT_TEACHER_LANGUAGE]: {
            "name": "AI Учитель по языку",
            "description": "Специалист по обучению языкам, помогает ученикам улучшить языковые навыки и овладеть как родным, так и иностранными языками"
        },
        [chatModes.CHAT_TEACHER_LITERATURE]: {
            "name": "AI Учитель по литературе",
            "description": "Эксперт по литературе, помогает учащимся изучать классические и современные литературные произведения, развивая навыки критического чтения и анализа"
        },
        [chatModes.CHAT_TEACHER_MATH]: {
            "name": "AI Учитель по математике",
            "description": "Специалист по математике, помогает ученикам осваивать сложные математические концепции и развивать навыки решения задач"
        },
        [chatModes.CHAT_TEACHER_GEOMETRY]: {
            "name": "AI Учитель по геометрии",
            "description": "Эксперт по геометрии, помогает ученикам изучать формы, углы и пространственное мышление"
        },
        [chatModes.CHAT_TEACHER_PHYSICS]: {
            "name": "AI Учитель по физике",
            "description": "Эксперт по физике, помогает учащимся понять законы физики и их применение в реальной жизни через интерактивное обучение"
        },
        [chatModes.CHAT_TEACHER_ASTRONOMY]: {
            "name": "AI Учитель по астрономии",
            "description": "Специалист по астрономии, помогает ученикам исследовать вселенную, небесные тела и космические явления"
        },
        [chatModes.CHAT_TEACHER_CHEMISTRY]: {
            "name": "AI Учитель по химии",
            "description": "Специалист по химии, обучает основам химических реакций, молекулярных структур и безопасного поведения в лаборатории"
        },
        [chatModes.CHAT_TEACHER_ART]: {
            "name": "AI Учитель по искусству",
            "description": "Эксперт по изобразительным искусствам, помогает ученикам выражать креативность и овладевать различными художественными техниками, такими как рисование и скульптура"
        },
        [chatModes.CHAT_TEACHER_MUSIC]: {
            "name": "AI Учитель по музыке",
            "description": "Музыкальный педагог, помогает ученикам понимать музыкальную теорию, улучшать игру на инструментах и развивать глубокую любовь к музыке"
        },
        [chatModes.CHAT_TEACHER_PHYSICAL_EDUCATION]: {
            "name": "AI Учитель по физкультуре",
            "description": "Эксперт по физическому воспитанию, продвигает физическую активность, обучает спортивным играм и помогает поддерживать здоровый образ жизни"
        },
        [chatModes.CHAT_TEACHER_TECHNOLOGY]: {
            "name": "AI Учитель по технологии",
            "description": "Эксперт по образовательным технологиям, обучает эффективному использованию цифровых инструментов и пониманию их влияния на повседневную жизнь"
        },
        [chatModes.CHAT_TEACHER_COMPUTER_SCIENCE]: {
            "name": "AI Учитель по информатике",
            "description": "Эксперт по информатике, обучает студентов основам вычислительной техники, программной инженерии и алгоритмическому решению задач"
        },
        [chatModes.CHAT_TEACHER_DATA_SCIENCE]: {
            "name": "AI Учитель по науке о данных",
            "description": "Специалист по обработке данных, обучает анализу данных, статистике и машинному обучению, подготавливая студентов к карьере в сфере технологий"
        },
        [chatModes.CHAT_TEACHER_PROGRAMMING]: {
            "name": "AI Учитель по программированию",
            "description": "Эксперт по программированию, помогает учащимся изучать языки программирования и развивать навыки разработки программного обеспечения"
        },
        [chatModes.CHAT_TEACHER_GRAPHIC_DESIGN]: {
            "name": "AI Учитель по графическому дизайну",
            "description": "Специалист по графическому дизайну, обучает основам цифрового дизайна, включая визуальные коммуникации и работу с программами для дизайна"
        },
        [chatModes.CHAT_TEACHER_LAW]: {
            "name": "AI Учитель по праву",
            "description": "Юридический педагог, помогает ученикам понять основы права, правовые системы и готовит их к карьере в юридической сфере"
        },
        [chatModes.CHAT_TEACHER_CIVICS]: {
            "name": "AI Учитель по обществознанию",
            "description": "Эксперт по обществознанию, обучает учеников основам государственной власти, гражданским правам и обязанностям"
        },
        [chatModes.CHAT_TEACHER_HISTORY]: {
            "name": "AI Учитель по истории",
            "description": "Специалист по истории, помогает ученикам изучать прошлые события и цивилизации для лучшего понимания настоящего"
        },
        [chatModes.CHAT_TEACHER_GEOGRAPHY]: {
            "name": "AI Учитель по географии",
            "description": "Эксперт по географии, помогает ученикам понять физические особенности Земли и взаимодействие человека с окружающей средой"
        },
        [chatModes.CHAT_TEACHER_BIOLOGY]: {
            "name": "AI Учитель по биологии",
            "description": "Специалист по биологии, обучает студентов принципам жизненных наук, изучению организмов и экосистем"
        },
        [chatModes.CHAT_TEACHER_ENVIRONMENTAL_SCIENCE]: {
            "name": "AI Учитель по экологии",
            "description": "Эксперт по экологическим наукам, обучает вопросам экосистем, устойчивого развития и влияния человеческой деятельности на природу"
        },
        [chatModes.CHAT_TEACHER_RELIGION]: {
            "name": "AI Учитель по религии",
            "description": "Эксперт по религиоведению, помогает ученикам изучать различные мировые религии, их верования и культурное значение"
        },
        [chatModes.CHAT_TEACHER_PHILOSOPHY]: {
            "name": "AI Учитель по философии",
            "description": "Педагог по философии, направляющий студентов в исследовании вопросов бытия, этики и истории философской мысли"
        },
        [chatModes.CHAT_TEACHER_ECONOMICS]: {
            "name": "AI Учитель по экономике",
            "description": "Экономический педагог, обучает студентов микроэкономике, макроэкономике и финансовой грамотности"
        },
        [chatModes.CHAT_TEACHER_BUSINESS]: {
            "name": "AI Учитель по предпринимательству",
            "description": "Эксперт по бизнесу, обучает студентов предпринимательским навыкам, управлению бизнесом и основам экономики"
        },
        [chatModes.CHAT_TEACHER_MARKETING]: {
            "name": "AI Учитель по маркетингу",
            "description": "Маркетинговый специалист, обучает студентов основам брендинга, маркетинговым исследованиям и рекламным стратегиям"
        },
        [chatModes.CHAT_TEACHER_SOCIOLOGY]: {
            "name": "AI Учитель по социологии",
            "description": "Эксперт по социологии, помогает ученикам изучать социальные структуры, человеческое поведение и культурные взаимодействия"
        },
        [chatModes.CHAT_TEACHER_PSYCHOLOGY]: {
            "name": "AI Учитель по психологии",
            "description": "Педагог по психологии, обучающий студентов основам работы человеческого разума, поведения и когнитивных процессов"
        },
        [chatModes.CHAT_TEACHER_MEDICINE]: {
            "name": "AI Учитель по медицине",
            "description": "Эксперт по медицинскому образованию, помогает студентам изучать анатомию, физиологию и готовиться к карьере в сфере здравоохранения"
        },
        [chatModes.CHAT_TEACHER_DRAMA]: {
            "name": "AI Учитель по театральному искусству",
            "description": "Преподаватель по театральному искусству, обучающий учеников актёрским техникам, постановке спектаклей и навыкам выступления"
        },
        [chatModes.CHAT_TEACHER_ORATORY]: {
            "name": "AI Учитель по ораторскому искусству",
            "description": "Эксперт по публичным выступлениям, помогает ученикам развивать навыки коммуникации и презентации в различных контекстах"
        },
        [chatModes.CHAT_TEACHER_FILMMAKING]: {
            "name": "AI Учитель по кинематографии",
            "description": "Специалист по кинематографии, направляющий учеников через творческие и технические аспекты производства фильмов"
        },
        [chatModes.CHAT_BUSINESS_STRATEGY]: {
            "name": "AI Эксперт по бизнес-стратегии",
            "description": "Специалист по разработке бизнес-стратегий, помогает компаниям ставить долгосрочные цели и создавать планы для их достижения"
        },
        [chatModes.CHAT_BUSINESS_DEVELOPMENT]: {
            "name": "AI Эксперт по развитию бизнеса",
            "description": "Фокусируется на росте бизнеса, выявлении новых рынков, возможностей и партнерств для увеличения доходов и расширения"
        },
        [chatModes.CHAT_BUSINESS_STARTUP]: {
            "name": "AI Консультант по стартапам",
            "description": "Специалист по развитию стартапов, предоставляет рекомендации по запуску новых предприятий, масштабированию и преодолению уникальных проблем стартапов"
        },
        [chatModes.CHAT_BUSINESS_INVESTMENT]: {
            "name": "AI Консультант по инвестициям",
            "description": "Эксперт в инвестиционных стратегиях, консультирует по управлению портфелем, привлечению финансирования и обеспечению финансовой поддержки бизнеса"
        },
        [chatModes.CHAT_BUSINESS_FINANCE]: {
            "name": "AI Эксперт по финансам",
            "description": "Специалист по управлению финансами, оказывает помощь в планировании бюджета, управлении денежными потоками и финансовом планировании для улучшения показателей бизнеса"
        },
        [chatModes.CHAT_BUSINESS_MARKETING]: {
            "name": "AI Консультант по маркетингу",
            "description": "Эксперт по маркетинговым стратегиям, помогает компаниям улучшить бренд, взаимодействовать с клиентами и расширять охват рынка"
        },
        [chatModes.CHAT_BUSINESS_SALES]: {
            "name": "AI Консультант по продажам",
            "description": "Фокусируется на улучшении процессов продаж, предоставляет техники для повышения производительности, вовлеченности клиентов и увеличения доходов"
        },
        [chatModes.CHAT_BUSINESS_HR]: {
            "name": "AI Консультант по управлению персоналом",
            "description": "Специалист по управлению персоналом, оказывает помощь в привлечении талантов, управлении вовлеченностью сотрудников и управлении кадрами"
        },
        [chatModes.CHAT_BUSINESS_OPERATIONS]: {
            "name": "AI Консультант по операционной деятельности",
            "description": "Эксперт в оптимизации бизнес-процессов для повышения эффективности, сокращения расходов и улучшения производительности"
        },
        [chatModes.CHAT_BUSINESS_TECHNOLOGY]: {
            "name": "AI Консультант по технологиям",
            "description": "Специалист в области технологий, помогает внедрять инновационные решения для улучшения бизнес-процессов и ускорения роста"
        },
        [chatModes.CHAT_BUSINESS_IT]: {
            "name": "AI Консультант по ИТ",
            "description": "Эксперт в области информационных технологий, помогает управлять ИТ-инфраструктурой и внедрять технологические решения"
        },
        [chatModes.CHAT_BUSINESS_LEGAL]: {
            "name": "AI Юрисконсульт",
            "description": "Специалист по правовым вопросам, консультирует по корпоративному праву, вопросам соблюдения нормативных требований и управлению контрактами"
        },
        [chatModes.CHAT_BUSINESS_ACCOUNTING]: {
            "name": "AI Консультант по бухгалтерии",
            "description": "Эксперт по финансовому учету, помогает вести точные финансовые записи и соблюдать бухгалтерские стандарты"
        },
        [chatModes.CHAT_BUSINESS_TAX]: {
            "name": "AI Консультант по налогам",
            "description": "Специалист по налоговой оптимизации, предлагает стратегии для минимизации налоговых обязательств и соблюдения налоговых норм"
        },
        [chatModes.CHAT_BUSINESS_TRAVEL]: {
            "name": "AI Консультант по организации командировок",
            "description": "Фокусируется на организации бизнес-поездок, оптимизации бюджета на поездки и обеспечении бесперебойной логистики"
        },
        [chatModes.CHAT_BUSINESS_DOCUMENT_MANAGEMENT]: {
            "name": "AI Эксперт по документообороту",
            "description": "Специалист по управлению документами, предлагает решения для улучшения эффективности работы с документами и записями"
        },
        [chatModes.CHAT_BUSINESS_SUPPLY_CHAIN]: {
            "name": "AI Консультант по цепочкам поставок",
            "description": "Эксперт в управлении цепочками поставок, оптимизации закупок, логистики и инвентаризации для экономичной работы"
        },
        [chatModes.CHAT_BUSINESS_PROJECT_MANAGEMENT]: {
            "name": "AI Консультант по управлению проектами",
            "description": "Фокусируется на лучших практиках управления проектами, помогает компаниям завершать проекты в срок, в рамках бюджета и в установленном объеме"
        },
        [chatModes.CHAT_BUSINESS_CHANGE_MANAGEMENT]: {
            "name": "AI Консультант по управлению изменениями",
            "description": "Специалист по управлению изменениями, помогает организациям плавно пройти через изменения и минимизировать сбои в работе"
        },
        [chatModes.CHAT_BUSINESS_RISK_MANAGEMENT]: {
            "name": "AI Консультант по управлению рисками",
            "description": "Эксперт по выявлению и минимизации рисков, помогает компаниям выстраивать устойчивость и защищаться от потенциальных угроз"
        },
        [chatModes.CHAT_BUSINESS_SUSTAINABILITY]: {
            "name": "AI Консультант по устойчивому развитию",
            "description": "Фокусируется на устойчивом развитии, консультирует по стратегиям снижения экологического воздействия и повышения социальной ответственности"
        },
        [chatModes.CHAT_BUSINESS_INNOVATION]: {
            "name": "AI Консультант по инновациям",
            "description": "Специалист в области инноваций, помогает компаниям разрабатывать новые продукты, услуги и процессы для сохранения конкурентоспособности"
        },
        [chatModes.CHAT_BUSINESS_EXPORT_IMPORT]: {
            "name": "AI Консультант по экспорту и импорту",
            "description": "Эксперт в области международной торговли, консультирует по вопросам регулирования, логистики и стратегии успешных операций по экспорту и импорту"
        },
        [chatModes.CHAT_BUSINESS_FRANCHISING]: {
            "name": "AI Консультант по франчайзингу",
            "description": "Специалист по франчайзингу, помогает компаниям расширять бренд через развитие и управление франшизами"
        },
        [chatModes.CHAT_BUSINESS_MERGE_ACQUISITION]: {
            "name": "AI Консультант по слияниям и поглощениям",
            "description": "Эксперт по сделкам слияний и поглощений, предоставляет рекомендации по структуре сделок, юридической проверке и интеграции после слияния"
        },
        [chatModes.CHAT_BUSINESS_CUSTOMER_SERVICE]: {
            "name": "AI Консультант по клиентскому сервису",
            "description": "Фокусируется на улучшении операций клиентского сервиса, повышении удовлетворенности клиентов и укреплении лояльности"
        },
        [chatModes.CHAT_BUSINESS_COMPLIANCE]: {
            "name": "AI Консультант по вопросам соблюдения стандартов",
            "description": "Специалист по нормативному соответствию, помогает компаниям следовать отраслевым стандартам и юридическим требованиям"
        },
        [chatModes.CHAT_BUSINESS_VALUATION]: {
            "name": "AI Эксперт по оценке бизнеса",
            "description": "Эксперт в оценке бизнеса, предоставляет информацию о стоимости компаний и консультирует по слияниям, поглощениям и инвестициям"
        },
        [chatModes.CHAT_BUSINESS_COACH]: {
            "name": "AI Бизнес-коуч",
            "description": "Специалист по бизнес-коучингу, предоставляет персонализированные рекомендации для бизнес-лидеров с целью их роста и достижения целей"
        },
        [chatModes.CHAT_BUSINESS_PSYCHOLOGIST]: {
            "name": "AI Бизнес-психолог",
            "description": "Эксперт по бизнес-психологии, фокусируется на улучшении рабочих взаимоотношений, развитии лидерских качеств и благополучии сотрудников"
        },
        [chatModes.CHAT_LEGAL_GENERAL]: {
            "name": "AI Юрист общего профиля",
            "description": "Юрист общего профиля, предоставляющий консультации по широкому кругу правовых вопросов, включая договоры, недвижимость и многое другое"
        },
        [chatModes.CHAT_LEGAL_CONTRACTS]: {
            "name": "AI Консультант по договорам",
            "description": "Специалист по договорному праву, предоставляет консультации по составлению, проверке и исполнению юридических соглашений"
        },
        [chatModes.CHAT_LEGAL_NOTARY]: {
            "name": "AI Нотариус",
            "description": "Удостоверенный нотариус, оказывающий услуги по заверению документов, приему присяг и свидетельствованию подписей"
        },
        [chatModes.CHAT_LEGAL_CORPORATE]: {
            "name": "AI Корпоративный юрист",
            "description": "Эксперт по корпоративному праву, помогает компаниям с юридической структурой, управлением и соблюдением законодательства"
        },
        [chatModes.CHAT_LEGAL_TAX]: {
            "name": "AI Налоговый юрист",
            "description": "Специалист по налоговому праву, помогает физическим и юридическим лицам разбираться в налоговых нормах и оптимизировать налоговое планирование"
        },
        [chatModes.CHAT_LEGAL_LABOR]: {
            "name": "AI Юрист по трудовому праву",
            "description": "Эксперт по трудовому праву, консультирует по вопросам трудовых договоров, трудовых споров и прав сотрудников"
        },
        [chatModes.CHAT_LEGAL_INTELLECTUAL_PROPERTY]: {
            "name": "AI Юрист по интеллектуальной собственности",
            "description": "Специалист по защите интеллектуальных прав, включая патенты, торговые марки и авторские права"
        },
        [chatModes.CHAT_LEGAL_LITIGATION]: {
            "name": "AI Юрист по судебным разбирательствам",
            "description": "Эксперт по судебным разбирательствам, оказывает юридическое представительство и консультации по вопросам судопроизводства"
        },
        [chatModes.CHAT_LEGAL_COURT]: {
            "name": "AI Судебный процесс (симуляция)",
            "description": "Симуляция судебного процесса для образовательных целей или подготовки, имитирующая реальное судебное разбирательство"
        },
        [chatModes.CHAT_LEGAL_MERGES_ACQUISITIONS]: {
            "name": "AI Юрист по слияниям и поглощениям",
            "description": "Специалист по корпоративным сделкам, оказывает консультации по вопросам слияний, поглощений и реструктуризаций"
        },
        [chatModes.CHAT_LEGAL_ADMINISTRATIVE]: {
            "name": "AI Юрист по административному праву",
            "description": "Эксперт по административному праву, предоставляет консультации по вопросам, связанным с государственным регулированием и действиями органов власти"
        },
        [chatModes.CHAT_LEGAL_ARBITRATION]: {
            "name": "AI Юрист по арбитражному праву",
            "description": "Специалист по арбитражным разбирательствам, помогает решать споры вне суда через обязательный или необязательный арбитраж"
        },
        [chatModes.CHAT_LEGAL_CRIMINAL]: {
            "name": "AI Юрист по уголовному праву",
            "description": "Эксперт по уголовному праву, защищает обвиняемых или выступает в качестве прокурора в уголовных делах"
        },
        [chatModes.CHAT_LEGAL_FAMILY]: {
            "name": "AI Юрист по семейному праву",
            "description": "Специалист по семейному праву, консультирует по вопросам развода, опеки над детьми и алиментов"
        },
        [chatModes.CHAT_LEGAL_REAL_ESTATE]: {
            "name": "AI Юрист по недвижимости",
            "description": "Эксперт по праву недвижимости, оказывает консультации по вопросам сделок с недвижимостью, аренды и споров"
        },
        [chatModes.CHAT_LEGAL_INTERNATIONAL]: {
            "name": "AI Юрист по международному праву",
            "description": "Специалист по международному праву, консультирует по вопросам трансграничных сделок, договоров и глобальных правовых вопросов"
        },
        [chatModes.CHAT_LEGAL_IMMIGRATION]: {
            "name": "AI Юрист по иммиграционному праву",
            "description": "Эксперт по иммиграционному праву, помогает с получением виз, вида на жительство и гражданства"
        },
        [chatModes.CHAT_LEGAL_CONSUMER_PROTECTION]: {
            "name": "AI Юрист по защите прав потребителей",
            "description": "Специалист по правам потребителей, предоставляет консультации по вопросам, связанным с мошенничеством, ответственностью за товары и недобросовестной деловой практикой"
        },
        [chatModes.CHAT_LEGAL_ENVIRONMENTAL]: {
            "name": "AI Юрист по экологическому праву",
            "description": "Эксперт по экологическому праву, консультирует по вопросам экологических нормативов и устойчивого развития"
        },
        [chatModes.CHAT_LEGAL_DATA_PRIVACY]: {
            "name": "AI Юрист по защите данных",
            "description": "Специалист по защите данных, помогает компаниям и физическим лицам соблюдать законы и правила в области конфиденциальности данных"
        },
        [chatModes.CHAT_LEGAL_HEALTHCARE]: {
            "name": "AI Юрист по медицинскому праву",
            "description": "Эксперт по медицинскому праву, предоставляет консультации медицинским учреждениям по вопросам нормативного соответствия и прав пациентов"
        },
        [chatModes.CHAT_LEGAL_BANKRUPTCY]: {
            "name": "AI Юрист по банкротству",
            "description": "Специалист по банкротству, помогает физическим и юридическим лицам разбираться в вопросах неплатежеспособности, реструктуризации и урегулирования долгов"
        },
        [chatModes.CHAT_LEGAL_COMPLIANCE]: {
            "name": "AI Юрист по вопросам соответствия требованиям",
            "description": "Эксперт по нормативному соответствию, помогает компаниям следовать отраслевым стандартам и юридическим требованиям"
        },
        [chatModes.CHAT_LEGAL_SPORTS]: {
            "name": "AI Спортивный юрист",
            "description": "Специалист по спортивному праву, консультирует спортсменов, команды и организации по контрактам, спонсорским соглашениям и спорам"
        },
        [chatModes.CHAT_LEGAL_ENTERTAINMENT]: {
            "name": "AI Юрист по медиа и развлечениям",
            "description": "Эксперт по медиа и развлекательному праву, оказывает юридические услуги в киноиндустрии, музыке и медиа"
        },
        [chatModes.CHAT_LEGAL_TECHNOLOGY]: {
            "name": "AI Юрист по технологиям",
            "description": "Специалист по правовым вопросам в области технологий, помогает компаниям решать проблемы, связанные с интеллектуальной собственностью, кибербезопасностью и нормативным соответствием"
        },
        [chatModes.CHAT_LEGAL_STARTUP]: {
            "name": "AI Юрист для стартапов",
            "description": "Эксперт по юридическим вопросам для стартапов, предоставляет консультации по созданию бизнеса, венчурному капиталу и защите интеллектуальной собственности"
        },
        [chatModes.CHAT_LEGAL_TRUSTS_ESTATES]: {
            "name": "AI Юрист по наследству и трастам",
            "description": "Специалист по планированию наследства, консультирует по вопросам завещаний, трастов и наследственного права"
        },
        [chatModes.CHAT_LEGAL_SECURITY]: {
            "name": "AI Юрист по безопасности",
            "description": "Эксперт по праву безопасности, предоставляет консультации по вопросам корпоративной, IT и личной безопасности, включая соответствие нормативным требованиям"
        },
        [chatModes.CHAT_LIFE_JOB]: {
            "name": "AI Эксперт по поиску работы",
            "description": "Предоставляет советы по поиску работы, составлению резюме и успешному прохождению собеседований"
        },
        [chatModes.CHAT_LIFE_CAREER_DEVELOPMENT]: {
            "name": "AI Консультант по карьерному развитию",
            "description": "Специализируется на помощи в продвижении по карьерной лестнице через развитие навыков и стратегические карьерные шаги"
        },
        [chatModes.CHAT_LIFE_SELF_DEVELOPMENT]: {
            "name": "AI Консультант по саморазвитию",
            "description": "Предлагает персонализированные советы для личностного роста, помогая достигать целей через методы самосовершенствования"
        },
        [chatModes.CHAT_LIFE_TRAVEL]: {
            "name": "AI Консультант по путешествиям",
            "description": "Оказывает помощь в планировании маршрутов, бронировании авиабилетов и поиске лучших вариантов размещения"
        },
        [chatModes.CHAT_LIFE_RELOCATION]: {
            "name": "AI Консультант по релокации",
            "description": "Помогает с процессом переезда, включая логистику, адаптацию на новом месте и юридические требования"
        },
        [chatModes.CHAT_LIFE_FINANCIAL]: {
            "name": "AI Консультант по личным финансам",
            "description": "Помогает управлять личными финансами, предоставляет рекомендации по составлению бюджета, сбережениям и планированию"
        },
        [chatModes.CHAT_LIFE_INSURANCE]: {
            "name": "AI Консультант по страхованию",
            "description": "Консультирует по различным видам страхования, помогая выбрать подходящие полисы в соответствии с вашими потребностями"
        },
        [chatModes.CHAT_LIFE_RETIREMENT]: {
            "name": "AI Консультант по планированию пенсии",
            "description": "Помогает спланировать пенсионные накопления для обеспечения финансовой стабильности на долгосрочную перспективу"
        },
        [chatModes.CHAT_LIFE_HEALTHY_LIFESTYLE]: {
            "name": "AI Консультант по здоровому образу жизни",
            "description": "Дает рекомендации по поддержанию здорового образа жизни через правильное питание, регулярные тренировки и управление стрессом"
        },
        [chatModes.CHAT_LIFE_NUTRITION]: {
            "name": "AI Консультант по питанию",
            "description": "Предоставляет персонализированные советы по питанию, помогает выбирать полезные продукты и составлять сбалансированные планы питания"
        },
        [chatModes.CHAT_LIFE_FITNESS]: {
            "name": "AI Консультант по фитнесу",
            "description": "Разрабатывает индивидуальные программы тренировок, помогает достигать целей в области здоровья и фитнеса"
        },
        [chatModes.CHAT_LIFE_YOGA]: {
            "name": "AI Инструктор по йоге",
            "description": "Предлагает персонализированные занятия йогой, помогая улучшить физическое здоровье и ментальную ясность"
        },
        [chatModes.CHAT_LIFE_MEMORY_IQ]: {
            "name": "AI Консультант по памяти и IQ",
            "description": "Специалист по улучшению памяти и когнитивных навыков, помогает повышать уровень IQ и ментальную продуктивность"
        },
        [chatModes.CHAT_LIFE_MENTAL_EQ]: {
            "name": "AI Консультант по ментальному здоровью и EQ",
            "description": "Дает советы по поддержанию психического здоровья и развитию эмоционального интеллекта для улучшения личных взаимоотношений"
        },
        [chatModes.CHAT_LIFE_REAL_ESTATE]: {
            "name": "AI Консультант по недвижимости",
            "description": "Эксперт по покупке, продаже и управлению недвижимостью, предлагает советы по сделкам с недвижимостью и рыночным трендам"
        },
        [chatModes.CHAT_LIFE_HOME_RENOVATION]: {
            "name": "AI Консультант по ремонту и улучшению жилья",
            "description": "Помогает планировать ремонтные работы, управлять бюджетом и взаимодействовать с подрядчиками для улучшения дома"
        },
        [chatModes.CHAT_LIFE_EDUCATION]: {
            "name": "AI Консультант по образовательным услугам",
            "description": "Консультирует по образовательным услугам, помогает выбрать школы, курсы и учебные ресурсы"
        },
        [chatModes.CHAT_LIFE_STUDY_ABROAD]: {
            "name": "AI Консультант по обучению за границей",
            "description": "Специалист по обучению за границей, помогает с подачей заявок, адаптацией к культуре и процессом обучения"
        },
        [chatModes.CHAT_LIFE_MARRIAGE]: {
            "name": "AI Консультант по семейным отношениям и браку",
            "description": "Предоставляет советы по вопросам брака и отношений, помогая парам укреплять отношения и разрешать конфликты"
        },
        [chatModes.CHAT_LIFE_PARENTING]: {
            "name": "AI Консультант по воспитанию детей",
            "description": "Дает рекомендации по эффективным стратегиям воспитания, помогая родителям справляться с вызовами воспитания"
        },
        [chatModes.CHAT_LIFE_SMALL_BUSINESS]: {
            "name": "AI Консультант по малому бизнесу",
            "description": "Консультирует владельцев малого бизнеса по вопросам управления, роста и решения бизнес-задач"
        },
        [chatModes.CHAT_LIFE_PERSONAL_SECURITY]: {
            "name": "AI Консультант по личной безопасности",
            "description": "Специалист по личной безопасности, предоставляет советы по самообороне, безопасности дома и защите личных данных"
        },
        [chatModes.CHAT_LIFE_HOBBIES]: {
            "name": "AI Консультант по хобби и личным интересам",
            "description": "Помогает развивать личные интересы, предлагая советы по различным хобби и увлечениям"
        },
        [chatModes.CHAT_LIFE_PET_CARE]: {
            "name": "AI Консультант по уходу за домашними животными",
            "description": "Предоставляет рекомендации по уходу за домашними животными, включая питание, уход и обучение"
        },
        [chatModes.CHAT_HOUSE_ELECTRICIAN]: {
            "name": "AI Электрик",
            "description": "Эксперт по электросистемам, предоставляет помощь с проводкой, освещением и ремонтом электрических устройств"
        },
        [chatModes.CHAT_HOUSE_PLUMBER]: {
            "name": "AI Сантехник",
            "description": "Специалист по сантехнике, помогает с водоснабжением, установкой труб и устранением утечек"
        },
        [chatModes.CHAT_HOUSE_CLEANER]: {
            "name": "AI Уборщик",
            "description": "Профессиональный уборщик, дающий советы по уборке дома, организации пространства и поддержанию чистоты"
        },
        [chatModes.CHAT_HOUSE_GARDENER]: {
            "name": "AI Садовник",
            "description": "Специалист по уходу за садом, предлагает советы по уходу за растениями, ландшафтному дизайну и поддержанию сада"
        },
        [chatModes.CHAT_HOUSE_SECURITY]: {
            "name": "AI Специалист по безопасности",
            "description": "Эксперт по безопасности дома, предоставляет советы по установке охранных систем и мерам защиты"
        },
        [chatModes.CHAT_HOUSE_APPLIANCE_REPAIR]: {
            "name": "AI Мастер по ремонту бытовой техники",
            "description": "Помогает с ремонтом и обслуживанием бытовой техники, включая холодильники, стиральные машины и плиты"
        },
        [chatModes.CHAT_HOUSE_CARPENTER]: {
            "name": "AI Плотник",
            "description": "Эксперт по деревообработке, предоставляет помощь в ремонте мебели, изготовлении деревянных конструкций и строительных работах"
        },
        [chatModes.CHAT_HOUSE_PLASTERER]: {
            "name": "AI Штукатур",
            "description": "Специалист по штукатурным работам, предоставляет советы по ремонту стен и потолков, а также отделочным работам"
        },
        [chatModes.CHAT_HOUSE_PAINTER]: {
            "name": "AI Маляр",
            "description": "Эксперт по покраске, помогает с выбором красок, цветовых схем и техниками профессиональной покраски"
        },
        [chatModes.CHAT_HOUSE_TILE_SETTER]: {
            "name": "AI Плиточник",
            "description": "Специалист по укладке плитки, предоставляет советы по укладке плитки на полы, стены и столешницы"
        },
        [chatModes.CHAT_HOUSE_CONSTRUCTOR]: {
            "name": "AI Проектировщик",
            "description": "Профессионал в области строительства, помогает с планированием проектов, выбором материалов и соблюдением строительных норм"
        },
        [chatModes.CHAT_HOUSE_INTERIOR_DESIGNER]: {
            "name": "AI Дизайнер интерьера",
            "description": "Эксперт по дизайну интерьеров, дает советы по созданию функциональных и эстетически привлекательных жилых помещений"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "AI Консультант по Фэншуй",
            "description": "Специалист по Фэншуй, предлагает советы по гармонизации вашего жилого и рабочего пространства для улучшения потока энергии"
        },
        [chatModes.CHAT_HOUSE_LANDSCAPE_ARCHITECT]: {
            "name": "AI Ландшафтный архитектор",
            "description": "Специалист по ландшафтной архитектуре, предлагает советы по дизайну сада, выбору растений и планировке пространства"
        },
        [chatModes.CHAT_HOUSE_CONTRACTOR]: {
            "name": "AI Подрядчик",
            "description": "Эксперт по управлению проектами, помогает с организацией строительных работ, выбором подрядчиков и контролем качества"
        },
        [chatModes.CHAT_HOUSE_MOVING_HELPER]: {
            "name": "AI Помощник по переезду",
            "description": "Оказывает помощь с логистикой переезда, давая советы по упаковке, транспортировке и организации вещей"
        },
        [chatModes.CHAT_HOUSE_FURNITURE_ASSEMBLER]: {
            "name": "AI Специалист по сборке мебели",
            "description": "Помогает с быстрой и эффективной сборкой мебели, предоставляя советы по сборке и установке"
        },
        [chatModes.CHAT_HOUSE_DOOR_LOCKS]: {
            "name": "AI Специалист по дверям и замкам",
            "description": "Эксперт по установке дверей и замков, предоставляет советы по обеспечению безопасности и их обслуживанию"
        },
        [chatModes.CHAT_HOUSE_SMART_HOME_SPECIALIST]: {
            "name": "AI Специалист по умным домам",
            "description": "Предоставляет помощь по установке и настройке систем умного дома, включая автоматизацию, освещение и голосовые команды"
        },
        [chatModes.CHAT_HOUSE_HOME_THEATER_EXPERT]: {
            "name": "AI Специалист по домашним кинотеатрам",
            "description": "Специалист по системам домашнего кинотеатра, предлагает советы по выбору оборудования, настройке и оптимизации звука"
        },
        [chatModes.CHAT_HOUSE_SOLAR_PANEL_SPECIALIST]: {
            "name": "AI Специалист по солнечным панелям",
            "description": "Предоставляет советы по установке, обслуживанию и повышению эффективности солнечных панелей"
        },
        [chatModes.CHAT_HOUSE_ROOFER]: {
            "name": "AI Кровельщик",
            "description": "Специалист по кровельным работам, предлагает советы по ремонту, установке и выбору материалов для крыши"
        },
        [chatModes.CHAT_HOUSE_WINDOW_INSTALLER]: {
            "name": "AI Установщик окон",
            "description": "Эксперт по установке окон, предоставляет советы по энергоэффективным окнам и правильной установке"
        },
        [chatModes.CHAT_HOUSE_FLOORING_EXPERT]: {
            "name": "AI Специалист по напольным покрытиям",
            "description": "Специалист по укладке полов, предлагает советы по выбору материалов и техникам укладки напольных покрытий"
        },
        [chatModes.CHAT_HOUSE_HVAC]: {
            "name": "AI Специалист по вентиляции и кондиционированию",
            "description": "Эксперт по системам отопления, вентиляции и кондиционирования, помогает с обслуживанием и улучшением энергоэффективности"
        },
        [chatModes.CHAT_HOUSE_INSULATION_EXPERT]: {
            "name": "AI Специалист по утеплению",
            "description": "Предоставляет советы по улучшению утепления дома для повышения энергоэффективности и комфорта"
        },
        [chatModes.CHAT_HOUSE_POOL_SPECIALIST]: {
            "name": "AI Специалист по бассейнам",
            "description": "Специалист по обслуживанию бассейнов, предлагает советы по чистке, ремонту и уходу за водой"
        },
        [chatModes.CHAT_HOUSE_SEPTIC_SYSTEM_EXPERT]: {
            "name": "AI Эксперт по септическим системам",
            "description": "Эксперт по септическим системам, помогает с установкой, обслуживанием и ремонтом систем"
        },
        [chatModes.CHAT_HOUSE_MOLD_REMOVAL_SPECIALIST]: {
            "name": "AI Специалист по борьбе с плесенью",
            "description": "Предоставляет советы по обнаружению, безопасному удалению плесени и предотвращению ее появления"
        },
        [chatModes.CHAT_HOUSE_PEST_CONTROL_SPECIALIST]: {
            "name": "AI Специалист по борьбе с вредителями",
            "description": "Эксперт по борьбе с вредителями, предоставляет советы по устранению насекомых и защите дома от вредителей в будущем"
        },
        [chatModes.CHAT_CAR_MECHANIC]: {
            "name": "AI Автомеханик",
            "description": "Эксперт по общему ремонту и обслуживанию автомобилей, помогает с регулярными услугами и механическими проблемами"
        },
        [chatModes.CHAT_CAR_ELECTRICIAN]: {
            "name": "AI Автоэлектрик",
            "description": "Специалист по автомобильным электрическим системам, предоставляет помощь с проводкой, освещением и электронными компонентами"
        },
        [chatModes.CHAT_CAR_SECURITY]: {
            "name": "AI Специалист по безопасности автомобилей",
            "description": "Эксперт по системам безопасности автомобилей, предоставляет советы по установке сигнализаций, иммобилайзеров и противоугонных средств"
        },
        [chatModes.CHAT_CAR_ENGINE_SPECIALIST]: {
            "name": "AI Специалист по двигателям",
            "description": "Специалист по диагностике и ремонту двигателей, помогает улучшить их производительность"
        },
        [chatModes.CHAT_CAR_TRANSMISSION_SPECIALIST]: {
            "name": "AI Специалист по трансмиссии",
            "description": "Эксперт по трансмиссионным системам, помогает с ремонтом и обслуживанием как механических, так и автоматических трансмиссий"
        },
        [chatModes.CHAT_CAR_BRAKE_SPECIALIST]: {
            "name": "AI Специалист по тормозам",
            "description": "Специалист по тормозным системам, предоставляет помощь с ремонтом, заменой и обслуживанием тормозов"
        },
        [chatModes.CHAT_CAR_TIRES_SPECIALIST]: {
            "name": "AI Специалист по шинам",
            "description": "Эксперт по обслуживанию шин, предоставляет советы по выбору, установке, вращению и балансировке шин"
        },
        [chatModes.CHAT_CAR_SUSPENSION_SPECIALIST]: {
            "name": "AI Специалист по подвеске",
            "description": "Специалист по системам подвески, помогает с ремонтом и улучшением для улучшения управляемости автомобиля"
        },
        [chatModes.CHAT_CAR_AIR_CONDITIONING_SPECIALIST]: {
            "name": "AI Специалист по автомобильным кондиционерам",
            "description": "Эксперт по автомобильным кондиционерам, предлагает услуги по ремонту, обслуживанию и заправке хладагента"
        },
        [chatModes.CHAT_CAR_BODYWORK_SPECIALIST]: {
            "name": "AI Специалист по кузовным работам",
            "description": "Специалист по ремонту кузова, предлагает помощь в устранении вмятин, царапин и повреждений структуры"
        },
        [chatModes.CHAT_CAR_PAINT_SPECIALIST]: {
            "name": "AI Специалист по покраске автомобилей",
            "description": "Эксперт по автомобильной покраске, предоставляет рекомендации по подбору цветов и профессиональной покраске"
        },
        [chatModes.CHAT_CAR_WINDSHIELD_REPAIR_SPECIALIST]: {
            "name": "AI Специалист по ремонту стекол",
            "description": "Специалист по ремонту и замене лобовых стекол, помогает устранить трещины и сколы"
        },
        [chatModes.CHAT_CAR_AUDIO_SPECIALIST]: {
            "name": "AI Специалист по аудиосистемам",
            "description": "Эксперт по автомобильным аудиосистемам, предоставляет советы по установке, модернизации и оптимизации звука"
        },
        [chatModes.CHAT_CAR_DIAGNOSTICS_SPECIALIST]: {
            "name": "AI Специалист по диагностике автомобилей",
            "description": "Специалист по диагностике, помогает выявить и устранить механические или электронные проблемы"
        },
        [chatModes.CHAT_CAR_DETAILER]: {
            "name": "AI Специалист по детейлингу",
            "description": "Профессиональный детейлер, дающий советы по уходу за интерьером и экстерьером автомобиля для улучшения его вида и защиты"
        },
        [chatModes.CHAT_CAR_TUNING_SPECIALIST]: {
            "name": "AI Специалист по тюнингу",
            "description": "Эксперт по тюнингу автомобилей, предоставляет рекомендации по улучшению производительности и индивидуальным модификациям"
        },
        [chatModes.CHAT_CAR_EXHAUST_SYSTEM_SPECIALIST]: {
            "name": "AI Специалист по выхлопной системе",
            "description": "Специалист по выхлопным системам, предлагает услуги по ремонту, замене и улучшению выхлопной системы"
        },
        [chatModes.CHAT_CAR_CLUTCH_SPECIALIST]: {
            "name": "AI Специалист по сцеплению",
            "description": "Эксперт по сцеплению, предоставляет помощь с ремонтом, регулировкой и заменой"
        },
        [chatModes.CHAT_CAR_FUEL_SYSTEM_SPECIALIST]: {
            "name": "AI Специалист по топливной системе",
            "description": "Специалист по топливным системам, предоставляет помощь с ремонтом топливного насоса, инжекторов и улучшением эффективности"
        },
        [chatModes.CHAT_CAR_GLASS_TINTING_SPECIALIST]: {
            "name": "AI Специалист по тонировке стекол",
            "description": "Эксперт по тонировке автомобильных стекол, предоставляет советы по установке, типам пленок и местным правилам"
        },
        [chatModes.CHAT_CAR_RADIATOR_SPECIALIST]: {
            "name": "AI Специалист по радиаторам",
            "description": "Специалист по системам охлаждения, предлагает услуги по ремонту и обслуживанию радиаторов для предотвращения перегрева"
        },
        [chatModes.CHAT_CAR_BATTERY_SPECIALIST]: {
            "name": "AI Специалист по аккумуляторам",
            "description": "Эксперт по автомобильным аккумуляторам, предоставляет рекомендации по выбору, замене и обслуживанию аккумуляторов"
        },
        [chatModes.CHAT_CAR_ALIGNMENT_SPECIALIST]: {
            "name": "AI Специалист по развалу-схождению",
            "description": "Специалист по регулировке колес, предоставляет услуги по устранению несоосности и улучшению безопасности движения"
        },
        [chatModes.CHAT_CAR_INTERIOR_REPAIR_SPECIALIST]: {
            "name": "AI Специалист по ремонту салона",
            "description": "Эксперт по ремонту интерьера автомобиля, предоставляет советы по восстановлению сидений, обивки и приборных панелей"
        },
        [chatModes.CHAT_CAR_LOCKSMITH]: {
            "name": "AI Автослесарь по замкам",
            "description": "Специалист по автомобильным замкам, предоставляет помощь с ремонтом замков, заменой и программированием ключей"
        },
        [chatModes.CHAT_CAR_HYBRID_SPECIALIST]: {
            "name": "AI Специалист по гибридным автомобилям",
            "description": "Эксперт по системам гибридных автомобилей, предоставляет помощь по обслуживанию и ремонту гибридных транспортных средств"
        },
        [chatModes.CHAT_CAR_LAWYER]: {
            "name": "AI Автоюрист",
            "description": "Эксперт по автомобильному праву, предоставляет юридические консультации по нарушениям ПДД, авариям и страховым претензиям"
        },
        [chatModes.CHAT_CAR_SALES_SPECIALIST]: {
            "name": "AI Специалист по продаже автомобилей",
            "description": "Специалист по покупке и продаже автомобилей, предлагает советы по переговорам, осмотру и оценке цен"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_MENTOR]: {
            "name": "AI Духовный наставник",
            "description": "Предоставляет духовное наставничество и руководство, помогая людям в их духовных поисках"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_CARE]: {
            "name": "AI Консультант по духовной поддержке",
            "description": "Оказывает эмоциональную и духовную поддержку в трудные моменты жизни, способствуя внутреннему покою и силе"
        },
        [chatModes.CHAT_RELIGION_RELIGIOUS_COUNSELOR]: {
            "name": "AI Консультант по религиозной поддержке",
            "description": "Помогает людям разбираться в религиозных вопросах и предоставляет поддержку на основе конкретных вероисповеданий"
        },
        [chatModes.CHAT_RELIGION_INTERFAITH_CONSULTANT]: {
            "name": "AI Консультант по межконфессиональным вопросам",
            "description": "Способствует взаимопониманию и сотрудничеству между различными религиями, продвигая гармонию и уважение"
        },
        [chatModes.CHAT_RELIGION_PRIEST]: {
            "name": "AI Священник",
            "description": "Христианский религиозный лидер, предоставляющий духовное руководство, таинства и религиозные обряды"
        },
        [chatModes.CHAT_RELIGION_CHAPLAIN]: {
            "name": "AI Капеллан",
            "description": "Предоставляет духовную заботу и консультирование в различных учреждениях, таких как больницы или армия, часто вне конфессий"
        },
        [chatModes.CHAT_RELIGION_IMAM]: {
            "name": "AI Имам",
            "description": "Исламский религиозный лидер, предоставляющий духовное руководство и ведущий молитвы в мусульманском сообществе"
        },
        [chatModes.CHAT_RELIGION_RABBI]: {
            "name": "AI Раввин",
            "description": "Еврейский духовный лидер, оказывающий руководство по религиозным практикам, этике и лидерству в общине"
        },
        [chatModes.CHAT_RELIGION_BUDDHIST_MONK]: {
            "name": "AI Буддийский монах",
            "description": "Практикующий буддизм, предлагающий учения по медитации, осознанности и духовному развитию"
        },
        [chatModes.CHAT_RELIGION_YOGA_TEACHER]: {
            "name": "AI Учитель йоги",
            "description": "Предоставляет руководство по практике йоги для улучшения физического и духовного благополучия, основанного на традициях индуизма"
        },
        [chatModes.CHAT_RELIGION_TANTRA_TEACHER]: {
            "name": "AI Учитель тантры",
            "description": "Предлагает духовные учения по тантре, традиции в индуизме и буддизме, сосредоточенные на осознанности и сакральных практиках"
        },
        [chatModes.CHAT_RELIGION_VEDIC_PRIEST]: {
            "name": "AI Ведический священник",
            "description": "Индуистский религиозный лидер, проводящий ведические ритуалы и оказывающий духовное руководство на основе древних индуистских писаний"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_HEALER]: {
            "name": "AI Духовный целитель",
            "description": "Помогает людям исцеляться духовно с помощью различных практик, таких как энергетическая работа, молитвы или медитации"
        },
        [chatModes.CHAT_RELIGION_MEDITATION_GUIDE]: {
            "name": "AI Проводник медитации",
            "description": "Предлагает инструкции по техникам медитации для расслабления, осознанности и духовного осознания"
        },
        [chatModes.CHAT_RELIGION_SCHOLAR_OF_RELIGIOUS_TEXTS]: {
            "name": "AI Специалист по религиозным текстам",
            "description": "Эксперт по священным писаниям, предоставляющий глубокие знания религиозных текстов различных вероисповеданий"
        },
        [chatModes.CHAT_RELIGION_THEOLOGIST]: {
            "name": "AI Теолог",
            "description": "Исследует и дает представления о теологических концепциях, помогая углубить понимание религиозных убеждений"
        },
        [chatModes.CHAT_RELIGION_ATHEIST_COUNSELOR]: {
            "name": "AI Консультант по вопросам атеизма",
            "description": "Предлагает помощь в решении жизненных вопросов и философских дилемм с атеистической или светской точки зрения"
        },
        [chatModes.CHAT_RELIGION_PHILOSOPHER]: {
            "name": "AI Философ",
            "description": "Предоставляет философские размышления по вопросам существования, этики и природы реальности"
        },
        [chatModes.CHAT_RELIGION_ETHICS_SPECIALIST]: {
            "name": "AI Специалист по этике",
            "description": "Помогает людям ориентироваться в сложных моральных и этических вопросах, предлагая руководство, основанное на философских или религиозных принципах"
        },
        [chatModes.CHAT_ESOTERIC_NUMEROLOGY]: {
            "name": "AI Нумеролог",
            "description": "Специалист по нумерологии, предлагает анализ и прогнозы на основе мистической силы чисел и их влияния на жизнь"
        },
        [chatModes.CHAT_ESOTERIC_BIORHYTHMS]: {
            "name": "AI Специалист по биоритмам",
            "description": "Эксперт по биоритмам, помогает разобраться в циклах физического, эмоционального и интеллектуального состояния"
        },
        [chatModes.CHAT_ESOTERIC_PALMISTRY]: {
            "name": "AI Хиромант",
            "description": "Специалист по гаданию по руке, предлагает предсказания и анализ личности на основе линий и формы рук"
        },
        [chatModes.CHAT_ESOTERIC_AURA_READING]: {
            "name": "AI Специалист по чтению ауры",
            "description": "Эксперт по чтению ауры, интерпретирует энергетическое поле вокруг человека для понимания его здоровья и эмоционального состояния"
        },
        [chatModes.CHAT_ESOTERIC_CRYSTALS]: {
            "name": "AI Специалист по кристаллам",
            "description": "Предлагает советы по использованию кристаллов для исцеления, защиты и духовного роста"
        },
        [chatModes.CHAT_ESOTERIC_RUNES]: {
            "name": "AI Специалист по рунам",
            "description": "Эксперт по гаданию на рунах, предлагает предсказания и анализ на основе древних символов и их значений"
        },
        [chatModes.CHAT_ESOTERIC_I_CHING]: {
            "name": "AI Специалист по Книге Перемен",
            "description": "Предлагает гадание и советы на основе древнекитайского текста И-Цзин (Книга Перемен)"
        },
        [chatModes.CHAT_ESOTERIC_PSYCHIC]: {
            "name": "AI Экстрасенс",
            "description": "Предоставляет интуитивные прогнозы и советы по различным аспектам жизни через экстрасенсорное восприятие"
        },
        [chatModes.CHAT_ESOTERIC_AKASHIC_RECORDS]: {
            "name": "AI Специалист по чтению Акаши",
            "description": "Специалист по чтению Акашических записей, предлагает духовные инсайты о прошлом, настоящем и будущем"
        },
        [chatModes.CHAT_ESOTERIC_MEDIUM]: {
            "name": "AI Медиум",
            "description": "Помогает установить контакт с духами и передает сообщения от ушедших близких или духовных наставников"
        },
        [chatModes.CHAT_ESOTERIC_SHAMAN]: {
            "name": "AI Шаман",
            "description": "Предлагает духовное исцеление и руководство через шаманские практики, включая ритуалы и связь с природой"
        },
        [chatModes.CHAT_ESOTERIC_PENDULUM_DOWSING]: {
            "name": "AI Специалист по гаданию на маятнике",
            "description": "Эксперт по гаданию на маятнике, предлагает предсказания и ответы на вопросы с использованием маятника как духовного инструмента"
        },
        [chatModes.CHAT_ESOTERIC_HERBALISM]: {
            "name": "AI Специалист по травам",
            "description": "Предоставляет советы по использованию трав для исцеления, улучшения здоровья и духовных практик"
        },
        [chatModes.CHAT_TAROT_TRADITIONAL]: {
            "name": "Традиционный Таролог",
            "description": "Ориентируется на классические значения карт и символику Таро"
        },
        [chatModes.CHAT_TAROT_LENORMAND]: {
            "name": "Таролог Ленорман",
            "description": "Использует карты Ленорман для гаданий, предлагая уникальные толкования"
        },
        [chatModes.CHAT_TAROT_ORACLE]: {
            "name": "Оракул Таро",
            "description": "Интерпретирует колоды оракула, предлагая гибкие и разнообразные толкования"
        },
        [chatModes.CHAT_TAROT_CELTIC]: {
            "name": "Кельтский или Паганский Таролог",
            "description": "Связывает свои гадания с кельтскими, языческими или викканскими духовными практиками"
        },
        [chatModes.CHAT_TAROT_NUMERICAL]: {
            "name": "Нумерологический Таролог",
            "description": "Ориентируется на нумерологию в толковании карт Таро"
        },
        [chatModes.CHAT_TAROT_ASTRO]: {
            "name": "Астрологический Таролог",
            "description": "Совмещает астрологию с Таро для более глубокого понимания гаданий"
        },
        [chatModes.CHAT_TAROT_THERAPY]: {
            "name": "Терапевтический Таролог",
            "description": "Использует Таро для личностного развития и работы над собой, часто имея опыт в психологическом консультировании"
        },
        [chatModes.CHAT_TAROT_SPIRIT]: {
            "name": "Духовный Таролог",
            "description": "Включает в свои гадания духовные практики, такие как медитация или молитва"
        },
        [chatModes.CHAT_TAROT_INTUITIVE]: {
            "name": "Интуитивный Таролог",
            "description": "Основывается на интуиции и личных интерпретациях карт, а не на строгих значениях"
        },
        [chatModes.CHAT_TAROT_PSYCHIC]: {
            "name": "Экстрасенс-Таролог",
            "description": "Совмещает гадание на картах Таро с экстрасенсорными способностями для более глубоких предсказаний"
        },
        [chatModes.CHAT_HOROSCOPE]: {
            "name": "AI Гороскоп общего профиля",
            "description": "Предоставляет общий анализ астрологических влияний на основе данных о рождении, предлагая советы по различным жизненным аспектам"
        },
        [chatModes.CHAT_HOROSCOPE_REGULAR]: {
            "name": "AI Традиционный гороскоп",
            "description": "Следует традиционным астрологическим методам для предоставления ежедневных, еженедельных или ежемесячных прогнозов на основе знаков зодиака"
        },
        [chatModes.CHAT_HOROSCOPE_EASTERN]: {
            "name": "AI Восточный гороскоп",
            "description": "Использует восточную астрологию, такую как китайский зодиак, для предоставления персонализированных прогнозов и рекомендаций"
        },
        [chatModes.CHAT_HOROSCOPE_BUSINESS]: {
            "name": "AI Бизнес-гороскоп",
            "description": "Сфокусирован на карьерных и бизнес-аспектах, предоставляя астрологические советы для принятия финансовых и профессиональных решений"
        },
        [chatModes.CHAT_HOROSCOPE_COMPATIBILITY]: {
            "name": "AI Гороскоп совместимости",
            "description": "Анализирует совместимость между партнёрами, друзьями или коллегами на основе астрологических карт"
        },
        [chatModes.CHAT_HOROSCOPE_LOVE]: {
            "name": "AI Гороскоп любви",
            "description": "Предоставляет астрологические рекомендации относительно романтических отношений и личной жизни"
        },
        [chatModes.CHAT_HOROSCOPE_SEX]: {
            "name": "AI Сексуальный гороскоп",
            "description": "Исследует сексуальную совместимость и предпочтения через астрологический анализ натальных карт"
        },
        [chatModes.CHAT_HOROSCOPE_HEALTH]: {
            "name": "AI Гороскоп здоровья",
            "description": "Предлагает советы, связанные со здоровьем, на основе астрологических знаков и положений планет"
        },
        [chatModes.CHAT_HOROSCOPE_LUNAR]: {
            "name": "AI Лунный гороскоп",
            "description": "Фокусируется на влиянии Луны и лунных циклов на повседневную жизнь, эмоции и принятие решений"
        },
        [chatModes.CHAT_HOROSCOPE_SOLAR]: {
            "name": "AI Солнечный гороскоп",
            "description": "Ориентирован на положение Солнца и его влияние на личную идентичность, цели и уровень энергии"
        },
        [chatModes.CHAT_HOROSCOPE_CELESTIAL]: {
            "name": "AI Небесный гороскоп",
            "description": "Анализирует выравнивание планет и движение небесных тел для предоставления глубоких астрологических прогнозов"
        },
        [chatModes.CHAT_HOROSCOPE_PSYCHOLOGICAL]: {
            "name": "AI Психологический гороскоп",
            "description": "Комбинирует астрологию с психологическими аспектами для более глубокого понимания личности и психических паттернов"
        },
        [chatModes.CHAT_HOROSCOPE_DRACONIC]: {
            "name": "AI Драконический гороскоп",
            "description": "Фокусируется на кармических и духовных аспектах жизни, интерпретируя драконическую карту, связанную с предназначением души"
        },
        [chatModes.CHAT_HOROSCOPE_HOURLY]: {
            "name": "AI Часовой гороскоп",
            "description": "Предоставляет частые астрологические обновления, отслеживая положение планет по часам для краткосрочных прогнозов"
        },
        [chatModes.CHAT_HOROSCOPE_WEEKLY]: {
            "name": "AI Еженедельный гороскоп",
            "description": "Предлагает еженедельный астрологический прогноз, помогая планировать предстоящие вызовы и возможности"
        },
        [chatModes.CHAT_HOROSCOPE_YEARLY]: {
            "name": "AI Ежегодный гороскоп",
            "description": "Предоставляет астрологический обзор на год вперёд, освещая важные тренды и ключевые моменты"
        },
        [chatModes.CHAT_ASTROLOGY_NATAL]: {
            "name": "AI Астролог по натальной карте",
            "description": "Специализируется на создании и интерпретации натальных карт, предоставляя информацию о личности, сильных сторонах и жизненном пути на основе данных о рождении"
        },
        [chatModes.CHAT_ASTROLOGY_HORO]: {
            "name": "AI Астрологический гороскоп",
            "description": "Предлагает ежедневные, еженедельные или ежемесячные гороскопы на основе астрологических позиций, давая рекомендации по краткосрочным решениям и жизненным событиям"
        },
        [chatModes.CHAT_ASTROLOGY_SYNASTRY]: {
            "name": "AI Астролог по синастрии",
            "description": "Анализирует совместимость партнеров, сравнивая их астрологические карты и предоставляя информацию об отношениях"
        },
        [chatModes.CHAT_ASTROLOGY_SOLAR_RETURN]: {
            "name": "AI Астролог по солнечным возвращениям",
            "description": "Специализируется на солнечных возвращениях, предоставляя годовые прогнозы на основе возвращения Солнца к его натальной позиции в день рождения"
        },
        [chatModes.CHAT_ASTROLOGY_LUNAR_CYCLE]: {
            "name": "AI Астролог по лунным циклам",
            "description": "Фокусируется на лунных циклах и их влиянии на эмоции, решения и жизненные фазы, предоставляя советы в соответствии с фазами Луны"
        },
        [chatModes.CHAT_ASTROLOGY_TRANSITS]: {
            "name": "AI Астролог по транзитам планет",
            "description": "Анализирует текущие позиции планет и их влияние на натальные планеты, помогая прогнозировать тренды и события в жизни"
        },
        [chatModes.CHAT_ASTROLOGY_PROGRESSIONS]: {
            "name": "AI Астролог по прогрессиям",
            "description": "Специалист по астрологическим прогрессиям, предоставляющий информацию о внутреннем росте и долгосрочной личной эволюции"
        },
        [chatModes.CHAT_ASTROLOGY_KARMIC_ASTROLOGY]: {
            "name": "AI Астролог по кармической астрологии",
            "description": "Интерпретирует влияние прошлых жизней и кармические уроки через астрологию, помогая понять духовное путешествие"
        },
        [chatModes.CHAT_ASTROLOGY_MEDICAL_ASTROLOGY]: {
            "name": "AI Астролог по медицинской астрологии",
            "description": "Фокусируется на вопросах здоровья, используя астрологию для предоставления рекомендаций по физическому состоянию и потенциальным проблемам со здоровьем"
        },
        [chatModes.CHAT_ASTROLOGY_VEDIC]: {
            "name": "AI Ведический астролог",
            "description": "Специалист по ведической астрологии, предоставляющий информацию на основе древней индийской астрологической системы, дающий глубокие духовные и практические советы"
        },
        [chatModes.CHAT_TALISMAN_CALPACA]: {
            "name": "Талисман Кальпака",
            "description": "Талисман в виде подковы Calpaca для удачи"
        },
        [chatModes.CHAT_TALISMAN_ROSA]: {
            "name": "Роза ветров",
            "description": "Талисман Роза ветров, символизирующий путь и руководство"
        },
        [chatModes.CHAT_TALISMAN_MANDALA]: {
            "name": "Мандала Ориша",
            "description": "Фигурка, представляющая Мандалу Ориша для духовной защиты"
        },
        [chatModes.CHAT_TALISMAN_BACA]: {
            "name": "Бака",
            "description": "Фигурка Бака, талисман, приносящий мистическую силу"
        },
        [chatModes.CHAT_TALISMAN_KUITA]: {
            "name": "Кукла Мунекас Куита Пеньяс",
            "description": "Кукла Мунекас Куита Пеньяс, приносящая защиту"
        },
        [chatModes.CHAT_TALISMAN_TRINKETS]: {
            "name": "Талисман Амулет",
            "description": "Маленький талисман для защиты или привлечения удачи"
        },
        [chatModes.CHAT_UNKNOWN]: {
            "name": "AI Консультант",
            "description": ""
        },
    },
    "consultationDetails": {
        "title": "Детали консультации",
        "deleteConfirmation": "Вы действительно хотите удалить эту консультацию?",
        "deleteConsultation": "Удалить",
        "resumeConsultation": "Продолжить",
        "restoreConsultation": "Восстановить"
    },
    "documentDetails": {
        "title": "Детали документа",
        "manage": "Управление",
        "download": "Скачать"
    },
    "documentManage": {
        "title": "Управление документом",
        "share": "Поделиться",
        "delete": "Удалить",
        "restore": "Восстановить",
        "rename": "Переименовать",
        "documentDeleted": "Документ удален {{date}}",
        "changeProfile": "Изменить связанного человека",
        "download": "Скачать",
        "deleteConfirmation": "Вы действительно хотите удалить этот документ?",
        "restoreConfirmation": "Вы действительно хотите восстановить этот документ?"
    },
    "deleteConfirmation": {
        "title": "Вы действительно хотите удалить?",
        "cancel": "Отмена",
        "delete": "Удалить"
    },
    "restoreConfirmation": {
        "title": "Вы действительно хотите восстановить?",
        "cancel": "Отмена",
        "restore": "Восстановить"
    },
    "countries": {
        [countries.COUNTRY_AF]: "Афганистан",
        [countries.COUNTRY_AL]: "Албания",
        [countries.COUNTRY_DZ]: "Алжир",
        [countries.COUNTRY_AD]: "Андорра",
        [countries.COUNTRY_AO]: "Ангола",
        [countries.COUNTRY_AG]: "Антигуа и Барбуда",
        [countries.COUNTRY_AR]: "Аргентина",
        [countries.COUNTRY_AM]: "Армения",
        [countries.COUNTRY_AU]: "Австралия",
        [countries.COUNTRY_AT]: "Австрия",
        [countries.COUNTRY_AZ]: "Азербайджан",
        [countries.COUNTRY_BS]: "Багамы",
        [countries.COUNTRY_BH]: "Бахрейн",
        [countries.COUNTRY_BD]: "Бангладеш",
        [countries.COUNTRY_BB]: "Барбадос",
        [countries.COUNTRY_BY]: "Беларусь",
        [countries.COUNTRY_BE]: "Бельгия",
        [countries.COUNTRY_BZ]: "Белиз",
        [countries.COUNTRY_BJ]: "Бенин",
        [countries.COUNTRY_BT]: "Бутан",
        [countries.COUNTRY_BO]: "Боливия",
        [countries.COUNTRY_BA]: "Босния и Герцеговина",
        [countries.COUNTRY_BW]: "Ботсвана",
        [countries.COUNTRY_BR]: "Бразилия",
        [countries.COUNTRY_BN]: "Бруней",
        [countries.COUNTRY_BG]: "Болгария",
        [countries.COUNTRY_BF]: "Буркина-Фасо",
        [countries.COUNTRY_BI]: "Бурунди",
        [countries.COUNTRY_KH]: "Камбоджа",
        [countries.COUNTRY_CM]: "Камерун",
        [countries.COUNTRY_CA]: "Канада",
        [countries.COUNTRY_CV]: "Кабо-Верде",
        [countries.COUNTRY_CF]: "Центральноафриканская Республика (ЦАР)",
        [countries.COUNTRY_TD]: "Чад",
        [countries.COUNTRY_CL]: "Чили",
        [countries.COUNTRY_CN]: "Китай",
        [countries.COUNTRY_CO]: "Колумбия",
        [countries.COUNTRY_KM]: "Коморы",
        [countries.COUNTRY_CG]: "Конго",
        [countries.COUNTRY_CD]: "Демократическая Республика Конго (ДРК)",
        [countries.COUNTRY_CR]: "Коста-Рика",
        [countries.COUNTRY_HR]: "Хорватия",
        [countries.COUNTRY_CU]: "Куба",
        [countries.COUNTRY_CY]: "Кипр",
        [countries.COUNTRY_CZ]: "Чехия",
        [countries.COUNTRY_DK]: "Дания",
        [countries.COUNTRY_DJ]: "Джибути",
        [countries.COUNTRY_DM]: "Доминика",
        [countries.COUNTRY_DO]: "Доминиканская Республика",
        [countries.COUNTRY_EC]: "Эквадор",
        [countries.COUNTRY_EG]: "Египет",
        [countries.COUNTRY_SV]: "Сальвадор",
        [countries.COUNTRY_GQ]: "Экваториальная Гвинея",
        [countries.COUNTRY_ER]: "Эритрея",
        [countries.COUNTRY_EE]: "Эстония",
        [countries.COUNTRY_SZ]: "Эсватини",
        [countries.COUNTRY_ET]: "Эфиопия",
        [countries.COUNTRY_FJ]: "Фиджи",
        [countries.COUNTRY_FI]: "Финляндия",
        [countries.COUNTRY_FR]: "Франция",
        [countries.COUNTRY_GA]: "Габон",
        [countries.COUNTRY_GM]: "Гамбия",
        [countries.COUNTRY_GE]: "Грузия",
        [countries.COUNTRY_DE]: "Германия",
        [countries.COUNTRY_GH]: "Гана",
        [countries.COUNTRY_GR]: "Греция",
        [countries.COUNTRY_GD]: "Гренада",
        [countries.COUNTRY_GT]: "Гватемала",
        [countries.COUNTRY_GN]: "Гвинея",
        [countries.COUNTRY_GW]: "Гвинея-Бисау",
        [countries.COUNTRY_GY]: "Гайана",
        [countries.COUNTRY_HT]: "Гаити",
        [countries.COUNTRY_HN]: "Гондурас",
        [countries.COUNTRY_HU]: "Венгрия",
        [countries.COUNTRY_IS]: "Исландия",
        [countries.COUNTRY_IN]: "Индия",
        [countries.COUNTRY_ID]: "Индонезия",
        [countries.COUNTRY_IR]: "Иран",
        [countries.COUNTRY_IQ]: "Ирак",
        [countries.COUNTRY_IE]: "Ирландия",
        [countries.COUNTRY_IL]: "Израиль",
        [countries.COUNTRY_IT]: "Италия",
        [countries.COUNTRY_JM]: "Ямайка",
        [countries.COUNTRY_JP]: "Япония",
        [countries.COUNTRY_JO]: "Иордания",
        [countries.COUNTRY_KZ]: "Казахстан",
        [countries.COUNTRY_KE]: "Кения",
        [countries.COUNTRY_KI]: "Кирибати",
        [countries.COUNTRY_KP]: "Северная Корея",
        [countries.COUNTRY_KR]: "Южная Корея",
        [countries.COUNTRY_KW]: "Кувейт",
        [countries.COUNTRY_KG]: "Киргизия",
        [countries.COUNTRY_LA]: "Лаос",
        [countries.COUNTRY_LV]: "Латвия",
        [countries.COUNTRY_LB]: "Ливан",
        [countries.COUNTRY_LS]: "Лесото",
        [countries.COUNTRY_LR]: "Либерия",
        [countries.COUNTRY_LY]: "Ливия",
        [countries.COUNTRY_LI]: "Лихтенштейн",
        [countries.COUNTRY_LT]: "Литва",
        [countries.COUNTRY_LU]: "Люксембург",
        [countries.COUNTRY_MG]: "Мадагаскар",
        [countries.COUNTRY_MW]: "Малави",
        [countries.COUNTRY_MY]: "Малайзия",
        [countries.COUNTRY_MV]: "Мальдивы",
        [countries.COUNTRY_ML]: "Мали",
        [countries.COUNTRY_MT]: "Мальта",
        [countries.COUNTRY_MH]: "Маршалловы Острова",
        [countries.COUNTRY_MR]: "Мавритания",
        [countries.COUNTRY_MU]: "Маврикий",
        [countries.COUNTRY_MX]: "Мексика",
        [countries.COUNTRY_FM]: "Микронезия",
        [countries.COUNTRY_MD]: "Молдова",
        [countries.COUNTRY_MC]: "Монако",
        [countries.COUNTRY_MN]: "Монголия",
        [countries.COUNTRY_ME]: "Черногория",
        [countries.COUNTRY_MA]: "Марокко",
        [countries.COUNTRY_MZ]: "Мозамбик",
        [countries.COUNTRY_MM]: "Мьянма",
        [countries.COUNTRY_NA]: "Намибия",
        [countries.COUNTRY_NR]: "Науру",
        [countries.COUNTRY_NP]: "Непал",
        [countries.COUNTRY_NL]: "Нидерланды",
        [countries.COUNTRY_NZ]: "Новая Зеландия",
        [countries.COUNTRY_NI]: "Никарагуа",
        [countries.COUNTRY_NE]: "Нигер",
        [countries.COUNTRY_NG]: "Нигерия",
        [countries.COUNTRY_NO]: "Норвегия",
        [countries.COUNTRY_OM]: "Оман",
        [countries.COUNTRY_PK]: "Пакистан",
        [countries.COUNTRY_PW]: "Палау",
        [countries.COUNTRY_PA]: "Панама",
        [countries.COUNTRY_PG]: "Папуа-Новая Гвинея",
        [countries.COUNTRY_PY]: "Парагвай",
        [countries.COUNTRY_PE]: "Перу",
        [countries.COUNTRY_PH]: "Филиппины",
        [countries.COUNTRY_PL]: "Польша",
        [countries.COUNTRY_PT]: "Португалия",
        [countries.COUNTRY_QA]: "Катар",
        [countries.COUNTRY_RO]: "Румыния",
        [countries.COUNTRY_RU]: "Россия",
        [countries.COUNTRY_RW]: "Руанда",
        [countries.COUNTRY_KN]: "Сент-Китс и Невис",
        [countries.COUNTRY_LC]: "Сент-Люсия",
        [countries.COUNTRY_VC]: "Сент-Винсент и Гренадины",
        [countries.COUNTRY_WS]: "Самоа",
        [countries.COUNTRY_SM]: "Сан-Марино",
        [countries.COUNTRY_ST]: "Сан-Томе и Принсипи",
        [countries.COUNTRY_SA]: "Саудовская Аравия",
        [countries.COUNTRY_SN]: "Сенегал",
        [countries.COUNTRY_RS]: "Сербия",
        [countries.COUNTRY_SC]: "Сейшельские Острова",
        [countries.COUNTRY_SL]: "Сьерра-Леоне",
        [countries.COUNTRY_SG]: "Сингапур",
        [countries.COUNTRY_SK]: "Словакия",
        [countries.COUNTRY_SI]: "Словения",
        [countries.COUNTRY_SB]: "Соломоновы Острова",
        [countries.COUNTRY_SO]: "Сомали",
        [countries.COUNTRY_ZA]: "Южная Африка",
        [countries.COUNTRY_SS]: "Южный Судан",
        [countries.COUNTRY_ES]: "Испания",
        [countries.COUNTRY_LK]: "Шри-Ланка",
        [countries.COUNTRY_SD]: "Судан",
        [countries.COUNTRY_SR]: "Суринам",
        [countries.COUNTRY_SE]: "Швеция",
        [countries.COUNTRY_CH]: "Швейцария",
        [countries.COUNTRY_SY]: "Сирия",
        [countries.COUNTRY_TW]: "Тайвань",
        [countries.COUNTRY_TJ]: "Таджикистан",
        [countries.COUNTRY_TZ]: "Танзания",
        [countries.COUNTRY_TH]: "Таиланд",
        [countries.COUNTRY_TL]: "Тимор-Лесте",
        [countries.COUNTRY_TG]: "Того",
        [countries.COUNTRY_TO]: "Тонга",
        [countries.COUNTRY_TT]: "Тринидад и Тобаго",
        [countries.COUNTRY_TN]: "Тунис",
        [countries.COUNTRY_TR]: "Турция",
        [countries.COUNTRY_TM]: "Туркмения",
        [countries.COUNTRY_TV]: "Тувалу",
        [countries.COUNTRY_UG]: "Уганда",
        [countries.COUNTRY_UA]: "Украина",
        [countries.COUNTRY_AE]: "Объединённые Арабские Эмираты (ОАЭ)",
        [countries.COUNTRY_GB]: "Великобритания",
        [countries.COUNTRY_US]: "Соединённые Штаты Америки (США)",
        [countries.COUNTRY_UY]: "Уругвай",
        [countries.COUNTRY_UZ]: "Узбекистан",
        [countries.COUNTRY_VU]: "Вануату",
        [countries.COUNTRY_VE]: "Венесуэла",
        [countries.COUNTRY_VN]: "Вьетнам",
        [countries.COUNTRY_YE]: "Йемен",
        [countries.COUNTRY_ZM]: "Замбия",
        [countries.COUNTRY_ZW]: "Зимбабве"
    },
    "languages": {
        [languages.LANGUAGE_AF]: "Африкаанс",
        [languages.LANGUAGE_AM]: "Амхарский",
        [languages.LANGUAGE_AR]: "Арабский",
        [languages.LANGUAGE_AZ]: "Азербайджанский",
        [languages.LANGUAGE_BE]: "Белорусский",
        [languages.LANGUAGE_BG]: "Болгарский",
        [languages.LANGUAGE_BN]: "Бенгальский",
        [languages.LANGUAGE_CA]: "Каталанский",
        [languages.LANGUAGE_CS]: "Чешский",
        [languages.LANGUAGE_DA]: "Датский",
        [languages.LANGUAGE_DE]: "Немецкий",
        [languages.LANGUAGE_DZ]: "Дзонг-кэ",
        [languages.LANGUAGE_EL]: "Греческий",
        [languages.LANGUAGE_EN]: "Английский (США)",
        [languages.LANGUAGE_EN_GB]: "Английский (Великобритания)",
        [languages.LANGUAGE_ES]: "Испанский",
        [languages.LANGUAGE_ET]: "Эстонский",
        [languages.LANGUAGE_FA]: "Персидский",
        [languages.LANGUAGE_FA_IR]: "Персидский (Иран)",
        [languages.LANGUAGE_FI]: "Финский",
        [languages.LANGUAGE_FR]: "Французский",
        [languages.LANGUAGE_FR_CA]: "Французский (Канада)",
        [languages.LANGUAGE_FR_CH]: "Французский (Швейцария)",
        [languages.LANGUAGE_HE]: "Иврит",
        [languages.LANGUAGE_HI]: "Хинди",
        [languages.LANGUAGE_HR]: "Хорватский",
        [languages.LANGUAGE_HU]: "Венгерский",
        [languages.LANGUAGE_HY]: "Армянский",
        [languages.LANGUAGE_ID]: "Индонезийский",
        [languages.LANGUAGE_IS]: "Исландский",
        [languages.LANGUAGE_IT]: "Итальянский",
        [languages.LANGUAGE_JA]: "Японский",
        [languages.LANGUAGE_KA]: "Грузинский",
        [languages.LANGUAGE_KG]: "Конго",
        [languages.LANGUAGE_KK]: "Казахский",
        [languages.LANGUAGE_KM]: "Кхмерский",
        [languages.LANGUAGE_KO]: "Корейский",
        [languages.LANGUAGE_KY]: "Киргизский",
        [languages.LANGUAGE_LA]: "Латинский",
        [languages.LANGUAGE_LT]: "Литовский",
        [languages.LANGUAGE_LV]: "Латышский",
        [languages.LANGUAGE_MK]: "Македонский",
        [languages.LANGUAGE_ML]: "Малаялам",
        [languages.LANGUAGE_MN]: "Монгольский",
        [languages.LANGUAGE_MS]: "Малайский",
        [languages.LANGUAGE_MT]: "Мальтийский",
        [languages.LANGUAGE_NE]: "Непальский",
        [languages.LANGUAGE_NL]: "Нидерландский",
        [languages.LANGUAGE_NO]: "Норвежский",
        [languages.LANGUAGE_PL]: "Польский",
        [languages.LANGUAGE_PT]: "Португальский",
        [languages.LANGUAGE_PT_BR]: "Португальский (Бразилия)",
        [languages.LANGUAGE_RO]: "Румынский",
        [languages.LANGUAGE_RU]: "Русский",
        [languages.LANGUAGE_RW]: "Киньяруанда",
        [languages.LANGUAGE_SD]: "Синдхи",
        [languages.LANGUAGE_SI]: "Сингальский",
        [languages.LANGUAGE_SK]: "Словацкий",
        [languages.LANGUAGE_SL]: "Словенский",
        [languages.LANGUAGE_SO]: "Сомалийский",
        [languages.LANGUAGE_SQ]: "Албанский",
        [languages.LANGUAGE_SR]: "Сербский",
        [languages.LANGUAGE_SV]: "Шведский",
        [languages.LANGUAGE_SW]: "Суахили",
        [languages.LANGUAGE_TA]: "Тамильский",
        [languages.LANGUAGE_TE]: "Телугу",
        [languages.LANGUAGE_TH]: "Тайский",
        [languages.LANGUAGE_TL]: "Тагальский",
        [languages.LANGUAGE_TR]: "Турецкий",
        [languages.LANGUAGE_UK]: "Украинский",
        [languages.LANGUAGE_UR]: "Урду",
        [languages.LANGUAGE_VI]: "Вьетнамский",
        [languages.LANGUAGE_ZH]: "Китайский",
        [languages.LANGUAGE_ZH_CN]: "Китайский (Упрощённый)",
        [languages.LANGUAGE_ZH_TW]: "Китайский (Традиционный)",
        [languages.LANGUAGE_ZU]: "Зулу"
    },
    "adviceMessages": {
        "TAROT": [
            {
                "title": "Легко переключайтесь между офисами",
                "text": "Легко перемещайтесь между разными офисами, чтобы исследовать различные услуги, адаптированные под ваши потребности."
            },
            {
                "title": "Получайте таро-инсайты на ходу",
                "text": "Используйте приложение на своем мобильном устройстве или в веб-браузере, чтобы получать таро-инсайты где бы вы ни были."
            }
        ],
        "TALISMAN": [
            {
                "title": "Создайте профили для своих близких",
                "text": "Добавьте профили для членов семьи и друзей, чтобы создавать персонализированные талисманы для каждого из них."
            },
            {
                "title": "Создавайте и сохраняйте индивидуальные талисманы",
                "text": "Создавайте уникальные талисманы и сохраняйте их в своей галерее для дальнейшего использования или обмена."
            }
        ],
        "ASTROLOGY": [
            {
                "title": "Заполните точные данные профиля",
                "text": "Введите данные о своём рождении, чтобы получать точные астрологические консультации и отслеживать свою астрологическую историю."
            },
            {
                "title": "Регулярно обновляйте данные профиля",
                "text": "Держите информацию профиля актуальной, например, ваше местоположение, чтобы повысить точность астрологических прогнозов."
            }
        ],
        "HOROSCOPE": [
            {
                "title": "Получайте персонализированные гороскопы",
                "text": "Наслаждайтесь ежедневными гороскопами, адаптированными специально под информацию вашего профиля."
            },
            {
                "title": "Отслеживайте историю своих гороскопов",
                "text": "Просматривайте прошлые гороскопы, чтобы наблюдать за тенденциями и размышлениями с течением времени."
            }
        ],
        "HEALTH": [
            {
                "title": "Загружайте соответствующие медицинские документы",
                "text": "Безопасно загружайте свои медицинские документы, чтобы их учитывали во время консультаций."
            },
            {
                "title": "Отслеживайте показатели здоровья",
                "text": "Обновляйте данные профиля, такие как вес и артериальное давление, чтобы отслеживать прогресс своего здоровья."
            },
            {
                "title": "Проводите консультации для членов семьи",
                "text": "Используйте правильный профиль при консультациях от имени членов семьи, чтобы записи были упорядочены."
            },
            {
                "title": "Настройте напоминания о приёме лекарств",
                "text": "Используйте приложение для установки напоминаний о приёме лекарств, чтобы никогда не пропустить дозу."
            },
            {
                "title": "Отслеживайте свои симптомы",
                "text": "Записывайте любые симптомы или изменения в здоровье, чтобы предоставить точную информацию во время консультаций."
            },
            {
                "title": "Доступ к ресурсам по здоровью",
                "text": "Изучайте статьи и ресурсы в приложении, чтобы быть в курсе тем, связанных с вашим здоровьем."
            },
            {
                "title": "Безопасно загружайте документы в формате PDF",
                "text": "Загружайте свои документы в формате PDF и будьте спокойны. Ваш AI-ассистент запомнит информацию и примет её во внимание, когда это будет актуально для текущего вопроса."
            },
            {
                "title": "Убедитесь, что консультации проводятся в правильном профиле",
                "text": "Всегда проводите консультации под правильным профилем. Данные консультации сохраняются в ваших записях или записях ваших членов семьи и будут учтены в будущих AI-консультациях."
            }
        ],
        "PSYCHO": [
            {
                "title": "Ведите конфиденциальный дневник",
                "text": "Записывайте свои мысли и чувства, чтобы поддерживать психическое благополучие и отслеживать свой прогресс со временем."
            },
            {
                "title": "Переключайтесь между профилями по необходимости",
                "text": "Легко переключайтесь между профилями, чтобы управлять консультациями для себя и близких."
            }
        ],
        "ALTMED": [
            {
                "title": "Исследуйте альтернативные методы лечения",
                "text": "Откройте для себя различные варианты альтернативной медицины и добавьте соответствующие в свой профиль."
            },
            {
                "title": "Обновляйте предпочтения в лечении",
                "text": "Регулярно обновляйте свои предпочтения в лечении, чтобы получать наиболее подходящие рекомендации."
            }
        ],
        "VET": [
            {
                "title": "Управляйте профилями ваших питомцев",
                "text": "Создайте профили для своих питомцев, чтобы эффективно отслеживать их здоровье и консультации."
            },
            {
                "title": "Загружайте медицинские документы питомцев",
                "text": "Храните все медицинские записи ваших питомцев в одном месте, загружая соответствующие документы."
            }
        ],
        "DEFAULT": [
            {
                "title": "Общий совет",
                "text": "Оставайтесь позитивными и воспринимайте каждый день таким, какой он есть."
            }
        ]
    }
};