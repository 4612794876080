import React, {
    useState
    , useEffect
    , FC
} from 'react';
import { get_photo_url, on_update } from '../classes/PhotoStore.js';
import {
    IconButton
    , Fade
    , Avatar
    , SxProps
    , Theme
} from '@mui/material';
import { useProfile } from '../classes/ProfileManager.js';

// Utility function to generate initials from a given name
const generateInitials = (fullName) => fullName
    .split(" ")
    .reduce((initials, namePart) => initials += namePart[0] || "", "");


type ProfileIconProps = FC<{
    id: bigint,
    name?: string,
    onClick: (id: bigint) => void,
    onImageChange?: (hasImage: boolean) => void,
    sx?: SxProps<Theme>
}>;

export const ProfileIcon: ProfileIconProps = ({
    id,
    name,
    onClick,
    onImageChange,
    sx
}) => {
    const { get_name } = useProfile();
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [initials, setInitials] = useState<string | undefined>();
    const [startFadeEffect, setStartFadeEffect] = useState(false);

    // Update initials when name changes
    useEffect(() => {
        if (name) {
            setInitials(generateInitials(name));
        }
    }, [name]);


    // Notify if there is an image available
    useEffect(() => {
        if (onImageChange) {
            onImageChange(!!imageUrl);
        }
    }, [imageUrl, onImageChange]);

    // Fetch and set image URL for the profile
    const fetchAndSetImageUrl = (profileId) => {
        get_photo_url(profileId)
            .then((url) => setImageUrl(url))
            .catch(() => setImageUrl(undefined));
    };

    // Fetch profile name and image when the ID changes
    useEffect(() => {
        if (id !== undefined && id !== null) {
            const fetchProfileName = name
                ? Promise.resolve(name)
                : get_name(id);

            fetchProfileName
                .then((profileName) => generateInitials(profileName))
                .then((generatedInitials) => setInitials(generatedInitials))
                .catch(() => setInitials("#"));

            fetchAndSetImageUrl(id);

            // Subscribe to photo updates
            return on_update((updatedId) => {
                if (updatedId === id) {
                    fetchAndSetImageUrl(updatedId);
                }
            });
        }
    }, [id, name, get_name]);


    // Delay the activation of the fade effect
    useEffect(() => {
        const fadeTimer = setTimeout(() => {
            setStartFadeEffect(true);
        }, 500); // Delay in milliseconds (e.g., 1000ms = 1 second)

        return () => clearTimeout(fadeTimer); // Cleanup the timeout on unmount
    }, []);

    return (
        <IconButton
            {...(sx && { sx: { ...sx } })}
            {...(onClick && { onClick: () => onClick(id) })}
        >
            <Fade in={startFadeEffect} timeout={1000}>
                <Avatar
                    {...(sx && {
                        sx: {
                            width: sx.width,
                            height: sx.height
                        }
                    })}
                    src={imageUrl}
                >
                    {initials}
                </Avatar>
            </Fade>
        </IconButton>
    );
};