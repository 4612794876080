import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from "react-markdown";
import terms from '../assets/terms.js';

const pdfUrl = 'https://wixee.ai/assets/legal/Terms%20and%20Conditions%20280924%20EN.pdf';

export function TermsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();

    const { t } = useTranslation();

    return (
        <Box sx={{
            pt: 1, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: 'auto', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)'
        }}>
            <Box sx={{
                display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', p: 2
            }}>

                <ReactMarkdown variant="body2" className="markdown-content">{terms}</ReactMarkdown>

                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => window.open(pdfUrl, '_blank')}
                    >
                        {t('terms.download')}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDelayedNavigation(() => setDirection('backward'), -1)}
                    >
                        {t('terms.close')}
                    </Button>
                </Box>
            </Box>
        </Box >
    );
}

export default TermsScreen;
