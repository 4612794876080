import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const hi = {
    "send_email_code": "{{email}} पर कोड के साथ ईमेल भेजा जा रहा है: {{code}}",
    "token_valid": "टोकन मान्य है",
    "token_invalid": "टोकन अमान्य है",
    "verification_error": "टोकन सत्यापन में त्रुटि:",
    "verification_completed": "सत्यापन पूरा हुआ। प्रमाणीकृत: ",
    "welcome": {
        "title": "विक्सी (Wixee) में आपका स्वागत है"
    },
    "splash1": {
        "title": "स्मार्ट स्वास्थ्य यहीं से शुरू होता है",
        "description": "अपने परिवार की सेहत की यात्रा को व्यक्तिगत AI स्वास्थ्य सलाह के साथ सशक्त बनाएं, जो हर सदस्य की विशिष्ट आवश्यकताओं के अनुसार बनाई गई है"
    },
    "splash2": {
        "title": "सबके लिए स्वास्थ्य अंतर्दृष्टि",
        "description": "विभिन्न आयु वर्गों के लिए व्यापक स्वास्थ्य अंतर्दृष्टि प्राप्त करें, जिससे आपके प्रियजनों की सेहत और खुशहाली बनी रहे"
    },
    "splash3": {
        "title": "आपकी सेहत, हमारी प्राथमिकता",
        "description": "परिवार की देखभाल का भविष्य अनुभव करें, जहाँ AI-चालित टिप्स और सिफारिशें आपको और आपके प्रियजनों को स्वस्थ रखने के लिए डिज़ाइन की गई हैं"
    },
    "splash4": {
        "title": "चलिए शुरू करें!",
        "description": "हमारी विशेषताओं का आनंद लेने के लिए लॉगिन करें, और स्वस्थ रहें!",
        "signInButton": "लॉग इन करें"
    },
    "home": {
        "title": "मुख्य पृष्ठ",
        "consultations": "परामर्श",
        "consultationsDescription": "अपने परामर्श देखने के लिए यहाँ क्लिक करें",
        "documents": "दस्तावेज़",
        "documentsDescription": "अपने दस्तावेज़ अपलोड करने के लिए यहाँ क्लिक करें",
        "notifications": "सूचनाएँ",
        "notificationsDescription": "सभी घटनाओं को देखने के लिए यहाँ क्लिक करें",
        "offices": "कार्यालय",
        "officesDescription": "सेवा प्राप्त करने के लिए एक कार्यालय चुनें",
        "settings": "सेटिंग्स",
        "settingsDescription": "भाषा बदलें, आदि",
        "cardTitle": "आज का दिन आनंदपूर्वक बिताएं",
        "cardDescription": "आपकी स्वास्थ्य स्थिति और सौर गतिविधि दर्शाते हैं कि आपको संज्ञानात्मक क्षमताओं में परेशानी हो सकती है। आराम करें और दिन का आनंद लें",
        "newConsultation": "नया परामर्श",
        "adTitle": "ऐप का कोई विज्ञापन",
        "learnMore": "और जानें"
    },
    "calendar": {
        "title": "सूचनाएँ",
        "created": "बनाया गया",
        "source": {
            "consultation": "परामर्श से",
            "document": "दस्तावेज़ से"
        }
    },
    "documents": {
        "title": "मेरे दस्तावेज़",
        "latest": "नवीनतम",
        "lastMonth": "पिछले महीने",
        "older": "पुराने",
        "upload": "नया दस्तावेज़ अपलोड करें",
        "deleted": "हटाया गया"
    },
    "document": {
        "title": "दस्तावेज़"
    },
    "header": {
        "youAndYourBeloved": "आप और आपके प्रियजन"
    },
    "settings": {
        "title": "सेटिंग्स",
        "language": "भाषा",
        "country": "देश",
        "userCountry": "मेरा देश",
        "userLanguage": "मेरी भाषा",
        "userEmail": "मेरा ईमेल",
        "emailAddress": "ईमेल पता"
    },
    "consultation": {
        "title": "परामर्श",
        "description": "परामर्श का विवरण",
        "selectConsultant": "परामर्शदाता चुनें",
        "manageConsultation": "परामर्श प्रबंधित करें",
        "consultationDeleted": "{{date}} को परामर्श हटाया गया",
        "download": "डाउनलोड करें",
        "message": "संदेश",
        "join": {
            "MALE": "वार्ता में शामिल होते हैं",
            "FEMALE": "वार्ता में शामिल होती हैं",
            "OTHER": "वार्ता में शामिल होते हैं"
        }
    },
    "consultations": {
        "title": "मेरे परामर्श",
        "description": "परामर्श का विवरण",
        "latest": "नवीनतम",
        "seeAll": "सभी देखें",
        "lastMonth": "पिछले महीने",
        "older": "पुराने",
        "deleted": "हटाया गया",
        "about": "के बारे में"
    },
    "profiles": {
        "title": "मेरे प्रियजन"
    },
    "profile": {
        "title": "मेरी प्रोफ़ाइल",
        "billing": "मेरी शुल्क योजना",
        "offices": "मेरे कार्यालय",
        "settings": "सेटिंग्स",
        "general": "सामान्य",
        "create": "बनाएं",
        "logout": "लॉगआउट",
        "addProfile": "नया प्रियजन जोड़ें",
        "hideStoredData": "संग्रहीत डेटा छिपाएँ",
        "showStoredData": "संग्रहीत डेटा दिखाएँ",
        "officeNoSummary": "कोई सारांश उपलब्ध नहीं",
        "termsConditions": "नियम और शर्तें",
        "clickToChange": "बदलने के लिए क्लिक करें",
        "name": "नाम",
        "comment": "टिप्पणी",
        "dateOfBirth": "जन्म तिथि",
        "timeOfBirth": "जन्म का समय (स्थानीय)",
        "placeOfBirth": "जन्म स्थान",
        "height": "लंबाई (सेमी)",
        "weight": "वजन (किग्रा)",
        "cm": "सेमी",
        "kg": "किग्रा"
    },
    "billing": {
        "title": "बिलिंग",
        "currentBalance": "वर्तमान शेष",
        "subscriptionPlan": "सदस्यता योजना",
        "subscription": {
            "free": "नि:शुल्क",
            "premium": "प्रीमियम",
            "exclusive": {
                "title": "विशेष",
                "description": "विशेष सदस्यता योजना आपको अद्वितीय सामग्री, विशेष सुविधाओं और व्यक्तिगत सेवाओं तक प्रीमियम पहुंच प्रदान करती है जो नियमित उपयोगकर्ताओं के लिए उपलब्ध नहीं हैं"
            }
        },
        "topUp": "रिचार्ज करें",
        "changePlan": "योजना बदलें"
    },
    "offices": {
        "title": "कार्यालय",
        "HEALTH": {
            "name": "स्वास्थ्य",
            "description": "AI स्वास्थ्य निदान सेवा उपयोगकर्ता द्वारा प्रदान किए गए लक्षणों और चिकित्सा इतिहास का विश्लेषण करती है और स्वास्थ्य स्थिति के प्रारंभिक मूल्यांकन प्रदान करती है। यह व्यक्तिगत अंतर्दृष्टि और सिफारिशें प्रदान करती है, उपयोगकर्ताओं को संभावित चिकित्सा देखभाल के अगले चरणों के बारे में मार्गदर्शन करती है, जिसमें पेशेवर सहायता कब ली जानी चाहिए",
            "disclaimer": "यह AI-चालित सेवा केवल सूचना उद्देश्यों के लिए है और यह कोई चिकित्सा उपकरण या सेवा नहीं है। यह चिकित्सा निदान, उपचार या नुस्खे प्रदान नहीं करती है। सभी स्वास्थ्य संबंधी निर्णय और क्रियाएँ एक लाइसेंसधारी चिकित्सा पेशेवर के परामर्श पर आधारित होनी चाहिए। इस उपकरण का उपयोग करने के आधार पर किसी भी स्वास्थ्य परिणाम के लिए इस एप्लिकेशन के डेवलपर्स और मालिक जिम्मेदार नहीं हैं। यदि आपको चिकित्सा आपातकाल का सामना करना पड़ रहा है, तो कृपया तुरंत 911 या अपनी स्थानीय आपातकालीन सेवा को कॉल करें।"
        },
        "ALTMED": {
            "name": "वैकल्पिक चिकित्सा",
            "description": "AI-चालित वैकल्पिक चिकित्सा सेवा, जिसमें एक्यूपंक्चर, हर्बल मेडिसिन और होम्योपैथी जैसी पारंपरिक चिकित्सा से अलग उपचार प्रदान करती है। उन्नत एल्गोरिदम का उपयोग करके यह वैकल्पिक चिकित्सा सिद्धांतों के आधार पर व्यक्तिगत सिफारिशें प्रदान करती है। यह सेवा पारंपरिक चिकित्सा उपचारों को पूरक करने के लिए बनाई गई है, जिससे उपयोगकर्ता की स्वास्थ्य और जीवनशैली के बारे में समग्र दृष्टिकोण मिलता है",
            "disclaimer": "यह AI-चालित वैकल्पिक चिकित्सा सेवा सामान्य स्वास्थ्य और सूचना उद्देश्यों के लिए है। यह पेशेवर चिकित्सा निदान या उपचार का विकल्प नहीं है। स्वास्थ्य से संबंधित किसी भी निर्णय से पहले हमेशा एक योग्य स्वास्थ्य सेवा प्रदाता से परामर्श करें। इस सेवा के उपयोग से उत्पन्न किसी भी स्वास्थ्य परिणाम के लिए डेवलपर्स जिम्मेदार नहीं हैं।"
        }
        // and so on for all other sections...
    },
    "languages": {
        "af": "अफ्रीकी",
        "ar": "अरबी",
        "az": "अज़रबैजानी",
        "be": "बेलारूसी",
        "bg": "बुल्गारियाई",
        "bn": "बंगाली",
        "bs": "बोस्नियाई",
        "ca": "कातालान",
        "cs": "चेक",
        "da": "डैनिश",
        "de": "जर्मन",
        "de-AT": "जर्मन (ऑस्ट्रिया)",
        "el": "यूनानी",
        "en": "अंग्रेज़ी",
        "en-GB": "अंग्रेज़ी (यूके)",
        "en-US": "अंग्रेज़ी (यूएस)",
        "es": "स्पेनिश",
        "et": "एस्टोनियाई",
        "fa-IR": "फारसी (ईरान)",
        "fi": "फ़िनिश",
        "fr": "फ्रेंच",
        "fr-CA": "फ्रेंच (कनाडा)",
        "fr-CH": "फ्रेंच (स्विट्ज़रलैंड)",
        "he": "हिब्रू",
        "hi": "हिन्दी",
        "hu": "हंगेरियन",
        "is": "आइसलैंडिक",
        "it": "इतालवी",
        "ja": "जापानी",
        "ko": "कोरियाई",
        "nl": "डच",
        "pl": "पोलिश",
        "pt": "पुर्तगाली",
        "pt-BR": "पुर्तगाली (ब्राज़ील)",
        "ro": "रोमानियाई",
        "ru": "रूसी",
        "sv": "स्वीडिश",
        "tr": "तुर्की",
        "uk": "यूक्रेनी",
        "vi": "वियतनामी",
        "zh-CN": "चीनी (सरलीकृत)",
        "zh-TW": "चीनी (पारंपरिक)"
    }
}
