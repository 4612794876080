// AppContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import officeManager from '../classes/OfficeManager.js';
import ConsultantManager from '../classes/ConsultantManager';
import { AlertBox } from '../elements/AlertBox';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { AppRoutes } from '../config/AppModes.ts';
import { useAuth } from './AuthContext.js'
import ConsultationsManager from '../classes/ConsultationsManager.js';
import DocumentsManager from '../classes/DocumentsManager.js';
import api from '../classes/API.js';
import { useProfile } from '../classes/ProfileManager.js';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const CURRENT_USER_ID = 'currentUserId';
const CURRENT_PROFILE_ID = 'currentProfileId';
const CURRENT_COUNTRY = 'currentCountry';

export const AppProvider = ({ children }) => {
    const [headerVisibility, setHeaderVisibility] = useState(true);
    const [footerVisibility, setFooterVisibility] = useState(true);
    const [backButtonVisibility, setBackButtonVisibility] = useState(true);
    const [headerTitle, setHeaderTitle] = useState('Title');
    const [headerType, setHeaderType] = useState(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerType, setFooterType] = useState(null);
    const [userMessage, setUserMessage] = useState(null);
    //const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [alertBoxOpen, setAlertBoxOpen] = useState(false);
    const [alertBoxMessage, setAlertBoxMessage] = useState('');
    const [alertBoxSeverity, setAlertBoxSeverity] = useState('');
    const [screenRoute, setScreenRoute] = useState(AppRoutes.ROUTE_HOME);
    const [uploadedDocument, setUploadedDocument] = useState(null);
    const [consultationId, setConsultationId] = useState(null);
    const [currentProfileId, setCurrentProfileId] = useState(null);

    // Initialize managers
    const [consultantManager, setConsultantManager] = useState(null);
    const [consultationsManager, setConsultationsManager] = useState(null);
    const [documentsManager, setDocumentsManager] = useState(null);

    const { t, i18n } = useTranslation(); // Initialize translation function
    const location = useLocation();

    const { userProfile, updateUserProfile, isAuthenticated, tokenVerificationCompleted } = useAuth();

    useEffect(() => {
        if (!tokenVerificationCompleted) return;

        // Initialize managers
        const cons = new ConsultantManager();
        setConsultantManager(cons);
        setConsultationsManager(new ConsultationsManager(officeManager, cons));
        setDocumentsManager(new DocumentsManager(officeManager));
    }, [tokenVerificationCompleted]);

    function getRouteType(location) {
        for (const routeKey in AppRoutes) {
            if (AppRoutes[routeKey].path === location) {
                return AppRoutes[routeKey];
            }
        }
        return null;
    }

    useEffect(() => {
        const handleLocationChange = () => {
            //console.log('App Context Current path:', location.pathname);
            const config = getRouteType(location.pathname);
            //console.log('App Context Config:', config);
            if (config) setScreenRoute(config);
            setHeaderType(config?.headerType);
            setFooterType(config?.footerType);
            setHeaderVisibility(config?.header);
            setFooterVisibility(config?.footer);
            setBackButtonVisibility(config?.backButton);
            setHeaderTitle(t(config?.headerTitle));
        };

        handleLocationChange(); // Call initially to set the state for the initial location
    }, [location, t]);

    const [userCountry, setUserCountry] = useState();

    useEffect(
        () => {
            userProfile?.country != userCountry
            && updateUserProfile({
                ...userProfile
                , country: userCountry
            });
            localStorage
                .setItem(CURRENT_COUNTRY, userCountry);
        }, [
            userCountry
        ]
    );

    useEffect(
        () => {
            if(!tokenVerificationCompleted) return;
            if(!isAuthenticated) return;

            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(userProfile?.timezone != tz || !userProfile?.country) api.setUserTimezone(tz)
                .then(country => {
                console.log("detected ", country);
                if(userCountry != country) setUserCountry(country);
                updateUserProfile({
                    ...userProfile
                    , timezone: tz
                });
                });

            const user_id = localStorage
                .getItem(CURRENT_USER_ID);
            if(
                userProfile
                && user_id != userProfile.id
            ) {
                //NOTE: handle user switch
                //Memorise new user id for quick app init
                localStorage
                    .setItem(CURRENT_USER_ID, userProfile.id);
                //Reset settings from previous user
                localStorage
                    .removeItem(CURRENT_PROFILE_ID);
                localStorage
                    .removeItem(CURRENT_COUNTRY);
            }
            //NOTE: restore current profile from previous login
            const profileId = localStorage
                .getItem(CURRENT_PROFILE_ID)
                || (
                    userProfile
                    && userProfile.profile_id
                );
            profileId
                && setCurrentProfileId(profileId);
            const country = localStorage
                .getItem(CURRENT_COUNTRY);
            setUserCountry(
                userProfile.country //Update with actual server side value
                || country //Set default country
                || i18n.country
            );
        }, [
            isAuthenticated, tokenVerificationCompleted
        ]
    );

    const [ currentProfile, setCurrentProfile ] = useState();
    const { getProfile } = useProfile();

    useEffect(
        () => {
            if(!currentProfileId) return;
            localStorage.setItem(CURRENT_PROFILE_ID, currentProfileId);
            getProfile(currentProfileId).then(x => setCurrentProfile(x));
        }, [
            currentProfileId
        ]
    )

    const showAlert = (severity, message) => {
        setAlertBoxSeverity(severity);
        setAlertBoxMessage(message);
        setAlertBoxOpen(Date.now());
    };

    return ([
        <AppContext.Provider value={{
            headerVisibility, footerVisibility, backButtonVisibility,
            headerTitle,
            headerType,
            headerHeight, setHeaderHeight,
            footerType,
            userMessage, setUserMessage,
            officeManager, consultationsManager, documentsManager, consultantManager,
            consultationId, setConsultationId,
            screenRoute,
            showAlert,
            uploadedDocument, setUploadedDocument,
            currentProfileId, setCurrentProfileId,
            userCountry, setUserCountry,
            currentProfile
        }}>
            {children}
        </AppContext.Provider>,
        <AlertBox key="alertBox"
            open={alertBoxOpen}
            message={alertBoxMessage}
            severity={alertBoxSeverity}
        />
    ]);
};
