// AuthContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import api from '../classes/API'; // Import your API class
import '../utils/i18n.js'; // Import the i18n configuration
import { AppRoutes } from '../config/AppModes.ts';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [email, setEmail] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [token, setToken] = useState(null);
    const [tokenVerificationCompleted, setTokenVerificationCompleted] = useState(false);

    //NOTE: A guard against double verifyToken call.
    let isFirstMount = true;
    useEffect(() => {
        //NOTE: Subscribe API events and store function that
        //      terminates subscriptions
        const terminate_sub = (
            sub_list => () => sub_list.forEach(sub => sub())
        )([
            api.on_authorized(user => {
                console.log('Authorized');
                setTokenVerificationCompleted(true);
                setIsAuthenticated(true);
                setUserProfile(user);
            })
            , api.on_unauthorized(() => {
                console.log('Non authorized');
                setTokenVerificationCompleted(true);
                setIsAuthenticated(false);
                setUserProfile(null);
            })
            , api.on_negotiate(() => {
                console.log('Authorization negotiation starts');
                setTokenVerificationCompleted(false);
            })
        ]);

        if(isFirstMount) { //NOTE: Prevent secod call of verifyToken on app load from url
            isFirstMount = false;
            api.verifyToken()
                .then(d => setToken(d.token)) //checkwhen token is needed here
                .catch(err => console.error(err));
        }
        //NOTE: Terminate API subscriptions on unmount
        return terminate_sub;
    }, []);

    const login = (token) => {
        setToken(token);
    }

    const logout = () => {
        api.drop();
    }

    const updateUserProfile = (profile) => {
        setUserProfile(profile);
    };

    const sendCode = async (userEmail) => {
        setEmail(userEmail);
        return await api.apiRequestInstantLogin(userEmail);
    };

    const verifyCode = async (inputCode) => {
        try {
            const tokenData = await api.apiInstantLogin(email, inputCode);
            login(tokenData.token);
            setAuthCode('');
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated, login, logout, sendCode, verifyCode,
            email,
            authCode,
            userProfile, updateUserProfile,
            tokenVerificationCompleted,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useAuthRedirect = (setDirection, useDelayedNavigation) => {
    const setDelayedNavigation = useDelayedNavigation();
    const { isAuthenticated, tokenVerificationCompleted } = useAuth();
    const hasRedirected = useRef(false);

    useEffect(() => {
        //console.log('Need redirect? isAuthenticated:', isAuthenticated, 'tokenVerificationCompleted:', tokenVerificationCompleted);
        if (!isAuthenticated && !hasRedirected.current && tokenVerificationCompleted) {
            hasRedirected.current = true;
            setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_WELCOME.path);
        }
    }, [isAuthenticated, setDelayedNavigation, setDirection, tokenVerificationCompleted]);
};