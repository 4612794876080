import React, { useEffect, useState } from 'react';
import { Box, Typography, Switch, Divider, Collapse, Button } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js'; // Import useAppContext hook
import { useAuth } from '../components/AuthContext.js';
import { getAllProfilesSummary } from '../classes/ProfileManager.js';
import { ProfileIcon } from '../components/ProfileIcon.tsx';

export function OfficesScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const { userCountry, officeManager } = useAppContext();
    const { t } = useTranslation();
    const [ activeOffices, setActiveOffices ] = useState([]);
    useEffect(
        () => {
            if(officeManager && userCountry)
                setActiveOffices(
                    officeManager.getActiveOffices(userCountry)
                );
        }, [
            officeManager
            , userCountry
        ]
    );
    const { tokenVerificationCompleted } = useAuth();

    const [services, setServices] = useState([]);
    const [summary, setSummary] = useState({});
    const [expanded, setExpanded] = useState({});


    const handleToggle = (service) => {
        setServices((prevState) => ({
            ...prevState,
            [service]: !prevState[service],
        }));
    };

    const handleExpandClick = (officeId) => {
        setExpanded((prevState) => ({
            ...prevState,
            [officeId]: !prevState[officeId],
        }));
    };

    useEffect(() => {
        if (!tokenVerificationCompleted) return;
        getAllProfilesSummary()
            .then((data) => data.json())
            .then((summaryData) => {
                let summaryDict = {};
                summaryData.summary_list.forEach((item) => {
                    const officeId = item.office_id;
                    const profileId = item.profile_id;

                    if (!summaryDict[officeId]) {
                        summaryDict[officeId] = {};
                    }
                    if (!summaryDict[officeId][profileId]) {
                        summaryDict[officeId][profileId] = [];
                    }

                    const profileEvents = item.profile_summary.events;
                    profileEvents.forEach((event) => {

                        if (!summaryDict[officeId][profileId]) {
                            summaryDict[officeId][profileId] = [];
                        }
                        const formattedDate = new Intl.DateTimeFormat('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        }).format(new Date(event.date));
                        summaryDict[officeId][profileId].push(`${formattedDate}: ${event.detailed_summary}`);
                    });
                });
                setSummary(summaryDict);
                console.log(summaryDict);
            });
    }, [tokenVerificationCompleted]);

    return (
        <Box sx={{
            pt: 1, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: '100svh', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
        }}>
            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', m: 2,
            }}>
                <Box
                    sx={{
                        width: '100%',
                        my: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '20px',
                        textAlign: 'center',
                        position: 'relative'

                    }}
                >

                    {activeOffices.map((office) => (
                        <Box sx={{ width: 'auto', mx: 4 }}>

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 3, mt: 1 }}>
                                <Box sx={{ width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mt: 1 }}>
                                    <Typography variant="h3" color="black" sx={{ flexGrow: 1 }}>
                                        {t(`offices.${office.type}.name`)}
                                    </Typography>
                                    <Switch
                                        checked={services[office.type]}
                                        onChange={() => handleToggle('healthAdvisor')}
                                        sx={{ opacity: 0 }}
                                        disabled
                                        color="primary"
                                    />
                                </Box>
                                <Typography variant="body2" sx={{ textAlign: 'left', flexGrow: 1 }}>
                                    {t(`offices.${office.type}.description`)}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleExpandClick(office.id)}
                                    sx={{ mt: 3, mb: 1 }}
                                >
                                    {expanded[office.id] ? t('profile.hideStoredData') : t('profile.showStoredData')}
                                </Button>
                                <Collapse in={expanded[office.id]} timeout="auto" unmountOnExit>
                                    {summary[office.id] ? (
                                        <Box>
                                            {/* Iterate over profiles within each office */}
                                            {Object.entries(summary[office.id]).map(([profileId, events]) => (
                                                <Box
                                                    key={profileId}
                                                    sx={{
                                                        mt: 3, p: 1,
                                                        borderRadius: '11px',
                                                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                                        borderWidth: '1px',
                                                        borderColor: 'lightgrey',
                                                        borderStyle: 'solid',
                                                        backgroundColor: 'rgba(240, 240, 240, 1)',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <ProfileIcon
                                                        id={profileId}
                                                        name={''}
                                                        onClick={() => { }}
                                                        sx={{
                                                            width: '2.3rem', height: '2.3rem',
                                                            position: 'absolute',  // <-- Position the icon absolutely
                                                            top: '-1.15rem',  // <-- Adjust the top distance to place the icon in the top right corner
                                                            right: '1.5rem',  // <-- Adjust the right distance to place the icon in the top right corner
                                                            boxShadow: '0px 0px 1px rgba(0, 0, 0, 1)',
                                                        }}
                                                    />
                                                    <ul>
                                                        {events.map((eventSummary, index) => (
                                                            <li key={index}>
                                                                <Typography variant="body2" sx={{ textAlign: 'left', flexGrow: 1 }}>
                                                                    {eventSummary}
                                                                </Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Box>
                                            ))}
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" sx={{ textAlign: 'left', flexGrow: 1 }}>
                                            {t('profile.officeNoSummary')}
                                        </Typography>
                                    )}
                                </Collapse>

                            </Box>
                            <Divider />
                        </Box>

                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default OfficesScreen;
