import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const ar ={
    "send_email_code": "يتم إرسال البريد الإلكتروني إلى {{email}} مع الرمز: {{code}}",
    "token_valid": "الرمز صالح",
    "token_invalid": "الرمز غير صالح",
    "verification_error": "خطأ في التحقق من الرمز:",
    "verification_completed": "اكتمل التحقق. تم المصادقة: ",
    "welcome": {
        "title": "مرحبًا بكم في Wixee"
    },
    "splash1": {
        "title": "الصحة الذكية تبدأ من هنا",
        "description": "قم بتمكين رحلة صحة عائلتك من خلال نصائح صحية مخصصة بالذكاء الاصطناعي تلبي احتياجات كل فرد على حدة"
    },
    "splash2": {
        "title": "رؤى صحية للجميع",
        "description": "اكتشف رؤى صحية شاملة لجميع الأعمار لضمان بقاء أحبائك بصحة وسعادة"
    },
    "splash3": {
        "title": "صحتك، أولويتنا",
        "description": "اكتشف مستقبل الرعاية الصحية الأسرية مع النصائح والتوصيات القائمة على الذكاء الاصطناعي، للحفاظ على صحة الجميع"
    },
    "splash4": {
        "title": "لنبدأ الآن!",
        "description": "سجّل الدخول للاستمتاع بالميزات التي نقدمها وابقى بصحة جيدة!",
        "signInButton": "تسجيل الدخول"
    },
    "home": {
        "title": "الرئيسية",
        "consultations": "الاستشارات",
        "consultationsDescription": "انقر هنا لمشاهدة استشاراتك",
        "documents": "الوثائق",
        "documentsDescription": "انقر هنا لتحميل الوثائق",
        "notifications": "الإشعارات",
        "notificationsDescription": "انقر هنا لرؤية جميع الأحداث",
        "offices": "المكاتب",
        "officesDescription": "اختر مكتبًا للحصول على الخدمة",
        "settings": "الإعدادات",
        "settingsDescription": "تغيير اللغة، إلخ",
        "cardTitle": "استمتع بيومك اليوم",
        "cardDescription": "تشير حالتك الصحية ونشاط الشمس إلى أنك قد تواجه مشاكل في القدرات العقلية. استرخ واستمتع بيومك",
        "newConsultation": "استشارة جديدة",
        "adTitle": "إعلان من التطبيق",
        "learnMore": "تعرف على المزيد"
    },
    "calendar": {
        "title": "الإشعارات",
        "created": "تم الإنشاء",
        "source": {
            "consultation": "من الاستشارة",
            "document": "من الوثيقة"
        }
    },
    "documents": {
        "title": "وثائقي",
        "latest": "الأحدث",
        "lastMonth": "الشهر الماضي",
        "older": "الأقدم",
        "upload": "تحميل وثيقة جديدة",
        "deleted": "تم الحذف"
    },
    "document": {
        "title": "وثيقة"
    },
    "header": {
        "youAndYourBeloved": "أنت وأحبائك"
    },
    "settings": {
        "title": "الإعدادات",
        "language": "اللغة",
        "country": "البلد",
        "userCountry": "بلدي",
        "userLanguage": "لغتي",
        "userEmail": "بريدي الإلكتروني",
        "emailAddress": "عنوان البريد الإلكتروني"
    },
    "consultation": {
        "title": "الاستشارة",
        "description": "وصف الاستشارة",
        "selectConsultant": "اختر المستشار",
        "manageConsultation": "إدارة الاستشارة",
        "consultationDeleted": "تم حذف الاستشارة في {{date}}",
        "download": "تنزيل",
        "message": "رسالة",
        "join": {
            "MALE": "انضم إلى المحادثة",
            "FEMALE": "انضمت إلى المحادثة",
            "OTHER": "انضم إلى المحادثة"
        }
    },
    "consultations": {
        "title": "استشاراتي",
        "description": "وصف الاستشارة",
        "latest": "الأحدث",
        "seeAll": "عرض الكل",
        "lastMonth": "الشهر الماضي",
        "older": "الأقدم",
        "deleted": "تم الحذف",
        "about": "حول"
    },
    "profiles": {
        "title": "أحبائي"
    },
    "profile": {
        "title": "ملفي الشخصي",
        "billing": "تعريفة الاشتراك الخاصة بي",
        "offices": "مكاتبي",
        "settings": "الإعدادات",
        "general": "عام",
        "create": "إنشاء",
        "logout": "تسجيل الخروج",
        "addProfile": "إضافة شخص جديد",
        "hideStoredData": "إخفاء البيانات المخزنة",
        "showStoredData": "عرض البيانات المخزنة",
        "officeNoSummary": "لا يوجد ملخص متاح",
        "termsConditions": "الشروط والأحكام",
        "clickToChange": "انقر لتغيير",
        "name": "الاسم",
        "comment": "التعليق",
        "dateOfBirth": "تاريخ الميلاد",
        "timeOfBirth": "وقت الميلاد (المحلي)",
        "placeOfBirth": "مكان الميلاد",
        "height": "الطول (سم)",
        "weight": "الوزن (كجم)",
        "cm": "سم",
        "kg": "كجم"
    },
    "billing": {
        "title": "الفواتير",
        "currentBalance": "الرصيد الحالي",
        "subscriptionPlan": "خطة الاشتراك",
        "subscription": {
            "free": "مجاني",
            "premium": "بريميوم",
            "exclusive": {
                "title": "حصري",
                "description": "تمنحك خطة الاشتراك الحصرية وصولاً مميزًا إلى محتوى خاص وميزات مميزة وخدمات مخصصة غير متاحة للمستخدمين العاديين"
            }
        },
        "topUp": "إعادة تعبئة الرصيد",
        "changePlan": "تغيير الخطة"
    },
    "offices": {
        "title": "المكاتب",
        "HEALTH": {
            "name": "الصحة",
            "description": "تحلل خدمة تشخيص الصحة بالذكاء الاصطناعي الأعراض والتاريخ الطبي المقدم من المستخدم لتقديم تقييمات أولية للحالة الصحية. توفر رؤى وتوصيات مخصصة، وتوجه المستخدمين حول الخطوات التالية الممكنة للرعاية الطبية، بما في ذلك متى يجب طلب المساعدة الطبية المتخصصة",
            "disclaimer": "هذه الخدمة المدعومة بالذكاء الاصطناعي هي لأغراض معلوماتية فقط وليست أداة أو خدمة طبية. لا تقدم تشخيصات طبية أو علاجات أو وصفات طبية. يجب أن تستند جميع القرارات والإجراءات المتعلقة بالصحة إلى استشارة مع محترف طبي مرخص. المطورون ومالكو هذا التطبيق غير مسؤولين عن أي نتائج صحية ناتجة عن استخدام هذه الأداة. إذا كنت تواجه حالة طبية طارئة، يرجى الاتصال بالرقم 911 أو خدمة الطوارئ المحلية على الفور."
        },
        "ALTMED": {
            "name": "الطب البديل",
            "description": "تقدم خدمة الطب البديل المدعومة بالذكاء الاصطناعي مجموعة من العلاجات غير التقليدية مثل الوخز بالإبر والطب العشبي والمعالجة المثلية. باستخدام خوارزميات متقدمة، تقدم توصيات مخصصة بناءً على مبادئ ممارسات الشفاء البديلة. تهدف الخدمة إلى تكملة العلاجات الطبية التقليدية من خلال تقديم رؤى شاملة حول صحة المستخدم ورفاهيته",
            "disclaimer": "تم تصميم هذه الخدمة المدعومة بالذكاء الاصطناعي لأغراض العافية العامة والمعلوماتية فقط. لا تعتبر بديلاً عن التشخيص أو العلاج الطبي المهني. يجب دائمًا استشارة مقدم الرعاية الصحية المؤهل قبل اتخاذ أي قرارات صحية. المطورون غير مسؤولين عن أي نتائج صحية ناتجة عن استخدام هذه الخدمة."
        }
        // and so on for other "officeTypes"
    },
    // Continue for other sections...
    "languages": {
        "af": "الأفريكانية",
        "ar": "العربية",
        "az": "الأذربيجانية",
        "be": "البيلاروسية",
        "bg": "البلغارية",
        "bn": "البنغالية",
        "bs": "البوسنية",
        "ca": "الكاتالونية",
        "cs": "التشيكية",
        "da": "الدانماركية",
        "de": "الألمانية",
        "de-AT": "الألمانية (النمسا)",
        "el": "اليونانية",
        "en": "الإنجليزية",
        "en-GB": "الإنجليزية (المملكة المتحدة)",
        "en-US": "الإنجليزية (الولايات المتحدة)",
        "es": "الإسبانية",
        "et": "الإستونية",
        "fa-IR": "الفارسية (إيران)",
        "fi": "الفنلندية",
        "fr": "الفرنسية",
        "fr-CA": "الفرنسية (كندا)",
        "fr-CH": "الفرنسية (سويسرا)",
        "he": "العبرية",
        "hi": "الهندية",
        "hu": "الهنغارية",
        "is": "الأيسلندية",
        "it": "الإيطالية",
        "ja": "اليابانية",
        "ko": "الكورية",
        "nl": "الهولندية",
        "pl": "البولندية",
        "pt": "البرتغالية",
        "pt-BR": "البرتغالية (البرازيل)",
        "ro": "الرومانية",
        "ru": "الروسية",
        "sv": "السويدية",
        "tr": "التركية",
        "uk": "الأوكرانية",
        "vi": "الفيتنامية",
        "zh-CN": "الصينية (المبسطة)",
        "zh-TW": "الصينية (التقليدية)"
    }
}
