import React, { FC, useEffect, useState, Fragment, ChangeEventHandler, useRef, memo } from 'react';
import { Box, Typography, TextField, InputAdornment, Divider, Button, Badge, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material'
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocation } from 'react-router-dom';
import { useAuth, useAuthRedirect} from '../components/AuthContext.js';
import { useAppContext } from '../components/AppContext.js';
import api from '../classes/API.js';
import { useProfile } from '../classes/ProfileManager.js'
import { set_photo_url, delete_photo } from '../classes/PhotoStore.js';
import { ProfileIcon } from '../components/ProfileIcon.tsx';
import PopupDeleteDialog from '../components/PopupDeleteDialog.js';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage, getCurrentLocale } from '../utils/dateUtils.js'; 
import { officeTypes } from '../config/AppModes.ts';

const SAVE_TIMEOUT_MS = 2000;

interface IProfile {
    id?: bigint
    , name: string
    , comment: string
    , height: Number
    , weight: Number
    , date_of_birth: Date
};

type TProfileComponent = FC<{
    profile: IProfile
    , setProfile: (p: IProfile) => void
}>;

const ProfileGeneral: TProfileComponent = ({
    profile
    , setProfile
}) => {
    const { t } = useTranslation();
    const handleProfileChange = (key: string): ChangeEventHandler<HTMLInputElement> => event => {
        setProfile({
            ...profile
            , [key]: event.target.value
        });
    };
    return (
        <Box mt={4} width="100%">
            <Typography variant="h3" color="primary">
                {t('profile.general')}
            </Typography>
            <Box mt={1}>
                <TextField sx={{ mb: 2 }}
                    label={t('profile.name')}
                    value={profile.name}
                    onChange={handleProfileChange('name')}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />
                <TextField sx={{ mb: 0 }}
                    label={t('profile.comment')}
                    value={profile.comment}
                    onChange={handleProfileChange('comment')}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />
            </Box>
        </Box>
    )
};

const NUMBER_RE = /^\d*\.?\d*$/;

const ProfileHealth: TProfileComponent = ({ profile, setProfile }) => {
    const { t } = useTranslation();
    const handleDateOfBirth = (d: Date | null) => {
        if (!d || isNaN(d.getTime())) return;
        if (profile.date_of_birth) {
            //NOTE: Preserve the time part of a date
            d.setUTCHours(profile.date_of_birth.getUTCHours());
            d.setUTCMinutes(profile.date_of_birth.getUTCMinutes());
            d.setUTCSeconds(profile.date_of_birth.getUTCSeconds());
            d.setUTCMilliseconds(profile.date_of_birth.getUTCMilliseconds());
        }
        //NOTE: Mutate the date of birth
        setProfile({
            ...profile
            , date_of_birth: d
        });
    };
    const [ error, setError ] = useState({} as {[key:string]:string|null})
    const timers = useRef({});
    const handleProfileFloat = (key: string): ChangeEventHandler<HTMLInputElement> => e => {
        if(NUMBER_RE.test(e?.target?.value || "")) {
            setError({
                ...error
                , [key]: null
            });
            setProfile({
                ...profile
                , [key]: e.target.value
            });
        } else {
            setError({
                ...error
                , [key]: "Not a number"
            });
            clearTimeout(timers[key]);
            timers[key] = setTimeout(
                () => setError({
                    ...error
                    , [key]: null
                })
                , 400
            )
        }
    };
    const [maxDate] = useState(new Date());
    return (
        <Box mt={0} width="100%">
            <Typography variant="h3" color="primary" mb={3}>
                {t(`offices.${officeTypes.HEALTH}.name`)}
            </Typography>
            <Box mt={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getCurrentLocale()}>
                    <DatePicker sx={{ mb: 2 }}
                        label={t('profile.dateOfBirth')}
                        maxDate={maxDate}
                        value={profile.date_of_birth}
                        onChange={handleDateOfBirth}
                    />
                </LocalizationProvider>
                <TextField sx={{ mb: 2 }}
                    label={t('profile.height')}
                    value={profile.height}
                    onChange={handleProfileFloat('height')}
                    error={!!error.height}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{t('profile.cm')}</InputAdornment>
                    }}
                />
                <TextField sx={{ mb: 0 }}
                    label={t('profile.weight')}
                    value={profile.weight}
                    onChange={handleProfileFloat('weight')}
                    error={!!error.weight}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{t('profile.kg')}</InputAdornment>
                    }}
                />
            </Box>
        </Box>
    );
};

const ProfileTarot: TProfileComponent = ({ profile: p, setProfile }) => {
    const { t } = useTranslation();
    return (
        <Box mt={0} width="100%">
            <Typography variant="h3" color="primary" mb={0}>
                {t(`offices.${officeTypes.TAROT}.name`)}
            </Typography>
            <Box mt={0}>
                <TextField sx={{ mb: 4 }}
                    label={t('profile.placeOfBirth')}
                    value={p.place_of_birth || ''}
                    onChange={(e) => setProfile({ ...p, place_of_birth: e.target.value })}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getCurrentLocale()}>
                    <TimePicker sx={{ mb: 4 }}
                        label={t('profile.timeOfBirth')}
                        value={p.date_of_birth}
                        onChange={(val) => {
                            if (!val || isNaN(val.getTime())) return;
                            if (p.date_of_birth) {
                                // Preserve the date components
                                val.setUTCFullYear(p.date_of_birth.getUTCFullYear());
                                val.setUTCMonth(p.date_of_birth.getUTCMonth());
                                val.setUTCDate(p.date_of_birth.getUTCDate());
                                // Preserve the intact time components
                                val.setUTCSeconds(p.date_of_birth.getUTCSeconds());
                                val.setUTCMilliseconds(p.date_of_birth.getUTCMilliseconds());
                            }
                            // Mutate birth time
                            setProfile({ ...p, date_of_birth: val });
                        }}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};

const componentsData: Array<TProfileComponent> = [
    memo(({ profile, setProfile }) => (
        <ProfileGeneral
            profile={profile}
            setProfile={setProfile}
        />
    ))
    , memo(({ profile, setProfile }) => (
        <ProfileHealth
            profile={profile}
            setProfile={setProfile}
        />
    ))
    , memo(({ profile, setProfile }) => (
        <ProfileTarot
            profile={profile}
            setProfile={setProfile}
        />
    ))
];

const Profile2Screen: FC<{
    setDirection
    , useDelayedNavigation
}> = ({
    setDirection
    , useDelayedNavigation
}) => {

        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const profileId = BigInt(searchParams.get('profileId') || "0");

        const [components, setComponents] = useState([] as TProfileComponent[]);

        useEffect(() => {
            setComponents(componentsData);
        }, []);

        const { t } = useTranslation();

        const [
            currentProfileId
        ] = useState(profileId);

        //NOTE: Substitute when actual current profile deleted
        const { currentProfileId : curProfileId, setCurrentProfileId } = useAppContext();
        const ensureCurrentProfileId = () => {
            currentProfileId == curProfileId
                && setCurrentProfileId(userProfile.profile_id);
        }

        const [
            profile
            , setProfile
        ] = useState({
            name: ""
            , comment: ""
            , date_of_birth: (d => d.setUTCFullYear(d.getUTCFullYear() - 18) ? d : d)(new Date())
            , weight: 0
            , height: 0
        } as IProfile);

        const {
            isAuthenticated
            , userProfile
        } = useAuth();

        useAuthRedirect(setDirection, useDelayedNavigation);
        const setDelayedNavigation = useDelayedNavigation();

        const hasProfile = useRef(false);

        const { getProfile, saveProfile, newProfile, deleteProfile } = useProfile();

        useEffect(() => {
            if (hasProfile.current) return;
            if (isAuthenticated && currentProfileId) {
                getProfile(
                    currentProfileId
                )
                .then(x => setProfile({
                    ...x
                    , date_of_birth: new Date(Date.parse(x.date_of_birth))
                }));
                hasProfile.current = true;
            };
        }, [
            isAuthenticated
            , currentProfileId
        ]);

        const canDelete = useRef(false);

        useEffect(() => {
            if(currentProfileId && userProfile) {
                canDelete.current = !(userProfile.profile_id == currentProfileId);
            }

        }, [
            userProfile
            , currentProfileId
        ]);

        const fileInputRef = useRef<HTMLInputElement|null>(null);

        const handlePhotoChange = p_id => {
            const file_input = (fileInputRef.current?.files || [])[0];
            if(file_input) set_photo_url(
                p_id
                , file_input
            )
            .catch(err => console.error(
                `Error while uploading photo: ${err}`
            ));
        }

        const deletePhoto = (e) => delete_photo(
                currentProfileId
            )
            .catch(err => console.error(
                `Error deleting profile photo: ${err}`
            ));

        const [ saveTimeout, setSaveTimeout ] = useState<NodeJS.Timeout | null>(null);

        const saveChanges = useRef(false);

        useEffect(() => {
            if(!profileId) return; //NOTE: Appy below only on edit
            saveTimeout && clearTimeout(saveTimeout);
            if(saveChanges.current) {
                const timeoutId = setTimeout(
                    () => saveProfile(profile)
                        .then(() => console.log(`Saved profile #${profile.id}!`))
                    , SAVE_TIMEOUT_MS
                );
                setSaveTimeout(timeoutId);
            } else {
                //NOTE: Consider only changes of a loaded profile
                saveChanges.current = !!profile.id;
            }
        } , [
            profile
        ]);

        useEffect(
            () => () => {
                //NOTE: Clean image for next new profile creation
                if(!profileId) delete_photo(0);
                //NOTE: Save the profile on unmount
                if(!saveTimeout) return;
                clearTimeout(saveTimeout);
                saveProfile(profile)
                    .then(() => console.log(`Saved profile #${profile.id} on unmount!`))
            }
            , []
        );

        const [ hasImage, setHasImage ] = useState(false);

        const [dialogOpen, setDialogOpen] = useState(false);
        return (
            <Box sx={{
                p: 0, m: 0,
                height: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                background: 'rgba(255, 255, 255, 0.7)',
            }}>
            <Box sx={{
                display: 'flex'
                , flexDirection: 'column'
                , flexGrow: 1
                , alignItems: 'center'
                , mt: 0, ml: 4, mr: 4
                , pt: 8
                , flex: 1
                , color: 'white'
            }}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom'
                        , horizontal: 'right'
                    }}
                    sx={{
                        width: '90px'
                        , height: '90px'
                    }}
                    badgeContent={
                        <IconButton
                            aria-label="delete"
                            onClick={deletePhoto}
                            {...(!hasImage && {
                                    sx : {
                                        visibility: 'hidden'
                                    }
                            })}
                        ><DeleteIcon/></IconButton>
                    }
                >
                    <ProfileIcon
                        id={currentProfileId}
                        name={profile.name}
                        onImageChange={e => { setHasImage(e); }}
                        onClick={e => fileInputRef.current && fileInputRef.current.click()}
                        sx={{
                            width: '100%'
                            , height: '100%'
                            , zoom: 2.5
                            , padding: '0px'
                        }}
                    />
                </Badge>
                <label>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={e => handlePhotoChange(currentProfileId)}
                        ref={fileInputRef}
                    />
                    <Typography
                        variant="h3"
                        sx={{ mt: 2, cursor: 'pointer', color: 'black' }}
                    >
                        {t('profile.clickToChange')}
                    </Typography>
                </label>
                {components.map((Cmp, ndx) => (
                    <Fragment key={ndx}>
                        {ndx > 0 && <Divider sx={{ my: 3 }} />}
                        <Cmp profile={profile} setProfile={setProfile}/>
                    </Fragment>
                ))}
            </Box>
            <Box sx={{
                display: 'flex'
                , flexDirection: 'column'
                , mt: 2
                , width: '100%'
                , alignItems: 'center'
            }}>
                {profile.id ? (
                    <>
                        {canDelete.current && (<>
                            <Button
                                sx={{
                                    mt: 2
                                    , mb: 5
                                }}
                                variant="danger"
                                color="primary"
                                onClick={() => setDialogOpen(true)}
                            >Delete</Button>
                            <PopupDeleteDialog
                                open={dialogOpen}
                                setOpen={setDialogOpen}
                                onDelete={() => deleteProfile(profile.id)
                                    .then(() => ensureCurrentProfileId())
                                    .then(() => setDelayedNavigation(
                                        () => setDirection('backward')
                                        , -1
                                    ))
                                }
                                Title={`Delete profile `}
                                Description={'There is no restore funtion and profile will e deleted forever. Proceed?'}
                            />
                        </>)}
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 2,
                            mb: 5
                        }}
                        onClick={e => newProfile(profile)
                            .then(p => fileInputRef.current?.files?.length
                                && handlePhotoChange(p.id)
                            )
                            .then(() => setDelayedNavigation(
                                () => setDirection('backward')
                                , -1
                            ))
                        }
                    >
                        {t('profile.create')}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Profile2Screen;
