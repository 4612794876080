// src/screens/ConsultationScreen.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Fade, Dialog, DialogContent } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';
import { useAuth } from '../components/AuthContext.js';
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js';
import { useSearchParams } from 'react-router-dom'; // Import useParams
import { AppRoutes } from '../config/AppModes.ts';
import ReactMarkdown from "react-markdown";
import DocumentCard from '../components/DocumentCard.js';

export function DocumentDetailsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { userProfile, tokenVerificationCompleted } = useAuth();
    const { t } = useTranslation();
    const setDelayedNavigation = useDelayedNavigation();

    const userPhoto = userProfile?.picture || null;

    const [searchParams] = useSearchParams();
    const docId = searchParams.get('docid');

    const messageEndRef = useRef(null);
    const { documentsManager, showAlert } = useAppContext();
    const [deletedDate, setDeletedDate] = useState(null);

    const [document, setDocument] = useState(null);
    const [markdown, setMarkdown] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const isLoaded = useRef(false);

    useEffect(() => {

        if (isLoaded.current) return;

        if (tokenVerificationCompleted && documentsManager)
            if (docId) {
                // load chat messages by consultation id
                loadDocument(docId);
                isLoaded.current = true;
            }
    }, [tokenVerificationCompleted, docId, documentsManager]);


    const [openImagePreview, setOpenImagePreview] = useState(false);
    const handleImageClick = () => {
        console.log("Image clicked");
        setOpenImagePreview(true);
    };
    const handleClose = () => {
        setOpenImagePreview(false);
    };



    async function loadDocument(docId) {
        try {
            const data = await documentsManager.getDocument(docId);
            setDocument(data);
            setMarkdown(data?.content || 'Error: document content not found');

            if (data.mime_type.startsWith('image/')) {
                const imageResponse = await documentsManager.loadDocumentContent(data.document_hash);
                setImageUrl(imageResponse.url);
            }
        } catch (error) {
            console.error('Error loading document:', error);
        }
    }

    const downloadDocument = async (documentId) => {
        console.log('downloadDocument', documentId);
        documentsManager.downloadDocument(documentId, showAlert);
    };

    return (
        <Box sx={{
            p: 0, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
            flex: 1, height: 'auto', background: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{ px: 2, py: 3, flexGrow: 0, textAlign: 'left', position: 'relative', }}>

                <DocumentCard
                    key={'doc-card'}
                    content={document}
                    userPhoto={userPhoto}
                    handleCardClick={null}
                    isDeleted={false}
                    index={1}
                    expanded={true}
                />

            </Box>

            <Fade in={document !== null} timeout={1000}>
                <Box sx={{ width: '100%', mx: 2, px: 2, pt: 0, pb: 0.5, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                    <Button sx={{ position: 'relative', width: '70%', m: 1, maxWidth: '20em' }}
                        variant="contained"
                        color="primary"
                        onClick={() => downloadDocument(document?.document_hash)}
                    >
                        {t('documentDetails.download')}
                    </Button>

                    <Button sx={{ position: 'relative', width: '70%', m: 1, maxWidth: '20em' }}
                        variant="outlined" color="primary"
                        onClick={() => { setDelayedNavigation(() => setDirection('forward'), (AppRoutes.ROUTE_DOCUMENT_MANAGE.path) + '?docid=' + docId) }}>
                        {t('documentDetails.manage')}
                    </Button>
                </Box>
            </Fade>

            <Fade in={markdown !== null} timeout={1000}>
                <Box sx={{ width: 'auto', minWidth: '20em', p: 2, mx: 2, backgroundColor: 'white', borderRadius: '11px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}>
                    <ReactMarkdown variant="body2" className="markdown-content">{markdown}</ReactMarkdown>
                    {imageUrl && (
                        <Fade in={imageUrl !== null} timeout={2000}>
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={imageUrl}
                                    alt="Document Image"
                                    style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '8px', cursor: 'pointer' }}
                                    onClick={handleImageClick}
                                />
                                {/* Dialog for image preview */}
                                <Dialog open={openImagePreview} onClose={handleClose} maxWidth="md" fullWidth>
                                    <DialogContent>
                                        <img
                                            src={imageUrl}
                                            alt="Preview"
                                            style={{ width: '100%', height: 'auto' }}

                                        />
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        </Fade>

                    )}
                </Box>
            </Fade >


            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flex: 1, p: 3
            }}>&nbsp;</Box>

            <div ref={messageEndRef} />
        </Box >
    );
}

export default DocumentDetailsScreen;
