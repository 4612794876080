import React, { useEffect, useState } from 'react';
import { Box, TextField, IconButton, CircularProgress, Fade } from '@mui/material';
import { useAppContext } from '../components/AppContext.js';
import { useAuth } from '../components/AuthContext.js';
import { useTranslation } from 'react-i18next';
import iconMic from '../img/icon-mic.svg';
import iconSend from '../img/icon-send.svg';
import iconStop from '../img/icon-stop.svg';
import iconPaperclip from '../img/icon-paperclip.svg';
import iconEQWaves from '../img/icon-eq-waves.gif';
import useSpeechButtonLogic from '../elements/SpeechButtonLogic';
import PopupUploadDialog from '../components/PopupUploadDialog.js';

const FooterConsultation = () => {
    const [message, setMessage] = useState('');
    const [micEnabled, setMicEnabled] = useState(false);
    const { setUserMessage, setUploadedDocument, consultationId, currentProfileId, officeManager } = useAppContext();
    const { userProfile } = useAuth();
    const [popupOpen, setPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { documentsManager } = useAppContext();

    //const language = userProfile?.language || 'en-US';
    const language = userProfile?.language || 'ru-RU';

    const { isRecording, startRecording, stopRecording } = useSpeechButtonLogic({
        onSpeechFinished: handleSpeechFinished, language: language, setIsLoading: () => { }
    });

    useEffect(() => {
        if (!isRecording) {
            handleStopMic();
        }
    }, [isRecording]);

    const handleSendMessage = () => {
        if (message.trim()) {
            setUserMessage(message);
            setMessage(''); // Clear the text field
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleStartMic = (event) => {
        if (!micEnabled) {
            setMicEnabled(true);
            startRecording();
        }
    };

    const handleStopMic = (event) => {
        if (micEnabled) {
            setMicEnabled(false);
            stopRecording();
        }
    };

    function handleSpeechFinished(message) {
        if (message.trim()) {
            setUserMessage(message);
            setMessage(''); // Clear the text field
        }
    }

    function handleUpload() {
        console.log('handleUpload');
        setPopupOpen(true);
    }

    async function handleFileSelected(files) {
        try {
            setIsLoading(true);

            const officeId = officeManager.getCurrentOfficeId();
            console.log('handleFileSelected, office:', officeId);

            console.log('handleFileSelected', files);

            for (let file of files) {
                if (file.type.startsWith('image/')) {
                    const response = await documentsManager.uploadPhoto(file, currentProfileId, officeId, consultationId);
                } else {
                    const response = await documentsManager.uploadDocument(file, currentProfileId, officeId, consultationId);
                    if (response.docId) {
                        console.log('Document uploaded successfully', response);
                        setUploadedDocument({
                            docId: response.docId,
                            title: response.title,
                            short_summary: response.short_summary,
                            long_summary: response.long_summary,
                        });
                    }
                }
            }

            // Handle success (e.g., show a success message or refresh the document list)
        } catch (error) {
            console.error('Error uploading file:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setIsLoading(false);
            setPopupOpen(false);
        }
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', width: '100%', }}>
            <IconButton onClick={handleUpload} edge="start" sx={{ ml: 2, mr: 0, my: 1 }}>

                {isLoading ? (
                    <CircularProgress thickness={4} size={80} sx={{ mx: 2 }} />
                ) : (
                    <Fade in={consultationId > 0}>
                        < Box
                            component="img"
                            src={iconPaperclip}
                            alt="Attach"
                            sx={{ width: 40, height: 40 }}
                        />
                    </Fade>
                )}

            </IconButton>
            {
                !micEnabled ? (
                    <TextField
                        sx={{ flexGrow: 1, mb: 1, px: 0, '& fieldset': { border: 'none' }, }}
                        placeholder={`${t('consultation.message')}...`}
                        multiline
                        maxRows={5}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            style: { fontSize: 18 }  // Adjust the font size here
                        }}
                    />
                ) : (
                    <Box sx={{ height: 40, flexGrow: 1, my: 2, display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            src={iconEQWaves}
                            alt="sound"
                            sx={{ height: 40, }}
                        >
                        </Box>
                    </Box>
                )
            }

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mr: 3, my: 1, }}>
                {
                    message.length ?
                        (
                            <IconButton onClick={handleSendMessage} edge="end">
                                <Box
                                    component="img"
                                    src={iconSend}
                                    alt="Send"
                                    sx={{ width: 40, height: 40 }}
                                />
                            </IconButton>

                        ) : !micEnabled ? (

                            <IconButton onClick={handleStartMic} edge="end" >
                                <Box
                                    component="img"
                                    src={iconMic}
                                    alt="Mic"
                                    sx={{ width: 40, height: 40 }}
                                />
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleStopMic} edge="end" >
                                <Box
                                    component="img"
                                    src={iconStop}
                                    alt="Mic"
                                    sx={{ width: 40, height: 40 }}
                                />
                            </IconButton>
                        )
                }


                <PopupUploadDialog
                    open={popupOpen}
                    setOpen={setPopupOpen}
                    onFileUpload={handleFileSelected} />

            </Box>
        </Box>
    );
};

export default FooterConsultation;
