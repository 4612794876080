import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Button, Fade } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import { AppRoutes } from '../config/AppModes.ts';
import { useTranslation } from 'react-i18next';
import { ProfileCard } from '../components/ProfileCard.tsx';

import { useProfile } from '../classes/ProfileManager.js';

export function SelectProfileScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();

    const [profiles, setProfiles] = useState([]);
    const isLoaded = useRef(false);

    const { t } = useTranslation();
    const { allProfiles } = useProfile();

    useEffect(() => {
        // Load all profiles
        if (isLoaded.current) return;
        isLoaded.current = true;
        allProfiles()
            .then(p => setProfiles(p));
    }, []);

    const handleCardClick = (profile) => {
        // Handle card click event
        setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_PROFILE_EDIT.path}?profileId=${profile.id}`);
    };

    return (
        <Box sx={{
            pt: 0
            , pb: 10
            , display: 'flex'
            , flexDirection: 'column'
            , alignItems: 'center'
            , width: '100%'
            , backgroundColor: 'rgba(255, 255, 255, 0.7)'
            , height: 'auto'
        }}>
            <Box sx={{
                pt: 2
                , display: 'flex'
                , flexDirection: 'column'
                , alignItems: 'center'
                , width: '100%'
            }}>
                <Grid container spacing={3} sx={{
                    px: { xs: 1, md: 2 },
                    width: '100%'
                }}>
                    {profiles.map((profile, index) => {
                        return (
                            <Grid item xs={6} md={4} key={index}>
                                <ProfileCard
                                    profile={profile}
                                    handleCardClick={handleCardClick}
                                    ind={index}
                                />
                            </Grid>
                        )
                    })}
                </Grid>

            </Box>
            {profiles.length < 6 && <Fade in={isLoaded.current} timeout={1000}>
                <Button sx={{ width: '70%', mt: 5, mx: 3, mb: 0, maxWidth: '20em' }}
                    variant="contained" color="primary"
                    onClick={() => { setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_PROFILE_EDIT.path}?profileId=0`) }}
                >
                    {t('profile.addProfile')}
                </Button>
            </Fade>}
        </Box >
    );
}

export default SelectProfileScreen;
