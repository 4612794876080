/**
 * The event queue to dispatch events and subscriptions.
 */
const queue = new EventTarget();

/**
 * Publish message into topic_name
 * @param {*} topic_name to publish messages into
 * @returns {*} function that publish argument as a message
 */
const pub = topic_name => message => {
    queue.dispatchEvent(
        new CustomEvent(
            topic_name
            , { detail: message }
        )
    );
}

/**
 * Subscribe callback function to be called when message is 
 * published into topic_name.
 * @param {*} topic_name to subsctibe to messages in
 * @returns a function to subscribe a callback funtion to process a message
 */
const sub = topic_name => handle_callback => {
    if(!handle_callback) return () => {};
    const cb = event => {
        handle_callback((event || {}).detail);
    };
    queue.addEventListener(topic_name, cb);
    return () => {
        queue.removeEventListener(topic_name, cb);
    }
}

/**
 * A pair of pub and sub fuctions in a form ready to structural assignement.
 * The Pub function is publish its argument into a topic_name. Pub function
 * returns void, so to say is fire-and-forget.
 * The Sub fuction is subscribing callback function to topic_name. The 
 * subscribed function will receive as an argument an argument, passed
 * to Pub function. The Sub function returns a funcition with no argumnts
 * that ends the subscription.   
 * @param {*} topic_name to work with
 * @returns pub and sub functions
 */
const topic = topic_name => {
    return {
        pub: pub(topic_name)
        , sub: sub(topic_name)
    };
}

export { topic };