import React, { useEffect } from 'react';
import { Button, Box, Typography, Fade, Collapse, FormControlLabel, Switch, Link, Alert } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import GoogleLoginButton from '../components/GoogleLoginButton.js';
import logo from '../img/wixee-magistral.svg';
import { AppRoutes } from '../config/AppModes.ts';
import { useTranslation } from 'react-i18next';

function isAndroidDevice() {
    return /Android/i.test(navigator.userAgent);
}

export function LoginScreen({ setDirection, useDelayedNavigation }) {
    const { t } = useTranslation();
    const setDelayedNavigation = useDelayedNavigation();
    const [fadeDelay, setFadeDelay] = React.useState(false);
    const [termsAgreed, setTermsAgreed] = React.useState(false);
    const [personalDataAgreed, setPersonalDataAgreed] = React.useState(false);
    const [medicalDataAgreed, setMedicalDataAgreed] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        setTimeout(() => setFadeDelay(true), 250);
    }, []);

    const validateAgreements = () => {
        if (!termsAgreed || !personalDataAgreed || !medicalDataAgreed) {
            setErrorMessage('You must agree to all terms before logging in');
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const handleGoogleLoginCode = (code) => {
        if (validateAgreements()) {
            // Implement Google login logic here
            console.log('Login with Google');
            console.log('handleGoogleLoginCode:', code);
            // check if code is string
            if (typeof code === 'string') {
                setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_GOOGLELOGIN.path + '?code=' + code);
            }
            else {
                console.log('code is not a string!');
            }
        }
    };

    const handleFacebookLogin = () => {
        if (validateAgreements()) {
            // Implement Facebook login logic here
            console.log('Login with Facebook');
        }
    };

    const handleAppleLogin = () => {
        if (validateAgreements()) {
            // Implement Apple login logic here
            console.log('Login with Apple');
        }
    };

    const handleEmailLogin = () => {
        if (validateAgreements()) {
            // Navigate to LoginByEmailScreen
            setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_LOGIN_BY_EMAIL.path);
        }
    };

    return (
        <Box sx={{
            // background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100vh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0
        }}>
            <Box sx={{ background: 'rgba(255,255,255,0.5)', }}>
                <Box
                    sx={{
                        pt: 10,
                        pb: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'black',
                        height: 'auto',
                    }}
                >

                    <Fade in={fadeDelay} timeout={1000}>
                        <img src={logo} alt="Wixee Logo" style={{ width: '20rem' }} />
                    </Fade>

                    {/* 
                    <Typography sx={{ mx: 4, mt: 4, textAlign: 'center' }} variant="h2">{t('login.welcome')}</Typography>
*/}
                    <Typography sx={{ mx: 4, mt: 4, textAlign: 'left' }} variant="body1">{t('login.signIn')}</Typography>
                    <Collapse sx={{ width: '100%' }} in={errorMessage !== ''} timeout={1000}>
                        <Box sx={{ mt: 4, width: '100%' }}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Box>
                    </Collapse>

                    <Box sx={{ mt: 0, mx: 4, pt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        {!isAndroidDevice() && (
                            <GoogleLoginButton onSuccess={handleGoogleLoginCode} onFailure={handleGoogleLoginCode} onValidate={validateAgreements} />
                        )}

                        <Collapse in={false} timeout={100}>
                            <Button
                                variant="outlined"
                                startIcon={<FacebookIcon style={{ fontSize: 30 }} />}
                                onClick={handleFacebookLogin}
                                sx={{
                                    mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                    maxWidth: { xs: '100%', sm: '60%' },
                                    justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                    '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                        marginRight: 2,           // Adjust spacing between the icon and the text
                                    },
                                }}
                            >
                                {t('login.loginWithFacebook')}
                            </Button>
                        </Collapse>

                        <Collapse in={false} timeout={100}>
                            <Button
                                variant="outlined"
                                startIcon={<AppleIcon style={{ fontSize: 30 }} />}
                                onClick={handleAppleLogin}
                                sx={{
                                    mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                    maxWidth: { xs: '100%', sm: '60%' },
                                    justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                    '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                        marginRight: 2,           // Adjust spacing between the icon and the text
                                    },
                                }}
                            >
                                {t('login.loginWithApple')}
                            </Button>
                        </Collapse>

                        <Button
                            variant="outlined"
                            startIcon={<EmailIcon style={{ fontSize: 30 }} />}
                            onClick={handleEmailLogin}
                            sx={{
                                mb: 2, width: '100%',  // Ensures text and icon are aligned more to the left
                                maxWidth: { xs: '100%', sm: '60%' },
                                justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                                '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                                    marginRight: 2,           // Adjust spacing between the icon and the text
                                },
                            }}
                        >
                            {t('login.loginWithEmail')}
                        </Button>


                        <Box sx={{ mt: 3, mx: 4, alignSelf: 'flex-start' }}>
                            <FormControlLabel
                                control={<Switch checked={termsAgreed} onChange={() => setTermsAgreed(!termsAgreed)} />}
                                label={
                                    <Box>
                                        <Typography variant="body2">{t('login.switchTerms')} (<Link href="https://wixee.ai/assets/legal/Terms%20and%20Conditions%20280924%20EN.pdf">Terms & Conditions</Link>)</Typography>
                                        <Typography variant="caption" display="block" color="black">{t('login.textTerms')}</Typography>
                                    </Box>
                                }
                                sx={{ alignItems: 'flex-start', mb: 2 }}
                            />
                            <FormControlLabel
                                control={<Switch checked={personalDataAgreed} onChange={() => setPersonalDataAgreed(!personalDataAgreed)} />}
                                label={
                                    <Box>
                                        <Typography variant="body2">{t('login.switchPersonalData')}</Typography>
                                        <Typography variant="caption" display="block" color="black">{t('login.textPersonalData')}</Typography>
                                    </Box>
                                }
                                sx={{ alignItems: 'flex-start', mb: 2 }}
                            />
                            <FormControlLabel
                                control={<Switch checked={medicalDataAgreed} onChange={() => setMedicalDataAgreed(!medicalDataAgreed)} />}
                                label={
                                    <Box>
                                        <Typography variant="body2">{t('login.switchMedicalData')}</Typography>
                                        <Typography variant="caption" display="block" color="black">{t('login.textMedicalData')}</Typography>
                                    </Box>
                                }
                                sx={{ alignItems: 'flex-start', mb: 2 }}
                            />
                        </Box>

                        {/* <Box sx={{ mt: 2 }}>
                            <Button variant="outlined" color="secondary"
                                onClick={() => setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_SPLASH4.path)} sx={{ mr: 1 }}>
                                <ArrowBackIosIcon />
                            </Button>

                            </Box> */}
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

export default LoginScreen;