import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const pt = {
    "send_email_code": "Enviando email para {{email}} com código: {{code}}",
    "token_valid": "Token válido",
    "token_invalid": "Token inválido",
    "verification_error": "Erro ao verificar token:",
    "verification_completed": "Verificação concluída. Autenticado: ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "Saúde Inteligente Começa Aqui",
        "description": "Potencialize a jornada de bem-estar da sua família com conselhos personalizados de saúde fornecidos por IA, adaptados às necessidades únicas de cada membro"
    },
    "splash2": {
        "title": "Informações de Saúde para Todos",
        "description": "Descubra informações de saúde abrangentes que atendem a todas as idades, garantindo que seus entes queridos permaneçam saudáveis e felizes"
    },
    "splash3": {
        "title": "Sua Saúde, Nossa Prioridade",
        "description": "Experimente o futuro do cuidado familiar com dicas e recomendações geradas por IA, projetadas para manter todos em ótima saúde"
    },
    "splash4": {
        "title": "Vamos começar!",
        "description": "Faça login para aproveitar os recursos que oferecemos e mantenha-se saudável!",
        "signInButton": "Entrar"
    },
    "home": {
        "title": "Início",
        "consultations": "Consultas",
        "consultationsDescription": "Clique aqui para ver suas consultas",
        "documents": "Documentos",
        "documentsDescription": "Clique aqui para carregar seus documentos",
        "notifications": "Notificações",
        "notificationsDescription": "Clique aqui para ver todos os eventos",
        "offices": "Departamentos",
        "officesDescription": "Selecione um departamento para obter um serviço",
        "settings": "Configurações",
        "settingsDescription": "Alterar idioma, etc.",
        "cardTitle": "Aproveite seu dia hoje",
        "cardDescription": "Suas condições de saúde e a atividade solar sugerem que você pode ter complicações com habilidades cognitivas. Relaxe e aproveite",
        "newConsultation": "Nova Consulta",
        "adTitle": "Algum anúncio do app",
        "learnMore": "Saiba mais"
    },
    "calendar": {
        "title": "Notificações",
        "created": "Criado",
        "source": {
            "consultation": "da consulta",
            "document": "do documento"
        }
    },
    "documents": {
        "title": "Meus Documentos",
        "latest": "Mais Recentes",
        "lastMonth": "Último Mês",
        "older": "Mais Antigos",
        "upload": "Carregar Novo Documento",
        "deleted": "Excluídos"
    },
    "document": {
        "title": "Documento",
        "generated": "O relatório é pronto.",
        "create": "Ahora puedo crear documentos de resumen. Quieres crearlo?",
        "option": {
            "basic": "Crear documento de resumen",
            "extended": "Crear documento de resumen extendido",
            "clarifications": "Necesito clarificaciones"
        }
    },
    "header": {
        "youAndYourBeloved": "Você e seus entes queridos"
    },
    "settings": {
        "title": "Configurações",
        "language": "Idioma",
        "country": "País",
        "userCountry": "O Meu País",
        "userLanguage": "A Minha Língua",
        "userEmail": "O Meu E-mail",
        "emailAddress": "Endereço de E-mail"
    },
    "consultation": {
        "title": "Consulta",
        "description": "Descrição da Consulta",
        "selectConsultant": "Selecionar Consultor",
        "manageConsultation": "Gerenciar Consulta",
        "consultationDeleted": "Consulta excluída {{date}}",
        "download": "Baixar",
        "message": "Mensagem",
        "join": {
            [genderTypes.MALE]: "Entrou na conversa",
            [genderTypes.FEMALE]: "Entrou na conversa",
            [genderTypes.OTHER]: "Entrou na conversa",
        },
    },
    "consultations": {
        "title": "Minhas Consultas",
        "description": "Descrição da Consulta",
        "latest": "Mais Recentes",
        "seeAll": "Ver tudo",
        "lastMonth": "Último Mês",
        "older": "Mais Antigos",
        "deleted": "Excluídos",
        "about": "sobre",
    },
    "profiles": {
        "title": "Meus Entes Queridos",
    },
    "profile": {
        "title": "Meu Perfil",
        "billing": "Meu Plano",
        "offices": "Meus Departamentos",
        "settings": "Configurações",
        "general": "Geral",
        "create": "Criar",
        "logout": "Sair",
        "addProfile": "Adicionar novo ente querido",
        "hideStoredData": "Ocultar dados armazenados",
        "showStoredData": "Mostrar dados armazenados",
        "officeNoSummary": "Sem resumo disponível",
        "termsConditions": "Termos e Condições",
        "clickToChange": "Clique para alterar",
        "name": "Nome",
        "comment": "Comentário",
        "dateOfBirth": "Data de nascimento",
        "timeOfBirth": "Hora de nascimento (local)",
        "placeOfBirth": "Local de nascimento",
        "height": "Altura (cm)",
        "weight": "Peso (kg)",
        "cm": "cm",
        "kg": "kg"
    },
    "billing": {
        "title": "Faturamento",
        "currentBalance": "Saldo Atual",
        "subscriptionPlan": "Plano de Assinatura",
        "subscription": {
            "free": "Gratuito",
            "premium": "Premium",
            "exclusive": {
                "title": "Exclusivo",
                "description": "Um plano de assinatura exclusivo oferece aos membros acesso premium a conteúdo único, recursos especiais e serviços personalizados que não estão disponíveis para usuários regulares"
            }
        },
        "topUp": "Recarregar",
        "changePlan": "Alterar Plano"
    },
    "offices": {
        "title": "Departamentos",
        [officeTypes.HEALTH]: {
            "name": "Saúde",
            "description": "Um serviço de diagnóstico de saúde baseado em IA que analisa os sintomas fornecidos pelo usuário e o histórico médico para oferecer avaliações preliminares de condições de saúde. Ele fornece informações personalizadas e recomendações, orientando os usuários sobre os próximos passos potenciais para o atendimento médico, incluindo quando procurar ajuda profissional.",
            "disclaimer": "Este serviço impulsionado por IA é apenas para fins informativos e não substitui um diagnóstico médico, tratamento ou prescrição profissional. Todas as decisões e ações relacionadas à saúde devem ser tomadas com base na consulta com um profissional médico licenciado. Os desenvolvedores e proprietários deste aplicativo não são responsáveis por quaisquer resultados de saúde com base no uso desta ferramenta. Se você estiver enfrentando uma emergência médica, ligue para 911 ou para o serviço de emergência local."
        },
        [officeTypes.ALTMED]: {
            "name": "Medicina Alternativa",
            "description": "Um serviço de medicina alternativa impulsionado por IA que oferece uma gama de tratamentos não tradicionais, incluindo acupuntura, fitoterapia e homeopatia. Utilizando algoritmos avançados, ele fornece recomendações personalizadas com base nos princípios das práticas de cura alternativa. O serviço visa complementar os tratamentos médicos convencionais, oferecendo uma visão holística da saúde e bem-estar do usuário.",
            "disclaimer": "Este serviço de medicina alternativa baseado em IA é destinado apenas para bem-estar geral e fins informativos. Não substitui o diagnóstico ou tratamento médico profissional. Sempre procure o conselho de um profissional de saúde qualificado antes de tomar decisões relacionadas à saúde. Os desenvolvedores não são responsáveis por quaisquer resultados de saúde derivados do uso deste serviço."
        },
        [officeTypes.PSYCHO]: {
            "name": "Psicologia",
            "description": "Um serviço de aconselhamento psicológico baseado em IA que oferece suporte à saúde mental analisando emoções, pensamentos e comportamentos relatados pelos usuários. Ele oferece conselhos personalizados, estratégias de enfrentamento e técnicas de autoaperfeiçoamento para ajudar os usuários a lidar com estresse, ansiedade e outros desafios psicológicos. Este serviço é projetado para complementar a terapia tradicional, oferecendo aos usuários suporte imediato e acessível.",
            "disclaimer": "Este serviço de aconselhamento psicológico baseado em IA é projetado para oferecer suporte geral à saúde mental e não substitui o aconselhamento, diagnóstico ou tratamento psicológico ou psiquiátrico profissional. Para cuidados de saúde mental precisos, sempre consulte um profissional licenciado. Se você estiver em crise ou enfrentando uma emergência, entre em contato com o serviço de emergência local ou uma linha direta de crise de saúde mental. Os desenvolvedores e proprietários deste aplicativo não assumem responsabilidade por quaisquer resultados com base no uso deste serviço."
        },
        [officeTypes.VET]: {
            "name": "Animais de Estimação",
            "description": "Um serviço veterinário baseado em IA que ajuda os donos de animais a entender as condições de saúde de seus animais, analisando os sintomas e o histórico médico fornecido pelos usuários. Ele oferece insights preliminares sobre possíveis problemas de saúde, juntamente com recomendações para os próximos passos, como agendar uma consulta veterinária ou gerenciar o cuidado em casa.",
            "disclaimer": "Este serviço veterinário baseado em IA é projetado para fornecer insights preliminares sobre a saúde do seu animal de estimação com base nas informações que você fornece. Não substitui o atendimento, diagnóstico ou tratamento veterinário profissional. Para diagnósticos e planos de tratamento precisos, sempre consulte um veterinário licenciado. Em caso de situação urgente ou de emergência com o seu animal de estimação, entre em contato com o serviço de emergência veterinária local. Os desenvolvedores e proprietários deste aplicativo não são responsáveis por quaisquer resultados relacionados à saúde do seu animal com base no uso desta ferramenta."
        },
        [officeTypes.TEACHER]: {
            "name": "Educação",
            "description": "Um serviço de suporte educacional impulsionado por IA que oferece tutoria e assistência em uma variedade de disciplinas, desde matemática até artes. O serviço oferece planos de aprendizado personalizados e responde a perguntas dos usuários, simulando a orientação de educadores experientes. Ele visa melhorar os resultados de aprendizado, oferecendo suporte educacional personalizado.",
            "disclaimer": "Este serviço de ensino baseado em IA é destinado a complementar a educação formal. Não substitui professores certificados ou programas educacionais credenciados. Os usuários devem procurar orientação profissional para educação formal e certificações. Os desenvolvedores não são responsáveis por quaisquer resultados acadêmicos derivados deste serviço."
        },
        [officeTypes.BUSINESS]: {
            "name": "Negócios",
            "description": "Um serviço de consultoria empresarial baseado em IA projetado para fornecer insights e estratégias para empreendedores, startups e empresas estabelecidas. Aproveitando a análise de dados, o serviço oferece orientação em marketing, operações, finanças e estratégias de crescimento, simulando a experiência de um consultor de negócios experiente. Ele ajuda os usuários a enfrentar os desafios empresariais com recomendações e ferramentas de planejamento impulsionadas por IA.",
            "disclaimer": "Este serviço de consultoria empresarial baseado em IA é apenas para fins informativos e de planejamento estratégico. Não substitui o aconselhamento financeiro, jurídico ou operacional profissional. Os usuários devem consultar profissionais qualificados para quaisquer decisões empresariais críticas. Os desenvolvedores não são responsáveis por quaisquer resultados empresariais resultantes do uso deste serviço."
        }
    },
    "consultants": {
        "title": "Consultores",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "Recepcionista de IA",
            "description": "Ajuda você a escolher o especialista certo"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "Alergista de IA",
            "description": "Especialista em diagnosticar e gerenciar alergias e condições relacionadas"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "Cardiologista de IA",
            "description": "Especialista em saúde do coração, diagnosticando e tratando condições cardíacas"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "Pediatra de IA",
            "description": "Especialista em saúde infantil, oferecendo cuidados médicos para bebês e crianças"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "Recepcionista Veterinário de IA",
            "description": "Ajuda você a escolher o especialista veterinário certo"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "Dentista Veterinário de IA",
            "description": "Especialista em cuidados dentários e saúde bucal para animais"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "Consultor de Feng Shui de IA",
            "description": "Especialista em Feng Shui, oferecendo conselhos para harmonizar seu espaço para melhorar o fluxo de energia"
        }
    },
    "consultationDetails": {
        "title": "Detalhes da Consulta",
        "deleteConfirmation": "Tem certeza de que deseja excluir esta consulta?",
        "deleteConsultation": "Excluir",
        "resumeConsultation": "Retomar",
        "restoreConsultation": "Restaurar",
        "restoreConfirmation": "Tem certeza de que deseja restaurar esta consulta?"
    },
    "documentDetails": {
        "title": "Detalhes do Documento",
        "manage": "Gerenciar Documento",
        "download": "Baixar"
    },
    "documentManage": {
        "title": "Gerenciar Documento",
        "share": "Compartilhar",
        "delete": "Excluir",
        "restore": "Restaurar",
        "rename": "Renomear",
        "changeProfile": "Alterar Pessoa Relacionada",
        "download": "Baixar",
        "documentDeleted": "Documento excluído {{date}}",
        "deleteConfirmation": "Tem certeza de que deseja excluir este documento?",
        "restoreConfirmation": "Tem certeza de que deseja restaurar este documento?"
    },
    "deleteConfirmation": {
        "title": "Você realmente deseja excluir esta consulta?",
        "cancel": "Cancelar",
        "delete": "Excluir"
    },
    "restoreConfirmation": {
        "title": "Deseja restaurar esta consulta?",
        "cancel": "Cancelar",
        "restore": "Restaurar"
    },
    "uploadOptions": {
        "takePhoto": "Tirar Foto",
        "photoGallery": "Galeria de Fotos",
        "uploadFile": "Carregar Documento"
    },
    "languages": {
        "af": "Africâner",
        "ar": "Árabe",
        "az": "Azeri",
        "be": "Bielorrusso",
        "bg": "Búlgaro",
        "bn": "Bengali",
        "bs": "Bósnio",
        "ca": "Catalão",
        "cs": "Tcheco",
        "da": "Dinamarquês",
        "de": "Alemão",
        "de-AT": "Alemão (Áustria)",
        "el": "Grego",
        "en": "Inglês",
        "en-GB": "Inglês (Reino Unido)",
        "en-US": "Inglês (EUA)",
        "es": "Espanhol",
        "et": "Estoniano",
        "fa-IR": "Persa (Irã)",
        "fi": "Finlandês",
        "fr": "Francês",
        "fr-CA": "Francês (Canadá)",
        "fr-CH": "Francês (Suíça)",
        "he": "Hebraico",
        "hi": "Hindi",
        "hu": "Húngaro",
        "is": "Islandês",
        "it": "Italiano",
        "ja": "Japonês",
        "ko": "Coreano",
        "nl": "Holandês",
        "pl": "Polonês",
        "pt": "Português",
        "pt-BR": "Português (Brasil)",
        "ro": "Romeno",
        "ru": "Russo",
        "sv": "Sueco",
        "tr": "Turco",
        "uk": "Ucraniano",
        "vi": "Vietnamita",
        "zh-CN": "Chinês (Simplificado)",
        "zh-TW": "Chinês (Tradicional)"
    }
};
