import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const fr = {
    "send_email_code": "Envoi de l'email à {{email}} avec le code : {{code}}",
    "token_valid": "Jeton valide",
    "token_invalid": "Jeton invalide",
    "verification_error": "Erreur lors de la vérification du jeton :",
    "verification_completed": "Vérification terminée. Authentifié : ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "La santé intelligente commence ici",
        "description": "Améliorez le bien-être de votre famille grâce à des conseils de santé personnalisés par IA, adaptés aux besoins uniques de chaque membre"
    },
    "splash2": {
        "title": "Des conseils santé pour tous",
        "description": "Découvrez des informations complètes sur la santé adaptées à tous les âges, assurant que vos proches restent en bonne santé et heureux"
    },
    "splash3": {
        "title": "Votre santé, notre priorité",
        "description": "Découvrez le futur des soins familiaux avec des conseils et recommandations basés sur l'IA, conçus pour maintenir chacun en parfaite santé"
    },
    "splash4": {
        "title": "Commençons !",
        "description": "Connectez-vous pour profiter des fonctionnalités que nous avons fournies, et restez en bonne santé !",
        "signInButton": "Se connecter"
    },
    "home": {
        "title": "Accueil",
        "consultations": "Consultations",
        "consultationsDescription": "Cliquez ici pour voir vos consultations",
        "documents": "Documents",
        "documentsDescription": "Cliquez ici pour télécharger vos documents",
        "notifications": "Notifications",
        "notificationsDescription": "Cliquez ici pour voir tous les événements",
        "offices": "Services",
        "officesDescription": "Sélectionnez un service pour obtenir une prestation",
        "settings": "Paramètres",
        "settingsDescription": "Changer de langue, etc.",
        "cardTitle": "Profitez de votre journée",
        "cardDescription": "Votre état de santé et l'activité solaire suggèrent que vous pourriez rencontrer des complications cognitives. Détendez-vous et profitez",
        "newConsultation": "Nouvelle consultation",
        "adTitle": "Une publicité de l'application",
        "learnMore": "En savoir plus"
    },
    "calendar": {
        "title": "Notifications",
        "created": "Créé",
        "source": {
            "consultation": "de la consultation",
            "document": "du document"
        }
    },
    "documents": {
        "title": "Mes Documents",
        "latest": "Derniers",
        "lastMonth": "Le mois dernier",
        "older": "Plus anciens",
        "upload": "Télécharger un nouveau document",
        "deleted": "Supprimés"
    },
    "document": {
        "title": "Document",
    },
    "header": {
        "youAndYourBeloved": "Vous et vos proches"
    },
    "settings": {
        "title": "Paramètres",
        "language": "Langue",
        "country": "Pays",
        "userCountry": "Mon Pays",
        "userLanguage": "Ma Langue",
        "userEmail": "Mon Email",
        "emailAddress": "Adresse Email"
    },
    "consultation": {
        "title": "Consultation",
        "description": "Description de la consultation",
        "selectConsultant": "Sélectionner un consultant",
        "manageConsultation": "Gérer la consultation",
        "consultationDeleted": "Consultation supprimée le {{date}}",
        "download": "Télécharger",
        "message": "Message",
        "join": {
            [genderTypes.MALE]: "Rejoint la conversation",
            [genderTypes.FEMALE]: "Rejoint la conversation",
            [genderTypes.OTHER]: "Rejoint la conversation",
        },
    },
    "consultations": {
        "title": "Mes consultations",
        "description": "Description de la consultation",
        "latest": "Dernières",
        "seeAll": "Voir tout",
        "lastMonth": "Le mois dernier",
        "older": "Plus anciennes",
        "deleted": "Supprimées",
        "about": "à propos",
    },
    "profiles": {
        "title": "Mes proches",
    },
    "profile": {
        "title": "Mon profil",
        "billing": "Mon forfait",
        "offices": "Mes services",
        "settings": "Paramètres",
        "general": "Général",
        "create": "Créer",
        "logout": "Déconnexion",
        "addProfile": "Ajouter un nouveau proche",
        "hideStoredData": "Masquer les données enregistrées",
        "showStoredData": "Afficher les données enregistrées",
        "officeNoSummary": "Aucun résumé disponible",
        "termsConditions": "Termes et conditions",
        "clickToChange": "Cliquez pour modifier",
        "name": "Nom",
        "comment": "Commentaire",
        "dateOfBirth": "Date de naissance",
        "timeOfBirth": "Heure de naissance (locale)",
        "placeOfBirth": "Lieu de naissance",
        "height": "Taille (cm)",
        "weight": "Poids (kg)",
        "cm": "cm",
        "kg": "kg"
    },
    "billing": {
        "title": "Facturation",
        "currentBalance": "Solde actuel",
        "subscriptionPlan": "Forfait d'abonnement",
        "subscription": {
            "free": "Gratuit",
            "premium": "Premium",
            "exclusive": {
                "title": "Exclusif",
                "description": "Un abonnement exclusif offrant aux membres un accès premium à des contenus uniques, des fonctionnalités spéciales et des services personnalisés non disponibles pour les utilisateurs réguliers"
            }
        },
        "topUp": "Recharger",
        "changePlan": "Changer de forfait"
    },
    "offices": {
        "title": "Services",
        [officeTypes.HEALTH]: {
            "name": "Santé",
            "description": "Un service de diagnostic de santé basé sur l'IA qui analyse les symptômes et l'historique médical fournis par l'utilisateur pour offrir une évaluation préliminaire des conditions de santé. Il fournit des conseils personnalisés et des recommandations, guidant les utilisateurs sur les prochaines étapes à suivre, y compris quand consulter un professionnel de santé.",
            "disclaimer": "Ce service basé sur l'IA est uniquement à des fins informatives et ne remplace pas un outil ou un service médical. Il ne fournit ni diagnostics, ni traitements, ni prescriptions. Toutes les décisions et actions liées à la santé doivent être prises en consultation avec un professionnel de santé agréé. Les développeurs et propriétaires de cette application ne sont pas responsables des résultats de santé basés sur l'utilisation de cet outil. En cas d'urgence médicale, appelez le 112 ou votre service d'urgence local."
        },
        [officeTypes.ALTMED]: {
            "name": "Médecine Alternative",
            "description": "Un service de médecine alternative basé sur l'IA, proposant une gamme de traitements non traditionnels tels que l'acupuncture, la phytothérapie et l'homéopathie. Utilisant des algorithmes avancés, il fournit des recommandations personnalisées basées sur les principes des pratiques de guérison alternatives. Le service vise à compléter les traitements médicaux conventionnels en offrant des informations holistiques sur la santé et le bien-être de l'utilisateur.",
            "disclaimer": "Ce service de médecine alternative basé sur l'IA est destiné à des fins de bien-être général et informatives uniquement. Il ne remplace pas un diagnostic ou un traitement médical professionnel. Consultez toujours un professionnel de santé qualifié avant de prendre des décisions concernant votre santé. Les développeurs ne sont pas responsables des résultats de santé issus de l'utilisation de ce service."
        },
        [officeTypes.PSYCHO]: {
            "name": "Psychologie",
            "description": "Un service de conseil psychologique basé sur l'IA qui propose un soutien en matière de santé mentale en analysant les émotions, pensées et comportements des utilisateurs. Il offre des conseils personnalisés, des stratégies d'adaptation et des techniques d'amélioration de soi pour aider les utilisateurs à gérer le stress, l'anxiété et d'autres défis psychologiques. Ce service est conçu pour compléter la thérapie traditionnelle en offrant un soutien immédiat et accessible.",
            "disclaimer": "Ce service de conseil psychologique basé sur l'IA est conçu pour offrir un soutien général en matière de santé mentale et ne remplace pas un avis, un diagnostic ou un traitement psychologique ou psychiatrique professionnel. Pour des soins de santé mentale précis, consultez toujours un professionnel de santé mentale agréé. Si vous êtes en crise ou en situation d'urgence, contactez un service d'urgence local ou une ligne d'assistance psychologique. Les développeurs et propriétaires de cette application ne sont pas responsables des résultats liés à l'utilisation de ce service."
        },
        [officeTypes.VET]: {
            "name": "Animaux",
            "description": "Un service vétérinaire basé sur l'IA qui aide les propriétaires d'animaux à comprendre l'état de santé de leurs animaux en analysant les symptômes et l'historique médical fournis par les utilisateurs. Il propose des informations préliminaires sur les problèmes de santé potentiels, ainsi que des recommandations sur les prochaines étapes, telles que la prise de rendez-vous chez un vétérinaire ou la gestion des soins à domicile.",
            "disclaimer": "Ce service vétérinaire basé sur l'IA est conçu pour fournir des informations préliminaires sur la santé de votre animal en fonction des informations que vous fournissez. Il ne remplace pas les soins, diagnostics ou traitements vétérinaires professionnels. Pour des diagnostics et des plans de traitement précis, consultez toujours un vétérinaire agréé. En cas d'urgence avec votre animal, contactez votre service d'urgence vétérinaire local. Les développeurs et propriétaires de cette application ne sont pas responsables des résultats de santé de votre animal liés à l'utilisation de cet outil."
        },
        [officeTypes.TEACHER]: {
            "name": "Éducation",
            "description": "Un service de soutien éducatif basé sur l'IA qui propose du tutorat et de l'assistance dans une variété de matières, allant des mathématiques aux arts. Le service offre des plans d'apprentissage personnalisés et répond aux questions des utilisateurs, simulant les conseils d'éducateurs expérimentés. Il vise à améliorer les résultats d'apprentissage en offrant un soutien éducatif personnalisé.",
            "disclaimer": "Ce service éducatif basé sur l'IA est destiné à compléter l'éducation formelle. Il ne remplace pas les enseignants certifiés ou les programmes éducatifs accrédités. Les utilisateurs doivent consulter des professionnels pour obtenir une éducation formelle et des certifications. Les développeurs ne sont pas responsables des résultats académiques dérivés de ce service."
        },
        [officeTypes.BUSINESS]: {
            "name": "Affaires",
            "description": "Un service de conseil en affaires basé sur l'IA conçu pour fournir des informations et des stratégies aux entrepreneurs, startups et entreprises établies. En exploitant l'analyse de données, le service offre des conseils en marketing, opérations, finances et stratégies de croissance, simulant l'expertise d'un consultant en affaires expérimenté. Il aide les utilisateurs à relever les défis commerciaux avec des recommandations et des outils de planification basés sur l'IA.",
            "disclaimer": "Ce service de conseil en affaires basé sur l'IA est à des fins d'information et de planification stratégique uniquement. Il ne remplace pas les conseils financiers, juridiques ou opérationnels professionnels. Les utilisateurs doivent consulter des professionnels qualifiés pour toute décision commerciale critique. Les développeurs ne sont pas responsables des résultats commerciaux issus de l'utilisation de ce service."
        }
    },
    "consultants": {
        "title": "Consultants",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "Réceptionniste IA",
            "description": "Vous aide à choisir le bon spécialiste"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "Allergologue IA",
            "description": "Spécialiste dans le diagnostic et la gestion des allergies et des affections connexes"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "Cardiologue IA",
            "description": "Spécialiste de la santé cardiaque, diagnostic et traitement des maladies cardiaques"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "Pédiatre IA",
            "description": "Spécialiste de la santé des enfants, soins médicaux pour bébés et enfants"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "Réceptionniste Vétérinaire IA",
            "description": "Vous aide à choisir le bon spécialiste vétérinaire"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "Dentiste Vétérinaire IA",
            "description": "Spécialiste des soins dentaires et de la santé bucco-dentaire pour les animaux"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "Consultant en Feng Shui IA",
            "description": "Spécialiste en Feng Shui, offrant des conseils pour harmoniser votre espace afin d'améliorer le flux d'énergie"
        }
    },
    "consultationDetails": {
        "title": "Détails de la Consultation",
        "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer cette consultation ?",
        "deleteConsultation": "Supprimer",
        "resumeConsultation": "Reprendre",
        "restoreConsultation": "Restaurer",
        "restoreConfirmation": "Êtes-vous sûr de vouloir restaurer cette consultation ?"
    },
    "documentDetails": {
        "title": "Détails du Document",
        "manage": "Gérer le Document",
        "download": "Télécharger"
    },
    "documentManage": {
        "title": "Gérer le Document",
        "share": "Partager",
        "delete": "Supprimer",
        "restore": "Restaurer",
        "rename": "Renommer",
        "changeProfile": "Changer de personne associée",
        "download": "Télécharger",
        "documentDeleted": "Document supprimé {{date}}",
        "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer ce document ?",
        "restoreConfirmation": "Êtes-vous sûr de vouloir restaurer ce document ?"
    },
    "deleteConfirmation": {
        "title": "Voulez-vous vraiment supprimer cette consultation ?",
        "cancel": "Annuler",
        "delete": "Supprimer"
    },
    "restoreConfirmation": {
        "title": "Voulez-vous restaurer cette consultation ?",
        "cancel": "Annuler",
        "restore": "Restaurer"
    },
    "uploadOptions": {
        "takePhoto": "Prendre une Photo",
        "photoGallery": "Galerie de Photos",
        "uploadFile": "Télécharger un Document"
    },
    "languages": {
        "af": "Afrikaans",
        "ar": "Arabe",
        "az": "Azéri",
        "be": "Biélorusse",
        "bg": "Bulgare",
        "bn": "Bengali",
        "bs": "Bosniaque",
        "ca": "Catalan",
        "cs": "Tchèque",
        "da": "Danois",
        "de": "Allemand",
        "de-AT": "Allemand (Autriche)",
        "el": "Grec",
        "en": "Anglais",
        "en-GB": "Anglais (Royaume-Uni)",
        "en-US": "Anglais (États-Unis)",
        "es": "Espagnol",
        "et": "Estonien",
        "fa-IR": "Persan (Iran)",
        "fi": "Finnois",
        "fr": "Français",
        "fr-CA": "Français (Canada)",
        "fr-CH": "Français (Suisse)",
        "he": "Hébreu",
        "hi": "Hindi",
        "hu": "Hongrois",
        "is": "Islandais",
        "it": "Italien",
        "ja": "Japonais",
        "ko": "Coréen",
        "nl": "Néerlandais",
        "pl": "Polonais",
        "pt": "Portugais",
        "pt-BR": "Portugais (Brésil)",
        "ro": "Roumain",
        "ru": "Russe",
        "sv": "Suédois",
        "tr": "Turc",
        "uk": "Ukrainien",
        "vi": "Vietnamien",
        "zh-CN": "Chinois (Simplifié)",
        "zh-TW": "Chinois (Traditionnel)"
    }
};
