import api from './API.js';
import { topic } from './Topic.js';

/**
 * The photo storage instance holds a pairs of url and vali_till.
 * React useEffect may cause multiple image loading. To avoid repeative
 * GET requests actual url is a promise that is read immediately and
 * resolved only after actualimage is loaded.
 */
const store = {};
/**
 * Store will reuse the url promise for next LIFETIME milliseconds 
 * to save on GET queries 
 */
const LIFETIME = 30000;

const { pub: notify_updated, sub: on_update } = topic('profile-image-updated');

/**
 * Subscribe callback function to be clled when profile
 * photo in photo storage gets updated. Callback function
 * has only paremeterof profile_id whose picture is updated.
 * @param {*} updateCallack
 * @returns a function that cancel event subscription
 */
export { on_update };


/**
 * Returns photo of given profile_id.
 * @param {*} profile_id
 * @returns Promise resolved with URL (uri or blob) of a photo
 * of the profile_id given or rejected with error description.
 */
export async function get_photo_url(profile_id) {
    if(profile_id || (profile_id == 0)) {
        let photo_cache = store[profile_id]
            || (store[profile_id]={ valid_till: 0 });
        if(
            photo_cache.valid_till > Date.now()
            || photo_cache.valid_till === null
        ) return photo_cache.url;
        else return photo_cache.url = new Promise(
            (resolve, reject) => {
                photo_cache.valid_till = Date.now() + LIFETIME;
                api
                .loadPhoto(profile_id, (photo_cache || {}).etag)
                .then(x => {
                    if(x.status == 200) {
                        photo_cache.etag = x.headers.get('etag');
                        return x.blob();
                    } else
                        return Promise.resolve(photo_cache.blob)
                })
                .then(x => {
                    photo_cache.blob = x;
                    resolve(photo_cache.url = URL.createObjectURL(x))
                })
                .catch(err => reject(`Profile has no photo, ${err}`))
            }
        );
    } else return Promise.reject("Profile id undefied");
}

/**
 * Sets the URI of profile picture, e.g. for Google photos.
 * function is 'lightweight' and doesnot store anything on the server,
 * just update the cached value to be consumed by subsequent
 * calls of get_photo_url.
 * @param {*} profile_id
 * @param {*} photo_uri
 */
export async function set_photo_uri(profile_id, photo_uri) {
    store[profile_id] = {
        url: Promise.resolve(photo_uri)
        , valid_till: null
    };
    notify_updated(profile_id);
}

/**
 * Sets the blob url for profile_id given. A blob is created
 * from a file bytes received as result of user's upload.
 * This function is heavy, it stores the file a the server side.
 * @param {*} profile_id
 * @param {*} photo_file
 * @returns Promise resolved with true if photo is successfully
 * stored in server storage or rejected with error description.
 */
export async function set_photo_url(profile_id, photo_file) {
    store[profile_id] = {
        url: URL.createObjectURL(photo_file)
        , valid_till: Date.now() + LIFETIME
    };

    //NOTE: For new profile there is no real storage,
    //      so do not actually save image
    if(!profile_id) return Promise.resolve()
        .then(() => notify_updated(profile_id));

    return api.storePhoto(profile_id, photo_file)
        .then(() => notify_updated(profile_id))
        .catch(err => console.log("ERROR uploading profile photo: ", err))
}

/**
 * Delete the photo of given profile_id. Cached photo
 * is only eleted when successfully removed from server.
 * @param {*} profile_id
 * @returns Promise resolved when photo is deleted and
 * all delete notifictions are executed. Rejected when
 * error deletion appears.
 */
export async function delete_photo(profile_id) {
    //NOTE: For new profile there is no real storage,
    //      so clear only cache
    if(!profile_id) {
        delete store[profile_id];
        return Promise.resolve()
            .then(() => notify_updated(profile_id));
    }

    return api
        .deletePhoto(profile_id)
        .then(() => {
            store[profile_id] = {
                valid_till: Date.now() + LIFETIME
                , url: Promise.reject(`Profile photo is deleted`)
            }
            notify_updated(profile_id);
        });
}