import { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Fade, Badge, Avatar } from '@mui/material';
import { ArrowBackIos as ArrowBackIcon, ForkRight, InsertChartOutlinedSharp } from '@mui/icons-material';
import { useAuth } from '../components/AuthContext.js';
import { useAppContext } from '../components/AppContext.js';
import { useTranslation } from 'react-i18next';
import { ProfileIcon } from '../components/ProfileIcon.tsx';

const HeaderStandard = ({ headerRef, onBackButtonClick, onAvatarClick, onServiceLogoClick }) => {
    const { t } = useTranslation();
    const { backButtonVisibility, headerTitle, officeManager } = useAppContext();
    const { userCountry, currentProfileId } = useAppContext();

    const { isAuthenticated } = useAuth();
    const [office, setOffice] = useState();
    useEffect(
        () => {
            if(!isAuthenticated) return;
            setOffice(
                officeManager.getCurrentOffice(
                    userCountry
                )
            );
        }, [
            userCountry
            , isAuthenticated
        ]
    );

    return (
        <AppBar ref={headerRef} position="relative" sx={{ maxWidth: 'md', left: 0, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.7)', color: 'black', boxShadow: 'none' }}>
            <Toolbar sx={{ m: 0, px: 1, boxShadow: 'none', maxWidth: '100%' }}>
                <Fade in={backButtonVisibility} timeout={500}>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={onBackButtonClick} sx={{ ml: 1 }}>
                        <ArrowBackIcon />
                    </IconButton>
                </Fade>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h2" sx={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',         // Hide overflowed text
                        textOverflow: 'ellipsis',   // Show ellipsis when text overflows
                    }}>
                        {office ? t(`offices.${office.type}.name`) : null}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: -0.7, mb: 1, flexGrow: 1, textAlign: 'center' }}>
                        {headerTitle}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ProfileIcon
                        id={currentProfileId}
                        onClick={onAvatarClick}
                        sx={{
                            width: 40
                            , height: 40
                            , mr: 1
                        }}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default HeaderStandard;
