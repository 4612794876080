// WelcomeScreen.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../img/wixee-magistral.svg';
import { preloadImages } from '../utils/utils';
import { useAuth } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../config/AppModes.ts';

interface WelcomeScreenProps {
    setDirection: (direction: 'forward' | 'backward') => void;
    useDelayedNavigation: () => (navigationFn: () => void, path: string) => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ setDirection, useDelayedNavigation }) => {
    const setDelayedNavigation = useDelayedNavigation();

    const { t } = useTranslation();

    useEffect(() => {
        console.log(`Mount welcome screen: ${tokenVerificationCompleted} ${isAuthenticated}`);
        const images = [
            require('../img/splash/splash1bkg.webp'),
            require('../img/splash/splash2bkg.webp'),
            require('../img/splash/splash3bkg.webp'),
        ];
        preloadImages(images);
        return () => console.log('unmount welcome screen');
    }, []);

    const { isAuthenticated, tokenVerificationCompleted } = useAuth();
    const redirect_timeout = useRef<NodeJS.Timeout|undefined>();

    useEffect(() => {
        console.log(`some changes: ${tokenVerificationCompleted} ${isAuthenticated}`);
        if(!tokenVerificationCompleted) return; //NOTE: Login negotiation in progress
        clearTimeout(redirect_timeout.current);
        redirect_timeout.current = setTimeout(
            () => {
            console.log(`detected redirection: ${tokenVerificationCompleted} ${isAuthenticated}`);
            setDelayedNavigation(
                () => setDirection('forward')
                , isAuthenticated
                    ? AppRoutes.ROUTE_HOME.path
                    : AppRoutes.ROUTE_SPLASH1.path
            );}
            , isAuthenticated ? 0 : 500
        );
        return () => clearTimeout(redirect_timeout.current);
    }, [
        isAuthenticated
        , tokenVerificationCompleted
    ]);

    return (
        <Box sx={{
            // background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            background: 'rgba(255,255,255,0.5)',
            width: '100%',
            height: '100svh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0, m: 0,
            overflow: 'hidden',
        }}>
            <Box sx={{ pt: 20, pb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={logo} alt="Wixee Logo" style={{ width: '20rem', marginBottom: '16px' }} />
                {/* <Typography sx={{ pl: 8, pr: 8, textAlign: 'center', color: 'white', maxWidth: '24rem' }} variant="h1">
                    {t('welcome.title')}
                </Typography> */}
            </Box>
        </Box>
    );
};

export default WelcomeScreen;
