import api from './API.js';

/**
 * Profiles cache. That is a module variables,
 * so instantiated only at first module include.
 * The promise resolver function is used to initialize
 * cache when the auth token first available. Immeditely
 * at an attempt to load profiles the resolver variable
 * is reset to avoid second, third an so on initialization
 * calls
 */

class Cache {
    constructor() {
        //NOTE: capture api lock resolver here when api gts authorized
        this.authorize = () => {};
        //NOTE: creates new api lock 
        const make_lock = cache => (
            new Promise(
                (resolve, reject) => {
                    cache.authorize = resolve;
                }
            )
        );
        //NOTE: create a lock to wait for api 
        this.has_auth = make_lock(this);
        this.invalidate();
        //NOTE: open api lock on HMR-events
        if(api.is_valid) this.authorize();
        //NOTE: open the api lock on tocken validation to update cache
        api.on_authorized((cache => () => {
            cache.authorize();
        })(this));
        //NOTE: reset cache and api lock on logout
        api.on_unauthorized((cache => () => {
            cache.has_auth = make_lock(cache);
            cache.invalidate();
        })(this));
    }

    invalidate() {
        this.promise = new Promise(
            resolve => {
                this.resolver = resolve;
            }
        );
    }

    actualize() {
        this.has_auth
        .then(() => {
            if(this.resolver) {
                api.allProfiles()
                .then(
                    (r => x => {
                        r(x);
                    })(this.resolver)
                );
                this.resolver = null;
            }
        });
    }

    profiles() {
        this.actualize();
        return this.promise;
    }
}

const cache_ = new Cache();

export function useProfile() {
    return {
        allProfiles: () => cache_.profiles()
        , getProfile: (id) => cache_.profiles()
            .then(profiles => profiles.reduce(
                (r, y) => (y.id == id)
                    ? y
                    : r
                , null
            ))
        , saveProfile: (p) => {
            return api
                .saveProfile(p)
                .then(x => {
                    cache_.invalidate();
                    return x;
                });
        }
        , newProfile: (p) => {
            return api
                .newProfile(p)
                .then(x => {
                    cache_.invalidate();
                    return x;
                });
        }
        , deleteProfile: (id) => {
            return api
                .deleteProfile(id)
                .then(x => {
                    cache_.invalidate();
                    return x;
                });
        }
        , get_name: (id) => cache_.profiles()
            .then(profiles => profiles.reduce(
                (r, y) => (y.id == id && !!y.name)
                    ? y.name
                    : r
                , ""
            ))
    }
}

export function getAllProfilesSummary() {
    return api.getAllProfilesSummary();
} 
