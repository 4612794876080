const terms = ` 

## 1. Introduction

Welcome to Wixee, a digital platform that provides AI-driven simulations of experts in various ﬁelds related to health and well-being, such as medicine, alternative medicine, psychology, veterinary care, horoscopes, astrology, and talismans. Wixee is available on web, iOS, and Android platforms, oﬀering users a combination of free and paid modules, depending on their preferences and needs.

The application is owned and operated by **WIXEELABS DOO**, a limited liability company registered in Belgrade, Serbia. Our oﬃcial business registration number is 22026127, and we are located at Jurija Gagarina 231, Local 329, Novi Beograd, 11070, Serbia. WIXEELABS DOO is engaged in computer programming activities, with a registered tax identiﬁcation number (PIB) of 114453924\.

Wixee is designed to handle and process personal, medical, and non-personal data in compliance with international standards such as **GDPR** (General Data Protection Regulation), **LGPD** (Lei Geral de Proteção de Dados), and **HIPAA** (Health Insurance Portability and Accountability Act). We utilize secure protocols, including **SSL** and **TLS**, to ensure the safe transmission of user data. Our platform also adheres to **WCAG** and **ADA** standards for content accessibility.

Please note that Wixee is not a medical service or device. The information provided within the application is for reference purposes only and should not be considered a substitute for professional medical advice, diagnosis, or treatment. All health-related decisions should be made in consultation with a licensed medical professional. WIXEELABS DOO and its employees are not liable for any actions taken based on the information provided by the application.

By using Wixee, users agree to the terms and conditions set forth in this document. We recommend reviewing these terms carefully before using the application, as they outline the rights and obligations of both the user and WIXEELABS DOO.

If you have any questions or concerns, you can reach us at our oﬃcial email address: [info@wixee.ai](mailto:info@wixee.ai)

## 2. Deﬁnitions

For the purpose of these Terms and Conditions, the following deﬁnitions apply:

1. User (“You”)

   Refers to any individual who accesses, uses, or interacts with the Wixee application, whether on a free or paid basis.

2. WIXEELABS DOO

   Refers to WIXEELABS DOO, a limited liability company registered in Belgrade, Serbia, with business registration number 22026127, responsible for the development, operation, and maintenance of the Wixee application.

3. Application

   Refers to the Wixee application available on web, iOS, and Android platforms, developed and operated by WIXEELABS DOO, Belgrade, Serbia.

4. Artiﬁcial Intelligence (AI)

   Artiﬁcial Intelligence (AI) refers to technologies and systems designed to perform tasks that typically require human intelligence, such as speech recognition, natural language processing, machine learning, data analysis, and the generation of text or images. In the Wixee application, AI is used to create expert simulations, generate recommendations, and analyze user-provided data. AI systems may operate on the user’s local device or through external AI services that interact with user data.

5. Personal Data

   Any information that relates to an identiﬁed or identiﬁable individual. This includes, but is not limited to, name, surname, email address, login credentials (username and password), and IP address.

6. Medical Data

   Any data related to the health or medical history of a user, including but not limited to height, weight, medical test results, medical consultations, diagnoses, treatment plans, and health records. These data are processed only with the explicit consent of the user.

7. Non-Personal Data

   Any information that does not identify an individual, including but not limited to country of residence, language preferences, birth date, birth location, and consultation protocols (except for medical consultations).

8. Local Device

   The user’s personal device (e.g., smartphone, tablet, or computer) where the Wixee application is installed and accessed. Some data may be stored or processed locally on the user’s device to ensure privacy and functionality, depending on the conﬁguration and features used.

9. Application Servers

   Secure servers controlled by WIXEELABS DOO or its authorized partners that store and process user data necessary for account management, content delivery, and application functionality. These servers handle the processing of personal, non-personal, and medical data as required by the app’s services.

10. External AI Services

    Third-party services, including AI-based systems for text analysis, text generation, language translation, speech recognition, speech generation, and image recognition, which are integrated into the Wixee application. The availability and quality of these services may vary, and WIXEELABS DOO is not responsible for their performance.

11. Subscription

    A paid plan or service provided by Wixee that grants users access to premium features and modules of the application. Subscriptions automatically renew unless canceled by the user.

12. Free Features

    The limited functionality or modules available to users without a paid subscription. These features may be subject to usage limits and other restrictions.

13. Paid Features

    Modules or functionalities within the application that are accessible only to users with an active subscription.

14. Medical Disclaimer

    A statement in the application clarifying that Wixee does not provide medical advice, diagnoses, treatment, or prescriptions, and the information provided is for reference purposes only. Users must consult with licensed medical professionals for any health-related decisions.

15. Data Processing

    The collection, storage, and use of personal, medical, and non-personal data within the app, which may occur on the user’s Local Device, Application Servers, or External AI Services as speciﬁed.

16. Data Anonymization

    The process of removing personally identiﬁable information from medical data to ensure that it cannot be traced back to a speciﬁc individual.

17. Consent

    The explicit permission given by the user to WIXEELABS DOO for the processing of their personal and medical data within the app. Consent may be revoked at any time by the user.

18. Intellectual Property

    Refers to the content, software, trademarks, and other proprietary materials owned by WIXEELABS DOO, which are protected by copyright and other applicable laws.

19. User Account

    The unique proﬁle created by the user when registering to use the Wixee application, which includes login credentials and user-speciﬁc data.

20. Account Deletion

    The process by which a user can permanently delete their account and all associated data from the application. Account deletion may result in the loss of access to both free and paid features.

21. Applicable Law

    The legal framework that governs the use of the application, which in this case is the law of the Republic of Serbia, unless otherwise speciﬁed.

## 3. User Eligibility

1. Legal Age and Ability to Consent

   By accessing and using the Wixee application, you conﬁrm that you are at least 18 years old or the age of majority in your jurisdiction, whichever is higher, and have the legal capacity to enter into and comply with these Terms and Conditions. If you are under the age of 18 or the applicable age of majority, you may not use the application without the explicit consent and supervision of a parent or legal guardian who agrees to be bound by these Terms and Conditions.

2. Geographic Limitations

   The Wixee application is intended for use only in countries where such applications and services are legally permitted. It is your responsibility to ensure that your use of the application complies with the laws and regulations of your country or region of residence. By using the app, you conﬁrm that accessing and utilizing the services provided by Wixee does not violate any local, national, or international laws applicable to you.

   WIXEELABS DOO does not guarantee that the application or its content is appropriate for use in any speciﬁc country or region. We reserve the right to restrict or prohibit the use of the application in countries or regions where the services may not comply with applicable legal frameworks.

3. User Responsibilities for Compliance with Applicable Laws

   As a user, you are solely responsible for ensuring that your use of the Wixee application adheres to all applicable local, national, and international laws, regulations, and guidelines. This includes, but is not limited to, laws governing data privacy, health services, and the use of AI-based applications.

   You must refrain from using the application in any manner that could result in violation of any applicable laws or infringe on the rights of others. This includes but is not limited to:

   * Misuse of the application for illegal or unauthorized purposes  
   * Circumventing any security measures implemented within the app  
   * Falsifying information or impersonating another person or entity

4. Suspension rights

   WIXEELABS DOO reserves the right to suspend or terminate access to the application for users who violate these Terms and Conditions or applicable laws. Furthermore, users who engage in illegal activities using the application are subject to prosecution under the relevant legal framework.

## 4. Data Collection and Processing

1. Data Categories

   The Wixee application collects various types of data from users in order to provide personalized services, including the use of artiﬁcial intelligence for expert simulations. The types of data collected include:

   a. Personal Data

      * Name and surname  
      * Email address  
      * Login credentials (username and password)  
      * IP address  
      * Other contact details provided during account creation or service use

   b. Medical Data

      * Height and weight  
      * Health metrics, test results, and medical reports  
      * Medical history and consultations  
      * Health-related feedback and user input  
      * Other sensitive health-related information that may be voluntarily provided by the user

   c. Non-Personal Data

      * Country of residence  
      * Preferred language  
      * Date and time of birth  
      * Place of birth  
      * Consultation logs, excluding medical consultations

2. Data Collection Purpose

   Data is collected in order to:

   * Personalize the user experience and provide tailored consultations  
   * Enable functionality related to health and well-being simulations (e.g., astrology, medical guidance, psychological support)  
   * Analyze user data using AI-driven models to provide relevant advice and recommendations  
   * Ensure compliance with applicable data protection regulations  
   * Improve the application’s features, user interface, and performance

3. Data Processing Purpose

   The data collected by Wixee is processed as follows:

   * Personal Data is used to identify users, manage accounts, provide customer support, and send notiﬁcations related to the app’s services.  
   * Medical Data is processed only with the user’s explicit consent and is anonymized when shared with External AI Services for further analysis. The anonymization process ensures that sensitive medical information cannot be linked back to an identiﬁable individual.  
   * Non-Personal Data is used to improve general user experience, ensure compliance with legal requirements, and facilitate interactions with External AI Services for simulations and analyses.

4. Data Processing Policy

   Data processing occurs in the following locations:

   * Local Device

     Non-Personal Data, Personal Data, and Medical Data may be processed and stored on the user’s local device, ensuring privacy and minimal data transfer. Personal Data and Medical Data should be encrypted while network transfer and data storage.

   * Application Servers

     Non-Personal data, Personal data, Medical data (if allowed by the user) may be processed and stored on secure application servers controlled by WIXEELABS DOO for purposes such as account management, data storage, and feature provision. Personal Data and Medical Data should be encrypted while network transfer and data storage.

   * External AI Services

     Non-Personal Data and anonymized Medical Data (if allowed by the user) may be sent for processing to External AI Services for analysis, text generation, language translation, and other related functionalities. All Data should be encrypted while network transfer. Data processing at External AI Services is regulated by their data processing policies.

5. Anonymization of Medical Data

   All medical data processed by Wixee and shared with External AI Services is anonymized. This means that any personally identiﬁable information (such as name or contact details) is removed, and the data is transformed in such a way that it cannot be traced back to an individual. This ensures compliance with privacy regulations and protects users’ sensitive health information.

6. Permission and Consent for Data Processing

   Users must provide explicit consent for the processing of their personal and medical data. Upon account registration and when inputting sensitive data, users will be prompted to grant or deny consent for data collection and processing. Users have the right to withdraw consent at any time, which will result in the cessation of data processing activities and the deletion of all personal and medical data upon request.

7. External AI Services and Their Role in Data Processing

   Wixee relies on third-party AI-driven systems to analyze user data and provide simulations, predictions, and advice. These External AI Services handle tasks such as:

   * Text analysis and generation (e.g., generating consultation summaries)  
   * Language translation  
   * Speech recognition and synthesis  
   * Image recognition and generation for speciﬁc features

8. WIXEELABS DOO takes measures to ensure that these External AI Services comply with data protection standards. However, the availability, quality, and performance of these systems are not guaranteed, and WIXEELABS DOO assumes no liability for any issues related to External AI Services use.

## 5. User Consent

1. Consent for Personal and Medical Data Processing  
   By using the Wixee application, users must provide explicit consent for the collection and processing of their personal and medical data. Consent is obtained during the account registration process and whenever users input sensitive information, particularly medical data. By providing consent, users agree to the following:

   * Personal Data

     Consent allows Wixee to process personal information (such as name, email, and login credentials) for account management, customer support, and communications.

   * Medical Data

     Consent enables the collection and processing of sensitive medical information (such as height, weight, health history, and consultations). This data is processed for the purpose of providing personalized health-related insights and simulations through AI-driven models.

   * Non-Personal Data

     Consent allows the processing of non-personal data (such as country of residence and language preferences) to enhance the app’s functionality and user experience.

2. Accept of Consent

   Users are required to actively accept these terms by acknowledging consent prompts within the app. If consent is not granted, access to certain features, particularly those involving medical or sensitive data, may be restricted.

3. Withdrawal of Consent and Data Deletion Procedures

   Users have the right to withdraw their consent for the processing of personal and medical data at any time. The withdrawal of consent will result in the immediate cessation of data processing and can be requested through the following procedures:

   a. Withdrawing Consent

      Users can withdraw consent by accessing their account settings within the application and selecting the appropriate option to revoke permission for data processing. Upon withdrawal, the application will no longer collect or process the user’s personal or medical data, and users may lose access to certain features or services that rely on such data.

   b. Data Deletion Requests

   In addition to withdrawing consent, users may request the permanent deletion of all personal, medical, and non-personal data from Wixee’s systems. To initiate a data deletion request, users can:

      * Access the “Data Deletion” option in their account settings within the app  
      * Contact customer support at [info@wixee.ai](mailto:info@wixee.ai) to submit a formal request for data deletion

   Upon receipt of the request, WIXEELABS DOO will:

      * Remove all personal and medical data from its application servers  
      * Ensure that any data stored on local devices or External AI Services is anonymized or deleted  
      * Conﬁrm the data deletion with the user within a reasonable timeframe

   c. Consequences of Withdrawal and Data Deletion

      * Users who withdraw consent or delete their data will lose access to certain functionalities of the app, including personalized simulations or consultations.  
      * Withdrawal of consent and data deletion does not aﬀect the legality of data processing that occurred prior to the withdrawal or deletion request.  
      * Data deletion is irreversible. Once the user’s data has been permanently deleted, it cannot be recovered, and users must create a new account to use the application again, subject to renewed consent.

   d. Retention of Anonymized Data

      In some cases, WIXEELABS DOO may retain anonymized, non-identiﬁable data for statistical or analytical purposes, in compliance with data protection regulations. This data will not be linked to any individual and will not be subject to the same consent rules as personal or medical data.

## 6. Use of the Application

1. Description of Free and Paid Features

   The Wixee application oﬀers both free and paid features to users.

   a. Free Features

      Free features include limited access to certain simulations, basic consultations, and general wellness tools. These features are subject to usage limitations and may oﬀer restricted functionality compared to paid services. Users can access free features without a subscription, but they may experience limited responses or functionality based on their interaction.

   b. Paid Features

      Paid features unlock full access to advanced simulations, personalized consultations, and in-depth analysis tools for various wellness and health-related areas. These features are available through a subscription model, providing additional beneﬁts such as detailed reports, priority access to External AI Services, and enhanced AI-driven insights.

2. Subscription Model, Including Automatic Renewal

   Wixee operates on a subscription-based model for paid features, with the following options:

      * Subscriptions can be purchased on a monthly, quarterly, or annual basis.  
      * By subscribing, users agree to automatic renewal of their subscription at the end of each billing cycle unless they choose to cancel. Subscription fees will be charged to the payment method provided by the user.  
      * Prices for subscriptions may vary depending on the region, and WIXEELABS DOO reserves the right to adjust prices, with prior notice provided to users.

3. Cancellation of Subscriptions and Consequences

   Users may cancel their subscription at any time through the app’s account settings or via the respective platform’s subscription management tools (e.g., application Store or Google Play).

   a. Cancellation Process

      * Upon cancellation, users will continue to have access to paid features until the end of the current billing cycle, after which access to premium services will be terminated.  
      * No refunds will be provided for the remaining period of the subscription after cancellation unless required by applicable law.

   b. Consequences of Cancellation

      * After the cancellation takes eﬀect, users will retain access to the free features of the application but will lose access to all paid features and services.  
      * Users may renew a subscription at any time to regain access to the premium content and services.

4. User Responsibility for Verifying the Information Provided by the App

   The Wixee application is designed to oﬀer guidance and information in areas such as wellness, health, and lifestyle. However, the information provided by the app, including AI-generated simulations, is for informational purposes only and should not be used as a substitute for professional advice.

      * User’s Obligation

        Users are responsible for verifying the accuracy, completeness, and relevance of the information provided by the app, especially when making decisions related to health and well-being.

      * Consultation with Professionals

        Users should always consult with licensed professionals (e.g., medical doctors, psychologists) before taking any action based on the app’s guidance.

5. Limitations on Usage

   The use of the Wixee application is subject to the following limitations:

   a. Legal Compliance

      Users must use the application in a manner that complies with all applicable local, national, and international laws. Any use of the application for unlawful purposes is strictly prohibited.

   b. Appropriate Use

      Users are prohibited from using the application in ways that may cause harm to themselves or others.

      * Users may not use the application to distribute false or misleading information, engage in fraudulent activities, or violate the rights of other users.  
      * Users are prohibited from circumventing any security measures or exploiting vulnerabilities in the app.

   c. Prohibited Activities

      Users must not:

      * Reverse-engineer, decompile, or attempt to derive the source code of the app.  
      * Use the application for any activity that could disrupt or interfere with its operation or with the data and security of other users.

6. Availability and Quality of External AI Services

   Wixee relies on External AI Services for certain features, including but not limited to:

   * Text Generation and Analysis for AI-driven insights and suggestions based on user input.  
   * Speech Recognition and Generation for voice-based input and output functionalities.  
   * Image Recognition and Generation for AI-based tools to Interpret and generate visual data.

7. While Wixee endeavors to ensure the availability and quality of these External AI Services, WIXEELABS DOO:

   * Does not guarantee the uninterrupted availability, accuracy, or quality of External AI Services, as they are dependent on third-party providers.  
   * Disclaims responsibility for any errors, omissions, or delays resulting from the use of these External AI Services.  
   * Reserves the right to modify, discontinue, or replace External AI Services at any time without prior notice to users.

8. Users acknowledge that the performance of these services may vary based on factors such as network connectivity, location, and the capacity of third-party service providers. WIXEELABS DOO cannot be held liable for any disruptions or inaccuracies in the services provided by these External AI Services.

## 7. Disclaimers

1. App’s Role as an Informational Tool

   The Wixee application is designed as an informational tool to provide guidance and simulations in areas such as health, wellness, and well-being. The content provided by the app, including AI-generated consultations, is intended for general informational purposes only. Wixee is not a medical service or a medical device and should not be relied upon as such. The application does not oﬀer professional medical, psychological, or veterinary advice.

2. No Diagnosis, Treatment, or Prescriptions Provided

   The Wixee application does not provide any form of medical diagnosis, treatment, or prescriptions. The information and insights generated by the app, including recommendations based on user input, are not a substitute for professional medical evaluations, diagnoses, or treatments by licensed healthcare providers. Users should not use the application to self-diagnose or self-treat any medical condition or health issue.

3. Liability Disclaimer for Use of Information Provided by the App

   WIXEELABS DOO and its employees are not liable for any damages, losses, or consequences arising from the use of the information or guidance provided by the Wixee application. This includes any potential errors, inaccuracies, or omissions in the AI-generated insights or any third-party content available through the app. By using the app, users acknowledge that:

   * The information provided may not be accurate, up-to-date, or relevant to their speciﬁc situation.  
   * Any reliance on the app’s information or simulations is at the user’s own risk.  
   * WIXEELABS DOO does not warrant the suitability or reliability of the content for any particular purpose, including health-related decisions.

4. Responsibility of Users to Consult Licensed Professionals

   Users are solely responsible for making decisions related to their health, well-being, and medical care. The Wixee application does not replace consultations with licensed medical professionals, such as doctors, psychologists, or veterinarians. Users must consult qualiﬁed healthcare providers before taking any action based on the information or simulations provided by the app, particularly when it concerns medical conditions, treatment plans, or changes in medication.

   WIXEELABS DOO emphasizes the following:

   * Users should seek professional advice for any medical concerns or emergencies.  
   * Users must not disregard or delay seeking medical advice based on information obtained from the app.  
   * The responsibility for any health-related decisions rests entirely with the user, and Wixee does not assume any liability for actions taken based on its content.

## 8. Limitation of Liability

1. WIXEELABS DOO’s Liability Exclusions

   To the fullest extent permitted by law, WIXEELABS DOO, its oﬃcers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to:

   * Loss of proﬁts  
   * Loss of data  
   * Personal injury  
   * Emotional distress  
   * Any other losses or damages resulting from the use or inability to use the Wixee application.

2. This limitation of liability applies regardless of whether such damages arise from contract, tort (including negligence), strict liability, or any other legal theory, and whether or not WIXEELABS DOO was advised of the possibility of such damages. WIXEELABS DOO is not responsible for:

   * Any inaccuracies or errors in the information provided by the app.  
   * Any consequences of actions taken based on information or guidance received through the app.  
   * The loss or unauthorized use of user data, unless caused by gross negligence or willful misconduct by WIXEELABS DOO.

3. Responsibility for the Use of External AI Services and Their Accuracy

   Wixee integrates with third-party AI services for tasks such as text generation, speech recognition, and data analysis. These External AI Services are provided by independent third-party vendors, and WIXEELABS DOO cannot guarantee their accuracy, availability, or performance.

   WIXEELABS DOO assumes no responsibility for:

   * The correctness, reliability, or relevance of the information generated by these External AI Services.  
   * Any interruptions, delays, or errors caused by these External AI Services.  
   * Any damages or losses arising from the use of External AI Services.

4. Users acknowledge that External AI Services may provide recommendations or analyses that are not suitable for their speciﬁc needs or circumstances, and users are responsible for verifying the accuracy and appropriateness of any information or advice generated by these services.

5. Disclaimer on Legal and Health Outcomes from application Usage

   WIXEELABS DOO disclaims any responsibility or liability for:

   * Legal consequences

     Users are responsible for ensuring that their use of the application complies with all applicable local, national, and international laws. WIXEELABS DOO is not liable for any legal issues that may arise from the use of the application in jurisdictions where such services are restricted or illegal.

   * Health outcomes

     The application does not provide medical diagnoses, treatment, or prescriptions, and users must not rely on the application as a substitute for professional medical advice. Any decisions regarding health or wellness based on information from the application are made at the user’s own risk, and WIXEELABS DOO is not liable for any negative health outcomes resulting from reliance on the app’s content.

6. By using the Wixee application, users agree to hold WIXEELABS DOO harmless from any claims, damages, or losses resulting from the use of the app, including legal disputes, health-related issues, or adverse outcomes associated with actions taken based on app-provided information.

## 9. Data Security and Protection

1. Compliance with GDPR, LGPD, HIPAA, SSL, and TLS Standards

   WIXEELABS DOO is committed to protecting user data and complies with the following data protection and security standards:

   * GDPR (General Data Protection Regulation) \- ensures the protection of personal data for users within the European Union.  
   * LGPD (Lei Geral de Proteção de Dados) \- governs the processing of personal data in Brazil.

   * HIPAA (Health Insurance Portability and Accountability Act) \- ensures the protection and privacy of medical data, particularly for users in the United States.  
   * SSL (Secure Socket Layer) and TLS (Transport Layer Security) \- industry-standard encryption protocols used to secure data transmissions between the app, its application servers, and External AI Services.

2. These compliance measures are designed to protect the privacy, conﬁdentiality, and integrity of user data, ensuring that personal and medical data is handled responsibly and securely.

3. Data Transmission Security

   All data transmitted between the user’s device, WIXEELABS DOO application servers, and External AI Services is protected using SSL/TLS encryption. This encryption helps prevent unauthorized access or interception of data during transmission, ensuring that personal and medical information remains conﬁdential and secure. Users are encouraged to ensure their Internet connection is secure (e.g., using trusted networks) when accessing the app.

4. Responsibility of the User for Securing Their Account Credentials

   Users are responsible for maintaining the conﬁdentiality of their account credentials, including usernames and passwords. WIXEELABS DOO strongly advises users to:

   * Use strong, unique passwords for their accounts.  
   * Regularly update passwords and avoid reusing them across diﬀerent services.  
   * Avoid sharing their account information with others.  
   * Log out from the application when using shared or public devices.

5. If a user suspects that their account has been compromised, they should immediately notify WIXEELABS DOO at [info@wixee.ai](mailto:info@wixee.ai) to take corrective actions, such as resetting the account password or securing the account.

6. Measures Taken to Protect Personal and Medical Data

   WIXEELABS DOO employs a variety of technical, organizational, and administrative measures to protect user data from unauthorized access, alteration, disclosure, or destruction. These measures include:

   * Data Encryption

     Personal and medical data is encrypted both during transmission (via SSL/TLS) and when stored on WIXEELABS DOO’s application servers to ensure conﬁdentiality and protection against unauthorized access.

   * Access Controls

     Strict access control policies limit the number of personnel who have access to sensitive data, ensuring that only authorized individuals within the organization can access personal and medical information.

   * Anonymization

     Medical data is anonymized where possible, especially when shared with External AI Services, ensuring that it cannot be traced back to an identiﬁable individual.

   * Regular Audits and Security Reviews

     WIXEELABS DOO conducts regular security audits and assessments to identify potential vulnerabilities and ensure that its systems are up to date with the latest security standards and practices.

   * Data Retention and Deletion Policies

     User data is retained only as long as necessary to provide services or as required by applicable laws. Upon user request or account deletion, personal and medical data is securely deleted from WIXEELABS DOO’s systems.

7. Despite the security measures implemented by WIXEELABS DOO, no system is entirely immune to potential threats. Therefore, users are encouraged to remain vigilant about their own security practices, such as updating software and enabling two-factor authentication (if available).

## 10. Intellectual Property

1. Ownership of Content Within the Application

   All content, materials, and features available within the Wixee application, including but not limited to text, graphics, logos, icons, images, audio, video, software, AI-driven outputs, and interactive features, are the property of WIXEELABS DOO or its licensors and partners. This includes any proprietary algorithms or models used to generate simulations, consultations, and recommendations.

   All rights, title, and interest in the application and its content are protected by international copyright laws, trademark laws, and other applicable intellectual property laws. Users do not obtain any ownership rights in the content or materials provided through the application by using or accessing the application.

2. Restrictions on Use and Distribution of application Content

   Users are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the Wixee application and its content solely for personal, non-commercial purposes, in accordance with these Terms and Conditions.

   Users are prohibited from:

    * Reproducing, copying, modifying, distributing, transmitting, publicly displaying, or creating derivative works from any content within the application without the express written permission of WIXEELABS DOO.  
    * Using the content for any commercial purpose, including but not limited to reselling or licensing any materials or simulations provided by the app.  
    * Attempting to reverse-engineer, decompile, or disassemble any software or proprietary algorithms used within the app.  
    * Using any automated systems (e.g., bots, scrapers) to access or extract data from the application without the prior consent of WIXEELABS DOO.  
    * Removing or altering any copyright, trademark, or other proprietary notices displayed within the application.

3. Any unauthorized use of the app’s content may result in the termination of the user’s account and legal action to protect the intellectual property rights of WIXEELABS DOO.

4. Intellectual Property Rights of WIXEELABS DOO and Its Partners

   All trademarks, service marks, logos, and trade names displayed within the Wixee application are the intellectual property of WIXEELABS DOO or its partners. These marks may not be used, reproduced, or modiﬁed without prior written permission from WIXEELABS DOO.

   The AI models, databases, user interfaces, and all software technologies powering the Wixee application are proprietary technologies owned by WIXEELABS DOO or licensed from third-party partners. Users agree not to infringe, copy, or misuse any intellectual property associated with these technologies.

   Some content or services provided within the application may originate from third-party partners or service providers. Users acknowledge that these third parties may have additional intellectual property rights that are protected by law, and any misuse of third-party content is strictly prohibited.

   Any feedback, suggestions, or ideas submitted by users to WIXEELABS DOO regarding the improvement of the application or its features will become the sole property of WIXEELABS DOO. Users acknowledge that they have no right to compensation or recognition for any such feedback or ideas, and WIXEELABS DOO may use these submissions at its discretion.

## 11. Termination and Suspension

1. Conditions for Terminating a User Account or Suspending Services

   WIXEELABS DOO reserves the right to terminate or suspend a user’s account or access to the Wixee application at its discretion, with or without notice, under the following conditions:

   a. Violation of Terms

      If the user violates any provision of these Terms and Conditions, including but not limited to improper use of the app, intellectual property violations, or attempts to circumvent security measures.

   b. Unlawful Activity

      If WIXEELABS DOO has reasonable grounds to believe the user is engaging in illegal activities or using the application in a way that violates applicable laws or regulations.

   c. Fraudulent Behavior

      If there is evidence of fraud, misuse of account information, or other activities that may harm WIXEELABS DOO, its users, or third-party partners.

   d. Technical or Security Issues

      If unexpected technical issues or security threats arise that require suspension or termination to protect the integrity of the platform or its users.

      In cases of suspension or termination, WIXEELABS DOO will, where possible, notify the user of the issue and give an opportunity to rectify the violation, unless immediate action is required to prevent harm or further violation of the Terms.

2. User’s Ability to Delete Their Account and Associated Data

   Users may choose to delete their account and associated data at any time through the following methods:

   a. Account Deletion Process

      Users can initiate the deletion of their account by accessing the account settings in the Wixee application and selecting the “Delete Account” option.

      Once the deletion request is conﬁrmed, WIXEELABS DOO will permanently remove all personal, medical, and non-personal data associated with the user’s account from its application servers. This action is irreversible, and users will lose access to all services, including any paid features.

   b. Data Deletion

      In compliance with GDPR, LGPD, and other applicable data protection laws, upon account deletion, all personal data will be erased, and any remaining anonymized data will no longer be associated with the user. Users can also request full data deletion by contacting WIXEELABS DOO via [info@wixee.ai](mailto:info@wixee.ai) email.

   c. Consequences of Account Deletion

      Users will no longer have access to their account, paid features, or any saved data once the deletion is processed.

      If the user wishes to return to the application after deletion, they will need to create a new account, with no access to previously saved data or features.

3. Refund Policies for Subscriptions

   WIXEELABS DOO oﬀers paid subscription services for access to premium features within the Wixee application. Refunds for subscriptions are subject to the following policies:

   a. No Refund for Current Billing Cycle

      Upon cancellation of a subscription, users will continue to have access to paid features until the end of the current billing cycle. No refunds or partial refunds will be issued for unused portions of the subscription period unless required by local law.

   b. Refunds for Technical Issues

      If a user experiences signiﬁcant technical issues or interruptions that prevent access to paid features for an extended period, WIXEELABS DOO may, at its discretion, issue a prorated refund or extend the subscription period to compensate for the disruption. Users must notify WIXEELABS DOO of such issues within a reasonable timeframe to be eligible for compensation.

   c. Refunds for Legal Requirements

      In certain jurisdictions, refund policies are subject to local consumer protection laws. WIXEELABS DOO will comply with applicable legal requirements regarding subscription refunds.

   d. How to Request a Refund

      Refund requests must be submitted by contacting WIXEELABS DOO at [info@wixee.ai](mailto:info@wixee.ai) email. Each refund request will be evaluated on a case-by-case basis, and users may be required to provide evidence of technical issues or other grounds for requesting a refund.

## 12. Governing Law

1. Applicable Law

   These Terms and Conditions, as well as any disputes or claims arising out of or in connection with their interpretation, formation, execution, or breach, shall be governed by and construed in accordance with the laws of the Republic of Serbia, without regard to its conﬂict of law principles.

2. Jurisdiction for Any Disputes

   Any disputes arising from the use of the Wixee application or related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts located in Belgrade, Serbia. By using the Wixee application, you agree to submit to the jurisdiction of these courts for the resolution of any legal disputes.

   In the event of a legal dispute, WIXEELABS DOO and the user will make reasonable eﬀorts to resolve the matter amicably through negotiation before pursuing formal legal action. Should such negotiations fail, the courts of Belgrade, Serbia, shall have exclusive jurisdiction over the matter.

## 13. Amendments to the Terms

1. Right to Update or Modify the Terms

   WIXEELABS DOO reserves the right to update, modify, or revise these Terms and Conditions at any time. Changes may be made to reﬂect updates in the law, improvements to the app, changes in business practices, or for any other reason deemed necessary by WIXEELABS DOO.

   Modiﬁcations to the Terms will become eﬀective immediately upon being posted, unless otherwise speciﬁed. Continued use of the Wixee application after any changes are made constitutes acceptance of the updated Terms.

2. How Users Will Be Notiﬁed of Changes

   In the event of signiﬁcant changes to these Terms and Conditions, WIXEELABS DOO will provide reasonable notice to users. Notiﬁcations may include:

   * In-application Notiﬁcations: prompts or alerts within the application itself notifying users of updated Terms.  
   * Email Notiﬁcation: if required, an email may be sent to users registered with the application to inform them of changes.  
   * Posting of Updated Terms: the latest version of the Terms will always be available in the application and on the oﬃcial Wixee website.

3. User Responsibility to Review Updated Terms

   It is the responsibility of the user to regularly review these Terms and Conditions for any updates or modiﬁcations. By continuing to use the Wixee application after the updated Terms have been posted, the user agrees to be bound by the revised Terms. If a user does not agree with the updated Terms, they must discontinue the use of the Wixee application and, if desired, delete their account. Failure to discontinue use after changes have been posted will be considered acceptance of the updated Terms.

## 14. Contact Information

If you have any questions, concerns, or inquiries regarding these Terms and Conditions, the Wixee application, or any other aspect of our services, you can reach WIXEELABS DOO through the following contact details:

* Email: [info@wixee.ai](mailto:info@wixee.ai)

* Postal Address: WIXEELABS DOO  
  Jurija Gagarina 231, Local 329  
  Novi Beograd, 11070  
  Belgrade, Serbia

Our customer support team will respond to inquiries in a timely manner and address any issues or requests related to the application, including data deletion requests, technical support, and subscription management.
`;

export default terms;
