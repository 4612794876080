// src/screens/ConsultationScreen.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';
import { useAuth } from '../components/AuthContext.js';
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js';
import { useSearchParams } from 'react-router-dom'; // Import useParams

import { AppRoutes } from '../config/AppModes.ts';
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import ConsultationCard from '../components/ConsultationCard.js';
import DocumentCard from '../components/DocumentCard.js';
import PopupDeleteDialog from '../components/PopupDeleteDialog.js';
import PopupRestoreDialog from '../components/PopupRestoreDialog.js';

export function DocumentManageScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { userProfile, tokenVerificationCompleted } = useAuth();
    const { t } = useTranslation();
    const setDelayedNavigation = useDelayedNavigation();

    const userPhoto = userProfile?.picture || null;

    const [searchParams] = useSearchParams();
    const docId = searchParams.get('docid');

    const messageEndRef = useRef(null);
    const { documentsManager, showAlert } = useAppContext();
    const [deletedDate, setDeletedDate] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenRestore, setDialogOpenRestore] = useState(false);
    const [restored, setRestored] = useState(false);
    const [document, setDocument] = useState(null);
    const isLoaded = useRef(false);

    useEffect(() => {

        if (isLoaded.current) return;

        if (tokenVerificationCompleted)
            if (docId) {
                // load chat messages by consultation id
                loadDocument(docId);
                isLoaded.current = true;
            }
    }, [tokenVerificationCompleted, docId, restored]);

    async function loadDocument(docId) {
        const data = await documentsManager.getDocument(docId)
        //console.log('loadDocument', data);
        setDeletedDate(data?.deleted_date_time);
        setDocument(data);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDeleteDocument = async () => {
        try {
            setDialogOpen(false);
            showAlert('info', 'Deleting...');
            await documentsManager.deleteDocument(docId);
            // Perform any additional actions after deletion, such as redirecting
            showAlert('success', 'Document deleted successfully');
            // Optionally, navigate back or show a success message
            setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_DOCUMENTS.path);
        } catch (error) {
            console.error('Error deleting consultation:', error);
            showAlert('error', 'Error deleting document');
            // Handle the error appropriately, e.g., show an error message
        }
    };

    const handleRestoreDocument = async () => {
        try {
            setDialogOpenRestore(false);
            await documentsManager.restoreDocument(docId);
            isLoaded.current = false;
            setRestored(true);
            showAlert('success', 'Document restored successfully');
            // Perform any additional actions after deletion, such as redirecting
            // Optionally, navigate back or show a success message
        } catch (error) {
            console.error('Error deleting consultation:', error);
            showAlert('error', 'Error restoring document');
            // Handle the error appropriately, e.g., show an error message
        }
    };

    const handleDialogOpenRestore = () => {
        setDialogOpenRestore(true);
    };


    const downloadDocument = async (documentId) => {
        documentsManager.downloadDocument(documentId, showAlert);
    };

    return (
        <Box sx={{
            p: 0, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
            flex: 1, height: '100%', background: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{ px: 2, py: 3, flexGrow: 0, textAlign: 'left', position: 'relative', maxWidth: '98%' }}>

                <DocumentCard
                    key={'doc-card'}
                    content={document}
                    userPhoto={userPhoto}
                    handleCardClick={null}
                    isDeleted={false}
                    index={1}
                    expanded={true}
                />

            </Box>

            <Box sx={{ width: '100%', mx: 2, px: 2, pt: 1, pb: 0.5, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                <Collapse sx={{ width: '100%' }} in={deletedDate !== null} timeout={200}>
                    <Box sx={{ width: 'auto', mx: 2, px: 2, py: 1, mb: 2, textAlign: 'center' }}>
                        <Typography variant="body1" color="black">
                            {t('documentManage.documentDeleted', { date: formatDateDistance(new Date(deletedDate || Date.now())) })}.
                        </Typography>
                    </Box>
                </Collapse>

                <Collapse sx={{ width: '100%' }} in={deletedDate === null && document !== null} timeout={200}>

                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="outlined" color="primary"
                            onClick={() => { }}>
                            {t('documentManage.rename')}
                        </Button>

                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="outlined" color="primary"
                            onClick={() => { }}>
                            {t('documentManage.share')}
                        </Button>

                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="outlined" color="primary"
                            onClick={() => { }}>
                            {t('documentManage.changeProfile')}
                        </Button>

                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="outlined"
                            color="primary"
                            onClick={() => downloadDocument(document?.document_hash_string)}
                        >
                            {t('documentManage.download')}
                        </Button>

                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="danger" color="primary"
                            onClick={handleDialogOpen}>
                            {t('documentManage.delete')}
                        </Button>

                    </Box>

                </Collapse>

                <Collapse sx={{ width: '100%' }} in={deletedDate !== null} timeout={200}>
                    <Box sx={{ width: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Button sx={{ width: '70%', m: 1, maxWidth: '20em' }}
                            variant="outlined" color="primary"
                            onClick={handleDialogOpenRestore}>
                            {t('documentManage.restore')}
                        </Button>
                    </Box>
                </Collapse>

            </Box>


            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flex: 1,
            }}></Box>

            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flexShrink: 0,
            }}>

            </Box>


            <PopupDeleteDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                onDelete={handleDeleteDocument}
                Title={t('documentManage.deleteConfirmation')}
                Description={document?.title}
            />

            <PopupRestoreDialog
                open={dialogOpenRestore}
                setOpen={setDialogOpenRestore}
                onRestore={handleRestoreDocument}
                Title={t('documentManage.restoreConfirmation')}
                Description={document?.title}
            />

            <div ref={messageEndRef} />
        </Box >
    );
}

export default DocumentManageScreen;
