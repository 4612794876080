import React, { useEffect, useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { useTranslation } from 'react-i18next';
import { useAuth } from '../components/AuthContext.js'; // Import useAuth hook
import SearchBox from '../elements/SearchBox.tsx'; // Import SearchBox component
import DocumentGroup from '../components/DocumentGroup.js';
import { AppRoutes } from '../config/AppModes.ts';
import { useAppContext } from '../components/AppContext.js';

export function DocumentScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();

    const isLoaded = useRef(false);
    const { t } = useTranslation();

    const { userProfile } = useAuth();
    const userPhoto = userProfile?.picture || null;

    const { documentsManager, currentProfileId } = useAppContext();

    const [expandedGroup, setExpandedGroup] = useState('latest');
    const [documents, setDocuments] = useState({
        latestDocuments: [
            { id: 0 },
            { id: 0 },
            { id: 0 },
            { id: 0 },
        ],
        lastMonthDocuments: [],
        olderDocuments: [],
        deletedDocuments: [],
    });

    // load consultations
    useEffect(() => {

        if (isLoaded.current) return;

        async function fetchAndFormatDocuments() {
            if (documentsManager && currentProfileId) {

                // FIXME! Use only user profile id
                setDocuments(await documentsManager.getSplittedDocuments(currentProfileId));
                isLoaded.current = true;
            }
        }
        fetchAndFormatDocuments();
    }, [documentsManager, currentProfileId]);

    const handleCardClick = (doc) => {
        // Handle card click event
        //console.log('Card clicked:', doc);

        if (doc.deleted_date_time !== null) {
            setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_DOCUMENT_MANAGE.path}?docid=${doc.id}`);
        }
        else {
            setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_DOCUMENT_DETAILS.path}?docid=${doc.id}`);
        }
        //docManager.downloadDocument(doc.document_hash);

    };

    return (
        <Box sx={{
            pt: 1, pb: 10, px: 0, flex: 1,
            display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <SearchBox />

                <Box sx={{ width: '70%', mx: 2, p: 2, my: 0, maxWidth: '20em' }}>
                    {/* <Button sx={{ position: 'relative', width: '100%', px: 2 }}
                        variant="contained" color="primary"
                        onClick={() => { }}>
                        {t('documents.upload')}
                    </Button> */}
                </Box>

                <DocumentGroup
                    title={t('documents.latest')}
                    documents={documents.latestDocuments}
                    userPhoto={userPhoto}
                    handleCardClick={handleCardClick}
                    expanded={expandedGroup === 'latest'}
                    onExpand={() => setExpandedGroup(expandedGroup === 'latest' ? '' : 'latest')}
                />

                <DocumentGroup
                    title={t('documents.lastMonth')}
                    documents={documents.lastMonthDocuments}
                    userPhoto={userPhoto}
                    handleCardClick={handleCardClick}
                    expanded={expandedGroup === 'lastmonth'}
                    onExpand={() => setExpandedGroup(expandedGroup === 'lastmonth' ? '' : 'lastmonth')}
                />

                <DocumentGroup
                    title={t('documents.older')}
                    documents={documents.olderDocuments}
                    userPhoto={userPhoto}
                    handleCardClick={handleCardClick}
                    expanded={expandedGroup === 'older'}
                    onExpand={() => setExpandedGroup(expandedGroup === 'older' ? '' : 'older')}
                />

                <DocumentGroup
                    title={t('documents.deleted')}
                    documents={documents.deletedDocuments}
                    userPhoto={userPhoto}
                    handleCardClick={handleCardClick}
                    expanded={expandedGroup === 'deleted'}
                    onExpand={() => setExpandedGroup(expandedGroup === 'deleted' ? '' : 'deleted')}
                />

            </Box>
        </Box >
    );
}

export default DocumentScreen;