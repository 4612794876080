import React, { useEffect, } from 'react';
import { Typography, Box, Card, CardContent, CardActionArea, } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { useTranslation } from 'react-i18next';
import SearchBox from '../elements/SearchBox.tsx'; // Import SearchBox component
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import photo1 from '../img/services/service-tarot.webp';
import photo2 from '../img/services/service-health.webp';
import photo3 from '../img/woman-photo.jpg';

export function CalendarScreen({ setDirection, useDelayedNavigation }) {

    useAuthRedirect(setDirection, useDelayedNavigation);
    const { t } = useTranslation();
    const notifications = [
        { photo: photo1, title: 'General Checkup', when: new Date('2024-10-01'), sourceType: 'consultation', date: new Date('2024-09-18T14:30:00') },
        { photo: photo2, title: 'Blood Check', when: new Date('2024-11-01'), sourceType: 'document', date: new Date('2024-09-18T14:30:00') },
        { photo: photo1, title: 'General Checkup', when: new Date('2024-12-01'), sourceType: 'consultation', date: new Date('2024-09-18T14:30:00') },
        { photo: photo2, title: 'General Checkup', when: new Date('2025-01-01'), sourceType: 'consultation', date: new Date('2024-09-18T14:30:00') },
        { photo: photo1, title: 'General Checkup', when: new Date('2025-02-01'), sourceType: 'consultation', date: new Date('2024-09-18T14:30:00') },
    ];


    const handleCardClick = (doc) => {
        // Handle card click event
        console.log('Card clicked:', doc);
    };

    return (
        <Box sx={{
            pt: 0, pb: 0, px: 0, flex: 1,
            display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', width: '100%', mb: 2, pt: 1 }}>

                <SearchBox />

                {notifications.map((doc, index) => (

                    <Card key={index} sx={{
                        overflow: 'hidden', p: 0, mx: 2, mt: 2,
                        borderRadius: '11px', border: '1px solid #C7E1FA',
                        position: 'relative' // Added to position photo3 absolutely
                    }} elevation={0}>
                        <CardActionArea onClick={() => { handleCardClick(doc) }}>
                            <CardContent sx={{ p: 0 }}>
                                <Box sx={{
                                    width: '100%', borderRadius: '11px',
                                    justifyContent: 'center',
                                    position: 'relative', overflow: 'hidden',
                                }}>

                                    <Box sx={{
                                        width: '100%',
                                        justifyContent: 'top', display: 'flex',
                                        flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%', height: '100%', overflow: 'hidden',
                                        }}>

                                            <Box sx={{
                                                width: '100%', aspectRatio: '4 / 7',
                                                backgroundImage: `url(${doc.photo})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                position: 'relative',
                                            }} />
                                        </Box>
                                        <Box sx={{
                                            height: '100%', width: '85%',
                                            justifyContent: 'top', display: 'flex',
                                            flexDirection: 'column', alignItems: 'left',
                                            backgroundColor: 'white',
                                        }}>
                                            <Typography sx={{ px: 1, textAlign: 'left', position: 'relative', }} variant="h3">
                                                {doc.title}
                                            </Typography>
                                            <Typography sx={{ px: 1, textAlign: 'left', position: 'relative', }} variant="body1">
                                                {formatDateDistance(doc.when)}
                                            </Typography>
                                            <Typography sx={{ px: 1, textAlign: 'left', position: 'relative', }} variant="body1">
                                                {t('calendar.created')} {t(`calendar.source.${doc.sourceType}`)}
                                            </Typography>
                                            <Typography sx={{ px: 1, textAlign: 'left', position: 'relative', }} variant="body1">
                                                {formatDate(doc.date)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        height: '40%',
                                        aspectRatio: '1 / 1',
                                        backgroundImage: `url(${photo3})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: '50%',
                                    }} />
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>

        </Box >
    );
}

export default CalendarScreen;