import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardActionArea, Avatar, Fade, Skeleton } from '@mui/material';
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import imageDoc1 from '../img/image-doc-1.webp';
import imageDoc2 from '../img/image-doc-2.webp';
import imageDoc3 from '../img/image-doc-3.webp';
import imageDoc4 from '../img/image-doc-4.webp';
import { ProfileIcon } from '../components/ProfileIcon.tsx';
import { Margin } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { chatModes } from '../config/AppModes.ts';

// Utility function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const docImages = [imageDoc1, imageDoc2, imageDoc3, imageDoc4];

const getRandomDocImage = (index) => {
    const randomIndex = index % docImages.length;
    return docImages[randomIndex];
};

const DocumentCard = ({ content, userPhoto, handleCardClick, isDeleted, index, expanded }) => {

    const [startFade, setStartFade] = useState(false);
    const { t } = useTranslation();

    // Delay the activation of the fade effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setStartFade(true);
        }, 100); // Delay in milliseconds (e.g., 1000ms = 1 second)

        return () => clearTimeout(timer); // Cleanup the timeout on unmount
    }, []);

    return (
        (content) &&
        <Fade
            in={expanded && startFade}
            key={index}
            timeout={Math.min(250 + 300 * index, 2000)}
        >
            <Grid>
                <Card sx={{
                    mb: 0, borderRadius: '11px', my: 0,
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                    // if is Deleted set opacity to 0.5
                    // opacity: isDeleted ? 0.5 : 1
                }} elevation={0}>

                    {content.id ? (
                        <CardActionArea sx={{ overflow: 'hidden' }} onClick={() => { if (handleCardClick) handleCardClick(content) }}>
                            <CardContent sx={{ p: 0 }}>
                                <Box sx={{
                                    width: '100%', borderRadius: '11px',
                                    justifyContent: 'center',
                                    position: 'relative', overflow: 'auto',
                                    //border: '1px solid lightgray',
                                    '&:hover .avatar-zoom': {
                                        transform: 'scale(1.4)',
                                    },
                                }}>
                                    <Box sx={{
                                        height: '100%', width: '100%', justifyContent: 'center', display: 'flex',
                                        flexDirection: 'column', alignItems: 'top',
                                    }}>
                                        <Box sx={{
                                            width: '100%', height: '7rem', flexShrink: 0, backgroundColor: '#E5E7EB',
                                            overflow: 'hidden', display: 'flex', alignItems: 'flex-start',
                                            justifyContent: 'center', position: 'relative',
                                        }}>
                                            <Avatar className="avatar-zoom" sx={{
                                                width: '100%', height: 'auto',
                                                transform: 'scale(1.3)',
                                                transformOrigin: 'top center',
                                                borderRadius: '0',
                                                position: 'absolute',
                                                pr: 1,
                                                transition: 'transform 1s ease-in-out',
                                            }} src={getRandomDocImage(content.id)} />
                                        </Box>
                                        <Box sx={{
                                            px: 2, py: 1, flexGrow: 1, textAlign: 'left', position: 'relative', maxHeight: '25em',
                                            overflowY: 'auto', // Allows scrolling but hides the scrollbar
                                            '&::-webkit-scrollbar': {
                                                display: 'none', // Hides the scrollbar in WebKit browsers (Chrome, Safari)
                                            },
                                            '-ms-overflow-style': 'none', // Hides the scrollbar in Internet Explorer and Edge
                                            'scrollbar-width': 'none', // Hides the scrollbar in Firefox
                                        }}>
                                            {(content.source_type === 'generated') ? (
                                                <Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                        <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="h3">
                                                            {content?.consultant?.key && t(`consultants.${content.consultant.key}.name`) || t(`consultants.${chatModes.CHAT_UNKNOWN}.name`)}
                                                        </Typography>
                                                    </Box>
                                                    <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                        {content.description}
                                                    </Typography>
                                                    <Typography sx={{ pt: 2, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                        {capitalizeFirstLetter(formatDateDistance(new Date(content.date || Date.now())))}
                                                        , #{content.id}{/*, content.total_cost$*/}
                                                    </Typography>
                                                </Box>
                                            ) : (content.source_type === 'uploaded') ? (
                                                <Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                        <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="h3">
                                                            {content?.consultant?.key && t(`consultants.${content.consultant.key}.name`) || t(`consultants.${chatModes.CHAT_UNKNOWN}.name`)}
                                                        </Typography>
                                                    </Box>
                                                    <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="h4">
                                                        {content.generated_title}
                                                    </Typography>
                                                    <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="h4">
                                                        {content.short_summary}
                                                    </Typography>
                                                    <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                        {content.long_summary}
                                                    </Typography>
                                                    <Typography sx={{ pt: 2, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                        {capitalizeFirstLetter(formatDateDistance(new Date(content.date || Date.now())))}
                                                        , #{content.id}{/*, content.total_cost$*/}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box>Unknown document type</Box>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        width: '2.2rem', height: '2.2rem', position: 'absolute', top: '7rem', right: '1rem',
                                        transform: 'translateY(-50%)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backdropFilter: 'blur(15px)',
                                        border: '3px solid white', // Set the desired border width and color
                                        borderRadius: '50%',
                                    }}  >
                                        <ProfileIcon
                                            id={content.profileId}
                                            name={content.profileName}
                                            onClick={() => { }}
                                            sx={{ width: '2.2rem', height: '2.2rem' }}
                                        />
                                    </Box>
                                </Box>
                            </CardContent>
                        </CardActionArea>

                    ) : (
                        <CardActionArea sx={{ overflow: 'hidden' }}>
                            <CardContent sx={{ p: 0 }}>
                                <Box sx={{
                                    width: '100%',
                                    borderRadius: '11px',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    overflow: 'auto',
                                    border: '1px solid lightgray',
                                }}>
                                    <Box sx={{
                                        height: '100%',
                                        width: '100%',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'top',
                                    }}>
                                        <Box sx={{
                                            width: '100%',
                                            height: '4rem',
                                            flexShrink: 0,
                                            backgroundColor: '#E5E7EB',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            position: 'relative'
                                        }}>
                                            <Skeleton variant="rectangular" width="100%" height="100%" sx={{ transform: 'scale(1.3)', transformOrigin: 'top center' }} />
                                        </Box>
                                        <Box sx={{ px: 2, py: 1, flexGrow: 1, textAlign: 'left', position: 'relative' }}>
                                            <Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Skeleton variant="text" sx={{ width: '80%' }} />
                                                </Box>
                                                <Skeleton variant="text" sx={{ width: '100%' }} />
                                                <Skeleton variant="text" sx={{ width: '60%', mt: 2 }} />
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </CardContent>
                        </CardActionArea>
                    )}
                </Card>
            </Grid>
        </Fade>
    );
};

export default DocumentCard;